import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';

import {
    Drawer,
    Divider,
} from '@mui/material';

import {
    EPOS_FILTERS_TITLES,
    IFilterListItem,
} from 'const';

import {
    ICardTransactionsReportBaseFilterList,
    ICardTransactionsReportFilterList,
    checkOpenExtension,
    countFilterQty,
    toggleFilters,
} from 'pages/Reports/business';

import { FilterButton, Header, ControlPanel } from 'components/Filters/Filters';
import FiltersWithSearch from 'components/Filters/FiltersWithSearch/FiltersWithSearch';
import DateTimePicker from 'pages/Users/AuditLogs/components/DateTimePicker/DateTimePicker';

import useStyles from 'pages/Reports/Tickets/TicketsReportFilters/styles';

const initOpenExpansions = {};

const initialValidityState = {
    timeRange: true,
};

const BETSHOPS = 'betShops';
const CASHIERS = 'cashiers';

const searchListInitial = {
    [BETSHOPS]: { text: '', isFieldOpen: false },
    [CASHIERS]: { text: '', isFieldOpen: false }
};

const checkValidity = (validityState) => Object.values(validityState).every(isValid => isValid);

const CardTransactionsReportFilters = function ({
    filtersLists,
    appliedFilters,
    setAppliedFilters,
    filterListInitialWithDate,
    handleClearFilters,
    handleSaveFilters,
    isSaveFilters,
}: {
    filtersLists: ICardTransactionsReportBaseFilterList;
    appliedFilters: ICardTransactionsReportFilterList;
    setAppliedFilters: (filters: ICardTransactionsReportFilterList) => void;
    filterListInitialWithDate: ICardTransactionsReportFilterList;
    handleClearFilters: () => void;
    handleSaveFilters: () => void;
    isSaveFilters: boolean;
}) {
    const classes = useStyles({});

    const [filters, setFilters] = useState(filterListInitialWithDate);
    const [searchInFiltersList, setSearchInFiltersList] = useState(searchListInitial);
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const [openExpansions, setOpenExpansions] = useState(initOpenExpansions);

    const filtersWrapperRef = useRef<HTMLDivElement>();

    useEffect(() => {
        setFilters({ ...appliedFilters });
        setOpenExpansions({ ...checkOpenExtension(appliedFilters) });
    }, Object.values(appliedFilters));

    const [validityState, setValidityState] = useState({ ...initialValidityState });
    const isApplyDisabled = !checkValidity(validityState);
    const setTimeRangeInvalid = useCallback((isInvalid) => {
        setValidityState(validityState => ({ ...validityState, timeRange: !isInvalid }));
    }, []);

    const handleOpenDrawer = () => {
        setFilters({ ...appliedFilters });
        setIsOpenDrawer(true);
    };

    const handleCloseDrawer = () => {
        setOpenExpansions({ ...checkOpenExtension(appliedFilters) });
        setIsOpenDrawer(false);
    };

    const handleFilterClick = useCallback((key: string, item: IFilterListItem) => {
        setFilters(filters => {
            const filterArr = toggleFilters(item, filters[key]);

            return {
                ...filters,
                [key]: filterArr,
            };
        });
    }, []);

    const handleApplyFilters = () => {
        setOpenExpansions({ ...checkOpenExtension(appliedFilters) });
        setIsOpenDrawer(false);
        setAppliedFilters({ ...filters });
        setSearchInFiltersList({ ...searchListInitial });
    };

    const handleSearchInFilters = (key: string, text: string, isFieldOpen: boolean) => {
        const newValue = { text, isFieldOpen };

        setSearchInFiltersList({ ...searchInFiltersList, [key]: newValue });
    };

    const onClearFilters = () => {
        setOpenExpansions({ ...initOpenExpansions });
        setFilters({ ...filterListInitialWithDate });
        setSearchInFiltersList({ ...searchListInitial });
        handleClearFilters();
    };

    const handleExpanded = useCallback((key: string) => {
        setOpenExpansions(openExpansions => (
            {
                ...openExpansions,
                [key]: !openExpansions[key],
            }
        ));
    }, []);

    const changeDates = useCallback((dates) => {
        setFilters(filters => ({ ...filters, ...dates }));
    }, []);

    return (
        <>
            <FilterButton
                filterQty={countFilterQty(appliedFilters)}
                handleOpenDrawer={handleOpenDrawer}
            />
            <Drawer
                className={classes.drawerWrap}
                classes={{
                    root: classes.drawerRoot,
                    paper: classes.drawerWrap
                }}
                anchor="right"
                open={isOpenDrawer}
                onClose={handleCloseDrawer}
            >
                <div className={classes.drawer}>
                    <Header onCloseClick={handleCloseDrawer} />
                    <div className={classes.filtersWrap}
                        ref={filtersWrapperRef}
                    >
                        <DateTimePicker
                            setApplyDisabled={setTimeRangeInvalid}
                            fromDate={filters.fromDate}
                            toDate={filters.toDate}
                            changeDates={changeDates}
                        />
                        <Divider />
                        <FiltersWithSearch
                            label={BETSHOPS}
                            title={EPOS_FILTERS_TITLES.location}
                            data={filtersLists[BETSHOPS]}
                            activeFilterIds={filters[BETSHOPS]}
                            appliedActiveFilters={appliedFilters[BETSHOPS]}
                            handleFilterClick={handleFilterClick}
                            expanded={openExpansions[BETSHOPS]}
                            handleExpanded={handleExpanded}
                            handleSearchInFilters={handleSearchInFilters}
                            searchText={searchInFiltersList[BETSHOPS].text}
                            isSearchFieldShown={searchInFiltersList[BETSHOPS].isFieldOpen}
                            scrollElement={filtersWrapperRef.current}
                        />
                        <FiltersWithSearch
                            label={CASHIERS}
                            title="users-cashier"
                            data={filtersLists[CASHIERS]}
                            activeFilterIds={filters[CASHIERS]}
                            appliedActiveFilters={appliedFilters[CASHIERS]}
                            handleFilterClick={handleFilterClick}
                            expanded={openExpansions[CASHIERS]}
                            handleExpanded={handleExpanded}
                            handleSearchInFilters={handleSearchInFilters}
                            searchText={searchInFiltersList[CASHIERS].text}
                            isSearchFieldShown={searchInFiltersList[CASHIERS].isFieldOpen}
                            scrollElement={filtersWrapperRef.current}
                        />
                    </div>
                    <ControlPanel
                        disabled={isApplyDisabled}
                        onApplyFilters={handleApplyFilters}
                        onClearFilters={onClearFilters}
                        onSaveFilters={handleSaveFilters}
                        isSaveFilters={isSaveFilters}
                    />
                </div>
            </Drawer>
        </>
    );
};

export default CardTransactionsReportFilters;
