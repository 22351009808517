import { createTheme } from '@mui/material';

export const palette = {
    common: {
        black: '#000',
        white: '#fff'
    },
    background: {
        paper: '#fff',
        default: '#fafafa'
    },
    primary: {
        light: 'rgba(247, 235, 223, 1)',
        main: 'rgba(246, 129, 0, 1)',
        dark: 'rgb(246, 177, 102)',
        lighter: '#fef5eb',
        contrastText: '#fff'
    },
    secondary: {
        light: 'rgba(36,193,136, 0.6)',
        main: '#24C188',
        dark: '#2E7D32',
        contrastText: '#fff'
    },
    error: {
        light: 'rgba(255, 247, 247, 1)',
        main: 'rgba(224, 78, 80, 1)',
        dark: 'rgba(234, 67, 53, 1)',
        contrastText: '#fff'
    },
    text: {
        primary: 'rgba(0, 0, 0, 0.87)',
        secondary: 'rgba(88, 88, 89, 1)',
        disabled: 'rgba(0, 0, 0, 0.38)',
        hint: 'rgba(0, 0, 0, 0.38)'
    },
    elevation: 8,
    badge: {
        pending: {
            backgroundColor: '#FFF9F3',
            color: '#F67900',
        },
        warning: {
            backgroundColor: '#FAD0CC',
            color: '#E44C35',
        },
        success: {
            backgroundColor: '#EAFCDA',
            color: '#60B510',
        },
        neutral: {
            backgroundColor: '#E8E8E8',
            color: '#666',
        },
    },
};

const defaultTheme = createTheme({
    palette,
    components: {
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        color: palette.primary.main,
                        backgroundColor: palette.primary.lighter,
                        borderRadius: 0,
                        '&:hover': {
                            backgroundColor: palette.primary.lighter,
                        }
                    },
                    '&:hover': {
                        color: palette.primary.main,
                        backgroundColor: palette.primary.lighter,
                    }
                }
            }
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    overflow: 'hidden'
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '&$focused': {
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: palette.primary.main,
                        }
                    }
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                h5: {
                    fontWeight: 500
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    minHeight: 'auto'
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    '@media (max-width: 600px)': {
                        margin: '0 16px'
                    }
                },
                actions: {
                    '@media (max-width: 600px)': {
                        marginLeft: '8px'
                    }
                },
                input: {
                    '@media (max-width: 600px)': {
                        marginRight: '8px'
                    }
                },
                toolbar: {
                    '@media (max-width: 600px)': {
                        flexWrap: 'wrap',
                        justifyContent: 'flex-end'
                    }
                },
            }
            // caption: {
            //     '@media (max-width: 600px)': {
            //         marginRight: '16px',
            //     }
            // }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    color: palette.text.primary,
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                },
                contained: {
                    boxShadow: 'none',
                    color: palette.common.white,
                }
            }
        }
    },
    typography: {
        fontFamily: [
            'Roboto',
            'Arial',
            'sans-serif',
        ].join(','),
    },
});

export default defaultTheme;
