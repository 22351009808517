import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) => ({
    drawerRoot: {
        '& > .MuiBackdrop-root': {
            backgroundColor: 'transparent',
        }
    },
    drawerWrap: {
        width: '350px',

        [breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    drawer: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
    },
    filtersWrap: {
        flexGrow: 1,
        overflowX: 'hidden',
        overflowY: 'auto',
    },
    controlPanel: {
        padding: spacing(2),
    },
    searchByDateSelectContainer: {
        padding: spacing(2, 2, 1),
        width: '100%',
    },
}));

export default useStyles;
