import * as React from 'react';
import clsx from 'clsx';

import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import { Typography } from '@mui/material/';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';
import formatStr from 'utils/formatStr';

const useStyles = makeStyles((theme: Theme) => ({
    wrap: {
        display: 'flex',
        alignItems: 'center',
        padding: `0 ${theme.spacing(2)}`,
        cursor: 'pointer'
    },
    arrow: {
        marginRight: theme.spacing(1),
        transition: theme.transitions.create('transform'),
    },
    arrowUp: {
        transform: 'rotate(180deg)'
    }
}));

function ShowMore(props: { showMore: boolean; qty: number; toggleShowMore: () => void }) {
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);
    const classes = useStyles({});
    const labelKey = props.qty ? 'hm-filter-show-more' : 'hm-filter-show-less';
    const labelText = `${formatStr(translations[labelKey], { 'count': props.qty ? props.qty.toString() : '0' })}`;

    return (
        <Typography variant="subtitle2" className={classes.wrap} onClick={props.toggleShowMore}>
            <KeyboardArrowDownIcon fontSize="small" className={clsx(classes.arrow, props.showMore && classes.arrowUp)} />
            <span>{labelText}</span>
        </Typography>
    );
}

export default React.memo(ShowMore);
