import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ breakpoints, spacing, typography }: Theme) => ({
    ticketIdWrap: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',

        [breakpoints.down('sm')]: {
            marginRight: 'auto',
            minWidth: spacing(150/8),
            padding: 0,
            fontWeight: typography.fontWeightMedium,
        }
    },
    ticketId: {
        marginRight: spacing(12/8),
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',

        [breakpoints.down('sm')]: {
            marginRight: 0,
        }
    },
    icon: {
        marginRight: spacing(12/8),
        color: '#B9B9B9',
        fontSize: typography.body1.fontSize,

        [breakpoints.down('sm')]: {
            marginRight: spacing(1),
            fontSize: `${24/16}rem`,
        }
    },
}));

export default useStyles;
