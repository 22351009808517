import React from 'react';

import {
    Order,
    ORDER_BY_FINANCIAL_PERFORMANCE_COLUMN,
    ORDER_BY_FINANCIAL_PERFORMANCE_COLUMN_NAMES,
} from 'const';
import usePropStyles from 'pages/Reports/Tickets/TicketsReportTable/styles';
import useTableHeadStyles from 'pages/Reports/Tickets/TicketsReportTable/TicketsReportTableHead/styles';

import {
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
} from '@mui/material';

import { headFinancialPerformanceReportColumns } from 'data';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

export default function FinancialPerformanceReportTableHead({
    classes,
    hideSorting,
    onSort,
    order,
    orderByColumn
}: {
  classes: ReturnType<typeof usePropStyles>;
  hideSorting?: boolean;
  onSort: (event: React.MouseEvent<unknown>, orderByColumn: ORDER_BY_FINANCIAL_PERFORMANCE_COLUMN) => void;
  order: Order;
  orderByColumn: ORDER_BY_FINANCIAL_PERFORMANCE_COLUMN;
}) {
    const localClasses = useTableHeadStyles({});

    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    const createSortHandler = (orderByColumn: ORDER_BY_FINANCIAL_PERFORMANCE_COLUMN) => (event: React.MouseEvent<unknown>) => {
        onSort(event, orderByColumn);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell
                    className={localClasses.cell}
                    padding="normal"
                    variant="head"
                    scope="col"
                    style={{ width: '5%' }}
                />
                {headFinancialPerformanceReportColumns.map(
                    ({ id, label, disablePadding, width }) => {
                        const isColumnSortable = Object.keys(
                            ORDER_BY_FINANCIAL_PERFORMANCE_COLUMN_NAMES
                        ).some(
                            (orderByColumnFinancialPerformance) =>
                                orderByColumnFinancialPerformance === id
                        );

                        return (
                            <TableCell
                                className={localClasses.cell}
                                key={id}
                                padding={disablePadding ? 'none' : 'normal'}
                                sortDirection={isColumnSortable && order}
                                variant="head"
                                classes={
                                    !headFinancialPerformanceReportColumns.some(
                                        (e) => e.id === id
                                    )
                                        ? { root: localClasses.hideCellMobile }
                                        : {}
                                }
                                scope="col"
                                style={{
                                    width: width || 'auto',
                                    padding: '16px 20px 16px 0px',
                                }}
                            >
                                {isColumnSortable && !hideSorting ? (
                                    <TableSortLabel
                                        active={orderByColumn === ORDER_BY_FINANCIAL_PERFORMANCE_COLUMN_NAMES[id]}
                                        direction={order}
                                        hideSortIcon={!isColumnSortable}
                                        onClick={isColumnSortable ? createSortHandler(id as any) : undefined}
                                    >
                                        {translations[label]}
                                        {isColumnSortable ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === Order.descending
                                                    ? 'sorted descending'
                                                    : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                ) : (
                                    translations[label]
                                )}
                            </TableCell>
                        );
                    }
                )}
            </TableRow>
        </TableHead>
    );
}
