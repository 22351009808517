import * as React from 'react';
import clsx from 'clsx';

import { Button, Checkbox, Icon } from '@mui/material';

import useStyles from './styles';

const ErrorsFilterButton = (props) => {
    const classes = useStyles({});
    const { isMobileView, errorsArr, iconName, translation, activeClass, errorsCount, onErrorHandler } = props;

    return (
        <Button
            className={clsx(classes.button, activeClass)}
            variant="outlined"
            component="span"
            onClick={() => onErrorHandler(errorsArr)}
        >
            <span className={classes.buttonWrap}>
                <span className={classes.buttonData}>
                    {isMobileView &&
                        <span className={clsx(classes.count, activeClass && classes.countActive)}>
                            {errorsCount}
                        </span>}
                    <Icon color={activeClass ? 'primary' : 'error'}>{iconName}</Icon>
                    <span className={classes.label}>{translation}</span>
                    {!isMobileView && <span>{errorsCount}</span>}
                </span>
                {isMobileView &&
                    <Checkbox
                        color="primary"
                        classes={{ root: classes.checkbox }}
                        checked={!!activeClass}
                        value="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                }
            </span>
        </Button>
    );
};

export default ErrorsFilterButton;
