import React, { useState, useRef } from 'react';
import { useStyles as useFilterStyles, Header, ControlPanel, FilterButton } from 'components/Filters/Filters';
import AuditLogFiltersList from './AuditLogFiltersList';
import { Drawer } from '@mui/material';
import { IFilterListItem, AUDIT_LOG_FILTERS } from 'const';

const { byAction } = AUDIT_LOG_FILTERS;

const searchListInFiltersInitial = {
    [byAction]: { text: '', isFieldOpen: false }
};

const AuditLogFilters = ({
    filters,
    appliedFilters,
    setFilters,
    handleAppliedFilters,
    handleClearFilters,
    isSaveFilters,
    handleSaveFilters
}) => {
    const classes = useFilterStyles({});
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const [isApplyDisabled, setApplyDisabled] = useState(false);

    const [searchInFiltersList, setSearchInFiltersList] = useState(searchListInFiltersInitial);

    const filtersWrapperRef = useRef<HTMLDivElement>();

    const handleOpenDrawer = () => {
        setFilters(appliedFilters);
        setIsOpenDrawer(true);
    };

    const handleCloseDrawer = () => {
        setIsOpenDrawer(false);
    };

    const onApplyFilters = () => {
        handleAppliedFilters();
        handleCloseDrawer();
        setSearchInFiltersList({ ...searchListInFiltersInitial });
    };

    const onHandleClearFilters = () => {
        setSearchInFiltersList({ ...searchListInFiltersInitial });
        handleClearFilters();
    };

    const handleSearchInFilters = (key: string, text: string, isFieldOpen: boolean) => {
        const newValue = { text, isFieldOpen };

        setSearchInFiltersList({ ...searchInFiltersList, [key]: newValue });
    };

    function countFilterQty() {
        const filtersWithSelection = Object.values(appliedFilters)
            .filter((filters: Array<IFilterListItem>) => filters.length);

        return filtersWithSelection.length;
    }

    return (
        <>
            <FilterButton
                filterQty={countFilterQty()}
                handleOpenDrawer={handleOpenDrawer}
            />
            <Drawer
                className={classes.drawerWrap}
                classes={{
                    root: classes.drawerRoot,
                    paper: classes.drawerWrap
                }}
                anchor="right"
                open={isOpenDrawer}
                onClose={handleCloseDrawer}
            >
                <div className={classes.drawer}>
                    <Header onCloseClick={handleCloseDrawer} />
                    <div className={classes.filtersWrap}
                        ref={filtersWrapperRef}
                    >
                        <AuditLogFiltersList
                            setApplyDisabled={setApplyDisabled}
                            initFilters={filters}
                            setFilters={setFilters}
                            appliedFilters={appliedFilters}
                            scrollElement={filtersWrapperRef.current}
                            searchInFiltersList={searchInFiltersList}
                            handleSearchInFilters={handleSearchInFilters}
                        />
                    </div>
                    <ControlPanel
                        isSaveFilters={isSaveFilters}
                        disabled={isApplyDisabled}
                        onApplyFilters={onApplyFilters}
                        onClearFilters={onHandleClearFilters}
                        onSaveFilters={handleSaveFilters}
                    />
                </div>
            </Drawer>
        </>
    );
};

export default AuditLogFilters;
