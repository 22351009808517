import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ spacing }: Theme) => ({
    root: {
        width: '100%',
    },
    list: {
        width: 'auto',
        minWidth: '100%',
        padding: 0,
        display: 'block',
        borderLeft: 'none',
        borderRight: 'none',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    paginationCountProgress: {
        marginLeft: spacing(0.5),
    }
}));

export default useStyles;
