import React from 'react';
import clsx from 'clsx';
import { Typography, Popover, Chip } from '@mui/material';

import useStyles from './styles';

const stopPropagation = (event: React.MouseEvent<HTMLElement, MouseEvent>) => event.stopPropagation();

export const DataItem = ({
    className,
    dataItem = '',
    url = '',
    handleRedirect = () => {},
}: {
    className?: string;
    dataItem: string;
    url?: string;
    handleRedirect?: (href: string) => void;
}) => {
    const classes = useStyles();
    const isLink = url.length > 0;
    const href = `${url}/${dataItem}`;

    return (
        <span className={clsx(className, isLink && classes.dataLink)}
            {...(isLink
                ? {
                    onClick: () => handleRedirect(href),
                    'data-href': href
                } : {}
            )}
        >
            {dataItem}
        </span>
    );
};

export default function DataItemsWithChipPopover ({
    className = '',
    data,
    delimiter = ', ',
    fixedItemsLimit = 1,
    hideFixedItemsIfLimitExceeded = false,
    hoverable = true,
    url = '',
    handleRedirect,
}: {
    className?: string;
    data: Array<string>;
    delimiter?: string;
    fixedItemsLimit?: number;
    hideFixedItemsIfLimitExceeded?: boolean;
    hoverable?: boolean;
    url?: string;
    handleRedirect?: (href: string) => void;
}) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const chipClickHandler = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation();

        handlePopoverOpen(event);
    };

    const showFixedItems = data.length > 0 &&
        hideFixedItemsIfLimitExceeded
        ? data.length <= fixedItemsLimit
        : true;

    const showChip = data.length > fixedItemsLimit;

    const chipLabel = showFixedItems
        ? `+${data.length - fixedItemsLimit}`
        : data.length;

    return (
        <div className={clsx(className, classes.root)}>
            {showFixedItems &&
                    data.slice(0, fixedItemsLimit).map((dataItem, index, arr) => (
                        <Typography className={classes.dataItemWrap} key={dataItem}
                            variant="body2"
                        >
                            <DataItem
                                dataItem={dataItem}
                                url={url}
                                handleRedirect={handleRedirect}
                            />
                            {index < arr.length - 1 ? delimiter : ''}
                        </Typography>
                    ))
            }
            {showChip &&
                <>
                    <Chip className={classes.chip}
                        classes={{ labelSmall: classes.chipLabelSmall }}
                        size="small"
                        label={chipLabel}
                        aria-owns={(hoverable && open) ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={hoverable ? handlePopoverOpen : undefined}
                        onMouseLeave={hoverable ? handlePopoverClose : undefined}
                        onClick={!hoverable ? chipClickHandler : undefined}
                        data-a="data-chip"
                    />
                    <Popover className={hoverable ? classes.popover : ''}
                        id={hoverable ? 'mouse-over-popover' : undefined}
                        classes={{ paper: classes.paper }}
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                        onClose={handlePopoverClose}
                        onClick={!hoverable ? stopPropagation : undefined}
                        disableRestoreFocus
                        data-a="data-popover"
                    >
                        {data.map(dataItem => (
                            <Typography className={clsx(classes.dataItemWrap, classes.dataItemInPopover)} key={dataItem}
                                variant="body2"
                            >
                                <DataItem
                                    dataItem={dataItem}
                                    url={url}
                                    handleRedirect={handleRedirect}
                                />
                            </Typography>
                        ))}
                    </Popover>
                </>
            }
        </div>
    );
}
