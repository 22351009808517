import React from 'react';
import useStyles from './styles';
import { Order, rowsPerPageOptions } from 'const';
import {
    Box,
    CircularProgress,
    Table,
    TableBody,
    TablePagination,
} from '@mui/material';

import { IHeadRowTicketsReport } from 'data';
import { IReportTicketModel } from 'api/models/reports';

import TicketsReportTableHead from 'pages/Reports/Tickets/TicketsReportTable/TicketsReportTableHead/TicketsReportTableHead';
import TicketsReportTableRow from 'pages/Reports/Tickets/TicketsReportTable/TicketsReportTableRow/TicketsReportTableRow';
import TablePaginationActionsDumb, { PAGINATION_ACTIONS } from 'components/TablePaginationActions/TablePaginationActionsDumb';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import formatStr from 'utils/formatStr';

const TicketsReportTable = ({
    activeColumns,
    order,
    orderBy,
    isFirstPage,
    isLastPage,
    page,
    rows,
    rowsCount,
    hidePagination,
    hideSorting,
    showPaginationInfo,
    rowsPerPage,
    handleSort,
    handleChangePage,
    handleChangeRowsPerPage,
    handleRedirect,
    isMobileView
}: {
    activeColumns: Array<IHeadRowTicketsReport>;
    order: Order;
    orderBy: string;
    isFirstPage: boolean;
    isLastPage: boolean;
    page: number;
    rows: Array<IReportTicketModel>;
    rowsCount: number;
    hidePagination?: boolean;
    hideSorting?: boolean;
    showPaginationInfo: boolean;
    rowsPerPage: number;
    handleSort: (event: React.MouseEvent<HTMLElement>) => void;
    handleChangePage: (event, action: PAGINATION_ACTIONS) => void;
    handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleRedirect: (id: string) => void;
    isMobileView: boolean;
}) => {
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    const classes = useStyles({});

    return (
        <React.Fragment>
            <Box className={classes.tableWrapper}>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                >
                    <TicketsReportTableHead
                        classes={classes}
                        activeColumns={activeColumns}
                        order={order}
                        orderBy={orderBy}
                        onSort={handleSort}
                        hideSorting={hideSorting}
                    />
                    <TableBody>
                        {rows.map((row) => (
                            <TicketsReportTableRow
                                key={`${row.ticketId}${row.expirationDate}`}
                                row={row}
                                handleRedirect={handleRedirect}
                                activeColumns={activeColumns}
                                isMobileView={isMobileView}
                            />
                        ))}
                    </TableBody>
                </Table>
            </Box>
            {!hidePagination &&
                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={rowsCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'previous page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'next page',
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={translations['hm-table-rows-per-page']}
                    labelDisplayedRows={(paginationInfo) => {
                        if (!showPaginationInfo) return '';

                        const { count } = paginationInfo;
                        const defaultTranslation = translations['hm-table-pagination-displayed-rows'];

                        const defaultLabelRows = formatStr(defaultTranslation, paginationInfo);
                        const labelRows = count === -1
                            ? (
                                <CircularProgress className={classes.paginationCountProgress}
                                    size={10}
                                />)
                            : defaultLabelRows;

                        return labelRows;
                    }}
                    ActionsComponent={(props) => (
                        <TablePaginationActionsDumb
                            {...props}
                            onChangePage={handleChangePage}
                            isFirstPage={isFirstPage} isLastPage={isLastPage}
                        />)}
                />
            }
        </React.Fragment>
    );
};

export default TicketsReportTable;
