import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ spacing }: Theme) => ({
    expandableTable: {
        minWidth: '100%',
        tableLayout: 'fixed',
        display: 'block',
        borderLeft: 'none',
        borderRight: 'none',
        '& thead': {
            display: 'none',
        },
        '& tbody': {
            display: 'block',
        },
    },
    expandableTableWrapper: {
        overflow: 'auto',
        flexGrow: 1,
    },
    notClickableRow: {
        cursor: 'default',
    },
    listItem: {
        display: 'flex',
        flexDirection: 'column',
        padding: spacing(0, 2, 1.25, 2),
    },
    listItemButton: {
        width: '100%',
        backgroundColor: '#F8F9FA !important',
        borderRadius: '4px',
        padding: spacing(1, 1, 0, 2),

        '& span': {
            alignItems: 'centered',
        },
    },
    listItemText: {
        '& span': {
            color: '#2E2E30',
            textAlign: 'left',
            fontSize: '0.875rem',
            fontWeight: 500,
        },
    },
    arrowIcon: {
        color: 'black',
    },
    collapseGrid: {
        backgroundColor: '#F8F9FA',
        padding: spacing(1, 1.5, 1, 2),
    },
    gridValueText: {
        color: '#2E2E30',
        fontSize: '0.875rem',
        fontWeight: 500,
    },
    gridContainer: {
        padding: spacing(1, 1, 1, 2),
    },
    summaryText: {
        textTransform: 'uppercase',
        fontWeight: 500,
        fontSize: '0.625rem',
        color: '#666666',
    },
    itemGridContainer: {
        borderBottom: '1px solid #E7E7E7'
    },
    entityText: {
        paddingBottom: spacing(1),
        fontSize: '0.875rem',
        color: '#666666'
    }
}));

export default useStyles;
