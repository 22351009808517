import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { pageWrapContainer } from 'const';

const useStyles = makeStyles((theme: Theme) => ({
    actionWrap: {
        paddingTop: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
            paddingTop: theme.spacing(2)
        }
    },
    content: {
        padding: theme.spacing(2, 0),
        flexGrow: 1
    },
    terminalInfoWrap: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
            order: 1
        }
    },
    metrics: {
        [theme.breakpoints.down('md')]: {
            order: 2
        }
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%'
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(6, 6, 1, 6),

        [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(6, 2, 0),
        },
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            padding: theme.spacing(2, 1.5, 0),
        }
    },
    bodyWrap: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flexGrow: 1,
        padding: theme.spacing(0, 6),

        [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(2),
        }
    },
    bodyWrapContainer: {
        width: '100%',
        maxWidth: pageWrapContainer.maxWidth
    }
}));

export default useStyles;
