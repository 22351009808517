import * as React from 'react';

import { TableCell } from '@mui/material';

const TerminalsTableCell = ({ name, row, activeColumns, className }) => {
    const isUpDownTime = name === 'deliveryTime';

    return (
        activeColumns.some(item => item.id === name)
            ? `${row[name]}`
                ? (
                    <TableCell className={className} variant="body">
                        {isUpDownTime ? row['transformDate'] : row[name]}
                    </TableCell>)
                : <TableCell className={className}>{''}</TableCell>
            : null
    );
};

export default TerminalsTableCell;
