import * as React from 'react';
import { IconButton, Grid, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import TestLabel from 'components/TestLabel/TestLabel';
import useStyles from './styles';
import { GRID_COLUMNS as columns } from 'const';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

const Header = ({ history, epos }) => {
    const { name, status, statusName, upDownTime, isTest } = epos;
    const classes = useStyles({ status });
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    return (
        <Grid container className={classes.bodyWrapContainer}>
            <Grid item lg={columns.col12} xs={columns.col12} sm={columns.col12}>
                <IconButton
                    className={classes.iconButton}
                    onClick={() => history.push({ pathname: '/epos' })}
                    edge="start"
                    color="inherit"
                    size="small"
                    disableRipple
                    data-a="epos-back-nav-button"
                >
                    <ArrowBack htmlColor="black" />
                    &nbsp;
                    <Typography variant="button" className={classes.iconLabel}>{translations['gen-health-monitor']}</Typography>
                </IconButton>

                <React.Fragment>
                    <Typography variant="h5" component="h5" className={classes.title}>{name || ''}</Typography>

                    <Grid classes={{ container: classes.statusContainer }} container spacing={2} alignItems="center">
                        <Grid item>
                            <Typography
                                className={classes.status}
                                color="textSecondary"
                                variant="subtitle1"
                            >
                                {statusName ? translations[statusName] : ''}
                            </Typography>
                        </Grid>
                        {
                            upDownTime &&
                                <Grid item>
                                    <Typography
                                        color="textSecondary"
                                        variant="subtitle1"
                                        display="inline"
                                    >
                                        {upDownTime}
                                    </Typography>
                                </Grid>
                        }
                        {
                            isTest &&
                                <Grid item>
                                    <TestLabel type="long" />
                                </Grid>
                        }
                    </Grid>
                </React.Fragment>
            </Grid>
        </Grid>
    );
};

export default Header;
