import * as React from 'react';
import * as ReactDOM from 'react-dom';
import clsx from 'clsx';

import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Typography,
    TextField,
} from '@mui/material';

import ButtonPrimaryText from 'components/Buttons/ButtonPrimaryText';

import Api from 'api/Api';

import { MaintenanceModeEnable } from 'data';
import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import { cancelRequests } from 'utils/requestCancelation';

import formatStr from 'utils/formatStr';
import useStyles from './styles';

const { useState, useEffect, useContext } = React;

const apiRequests = {
    maintenanceImages: null,
};

export default (props: {
  id: number;
  isOpen: boolean;
  isMobileView: boolean;
  onClose: () => void;
  onSubmit: (id: number, params: Array<string>) => void;
}) => {
    const { isOpen, isMobileView, onClose, onSubmit } = props;

    const classes = useStyles();

    const { globalSettings, translations, permissions }: GlobalContextModel =
    useContext(GlobalContext);

    const { manageMaintenanceModeImagesPermission } = permissions;

    const imageSize = globalSettings.maintenance.ImageSize;
    const imageCount = globalSettings.maintenance.ImageCount;
    const timeoutDefault = globalSettings.maintenance.DefaultTimeout;
    const delayedMaintenanceEnabled =
    globalSettings.maintenance.DelayedMaintenanceEnabled;

    const [images, setImages] = useState([]);
    const [activeImage, setActiveImage] = useState(0);
    const [inputValue, setInputValue] = useState('');
    const [mainFormValid, setMainFormValid] = useState(true);
    const [notificationToPlayer, setNotificationToPlayer] = useState(true);
    const [maintenanceTimeout, setMaintenanceTimeout] = useState(
        timeoutDefault ? `${timeoutDefault}` : ''
    );
    const [timeoutValid, setTimeoutValid] = useState(true);
    const [fileSizeError, setFileSizeError] = useState(false);

    useEffect(() => {
        apiRequests.maintenanceImages = Api.Image.GetMaintenanceImages();
        apiRequests.maintenanceImages
            .then((res) => {
                if (res) {
                    const defaultImageIndex = res.findIndex(image => image.default);

                    if (defaultImageIndex !== -1) {
                        const defaultImage = res[defaultImageIndex];

                        res.splice(defaultImageIndex, 1);
                        res.unshift(defaultImage);
                    }

                    setImages(setLoadedStatus(res));
                }
            })
            .catch(() => {
                // handle fetch errors here
            });

        return () => cancelRequests(apiRequests);
    }, []);

    function setLoadedStatus(res) {
        return res.map((image) => {
            image.loaded = true;

            return image;
        });
    }

    function toggleImage(id) {
        if (activeImage === id) {
            setActiveImage(-1);
        } else {
            setActiveImage(id);
            !mainFormValid && setMainFormValid(!!id);
        }
    }

    function handleInputChange(event) {
        setInputValue(event.target.value);
        !mainFormValid && setMainFormValid(!!event.target.value);
    }

    function handleClose() {
        setInputValue('');
        setMainFormValid(true);
        setTimeoutValid(true);
        images.some(image => image.default) ? setActiveImage(images.findIndex(image => image.default)) : setActiveImage(0);
        onClose();
    }

    function handleSubmit() {
        const isMainFormValid = !!(
            (activeImage >= 0 && activeImage < imageCount) ||
      inputValue
        );
        const isTimeoutValid = Number(maintenanceTimeout) >= Number(timeoutDefault);

        if (isMainFormValid && isTimeoutValid) {
            const imgs = `${images[activeImage]?.url ? images[activeImage].url : ''}`;
            const params = [imgs, inputValue];

            notificationToPlayer && params.push(maintenanceTimeout);

            setInputValue('');
            onSubmit(props.id, params);
            onClose();
        }

        setMainFormValid(isMainFormValid);
        setTimeoutValid(isTimeoutValid);
    }

    function updateImageArray(newImages: any[], newActive: number) {
        setImages(newImages.sort((a, b) => b.default - a.default));

        if (newActive >= 0) {
            setActiveImage(newActive);
        }
    }

    function onChangeUpload(event: React.ChangeEvent<HTMLInputElement>) {
        const file = event.target.files[0];
        const fileSize = file.size / 1024 / 1024;

        if (fileSize > imageSize) {
            setFileSizeError(true);
        } else {
            const reader = new FileReader();
            const uploadedImagesArr = [...images];

            setFileSizeError(false);
            setMainFormValid(true);

            reader.readAsDataURL(file);

            uploadedImagesArr.push();

            reader.onloadend = () => {
                const newUploadedImgArr = [...uploadedImagesArr];

                newUploadedImgArr.push({
                    name: `image${newUploadedImgArr.length + 1}.png`,
                    url: reader.result,
                    loaded: false,
                });

                setImages(newUploadedImgArr);
                Api.Image.UploadMaintenanceImage(file).then(
                    (res) => res && updateImageArray(setLoadedStatus(res), -1)
                );
            };
        }

        event.target.value = null;
    }

    function onRemoveImage(name, isActive) {
        setFileSizeError(false);
        setMainFormValid(true);
        Api.Image.DeleteMaintenanceImage(name).then(
            (res) => {
                if (isActive) {
                    res && updateImageArray(setLoadedStatus(res), res.length >= 0 ? 0 : -1);
                } else {
                    res && updateImageArray(setLoadedStatus(res), -1);
                }
            }
        );
    }

    function handleTimeoutChange(event) {
        const { value } = event.target;

        setMaintenanceTimeout(value.replace(/[^0-9]/g, ''));
        !timeoutValid && setTimeoutValid(value >= +timeoutDefault);
    }

    return ReactDOM.createPortal(
        <div>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {translations[MaintenanceModeEnable.title]}
                </DialogTitle>
                <DialogContent classes={{ root: classes.dialogContent }}>
                    <DialogContentText id="alert-dialog-description">
                        {translations[MaintenanceModeEnable.description]}
                    </DialogContentText>
                </DialogContent>
                {manageMaintenanceModeImagesPermission ? (
                    <Box className={classes.modalBox}>
                        <div className={classes.imgTitleWrap}>
                            <div>
                                <h2 className={classes.title}>
                                    {translations[MaintenanceModeEnable.imagesDescription]}
                                </h2>
                                <p className={classes.titleDescription}>
                                    {`${formatStr(
                                        translations[
                                            'hm-bulk-action-enable-maintenance-mode-size-desc'
                                        ],
                                        { imageSize: imageSize, imageCount: imageCount }
                                    )}`}
                                </p>
                            </div>
                            <div>
                                <input
                                    color="primary"
                                    type="file"
                                    onChange={onChangeUpload}
                                    id="button-file"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    disabled={images.length >= imageCount}
                                />
                                <label htmlFor="button-file">
                                    <Button
                                        component="span"
                                        size="small"
                                        color="primary"
                                        variant="outlined"
                                        data-a="maintenance-mode-images-upload-button"
                                        className={clsx(classes.button, classes.uploadButton)}
                                        disabled={images.length >= imageCount}
                                    >
                                        {translations[MaintenanceModeEnable.uploadImage]}
                                    </Button>
                                </label>
                            </div>
                        </div>
                        <div className={classes.imagesWrap} data-a="maintenance-mode-images-container">
                            {!!images?.length &&
                images.map((image, i) => {
                    return !!image.loaded ? (
                        <div key={i}>
                            <div
                                className={clsx(
                                    classes.imageWrap,
                                    classes.image,
                                    activeImage === i && classes.imageActive
                                )}
                                style={{ backgroundImage: `url("${image.url}")` }}
                                onClick={() => toggleImage(i)}
                                title={!isMobileView ? image.name : ''}
                            />
                            <Button
                                size="small"
                                color="primary"
                                data-a="maintenance-mode-images-delete-button"
                                className={clsx(classes.button, classes.removeButton)}
                                onClick={() => onRemoveImage(image.name, activeImage === i)}
                            >
                                {translations[MaintenanceModeEnable.deleteImage]}
                            </Button>
                        </div>
                    ) : (
                        <div key={i}>
                            <div className={clsx(classes.loadingImg)}>
                                <div className={classes.loadingDots} />
                            </div>
                            <p className={classes.loadingText}>
                                {translations[MaintenanceModeEnable.uploading]}
                            </p>
                        </div>
                    );
                })}
                        </div>
                        {fileSizeError && (
                            <Typography
                                className={classes.errorMessage}
                                color="error"
                                variant="subtitle2"
                            >
                                {`${formatStr(
                                    translations[
                                        'hm-bulk-action-enable-maintenance-mode-size-error'
                                    ],
                                    { imageSize: imageSize }
                                )}`}
                            </Typography>
                        )}
                    </Box>
                ) : (
                    <div className={clsx(classes.imagesWrap, classes.imagesDisabled)}>
                        {!!images?.length &&
                images.map((image, i) => {
                    return !!image.loaded ? (
                        <div key={i}>
                            <div
                                className={clsx(
                                    classes.imageWrap,
                                    classes.image,
                                    activeImage === i && classes.imageActive
                                )}
                                style={{ backgroundImage: `url("${image.url}")` }}
                                onClick={() => toggleImage(i)}
                                title={!isMobileView ? image.name : ''}
                            />
                        </div>
                    ) : (
                        <div key={i}>
                            <div className={clsx(classes.loadingImg)}>
                                <div className={classes.loadingDots} />
                            </div>
                            <p className={classes.loadingText}>
                                {translations[MaintenanceModeEnable.uploading]}
                            </p>
                        </div>
                    );
                })}
                    </div>
                )}
                <Box className={classes.modalBox} mb={1}>
                    <Box className={classes.inputWrap}>
                        <TextField
                            label={translations[MaintenanceModeEnable.textDisplayPlaceholder]}
                            multiline
                            variant="outlined"
                            rows="4"
                            data-a="maintenance-mode-images-text-field"
                            fullWidth
                            value={inputValue}
                            onChange={handleInputChange}
                            error={!mainFormValid}
                        />
                    </Box>
                    {!mainFormValid && (
                        <Typography
                            className={classes.errorMessage}
                            color="error"
                            variant="subtitle2"
                        >
                            {translations[MaintenanceModeEnable.textError]}
                        </Typography>
                    )}
                </Box>
                {delayedMaintenanceEnabled && (
                    <Box className={classes.modalBox} mb={1}>
                        <Box className={classes.inputWrap}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={notificationToPlayer}
                                        onChange={() =>
                                            setNotificationToPlayer(!notificationToPlayer)
                                        }
                                        name="two-factor"
                                        color="primary"
                                    />
                                }
                                label={translations[MaintenanceModeEnable.notificationToPlayer]}
                            />
                        </Box>
                        <Box className={classes.inputWrap}>
                            <p className={classes.titleDescription}>
                                {translations[MaintenanceModeEnable.timerDescription]}
                            </p>
                        </Box>
                        <Box className={clsx(classes.inputWrap, classes.inputWrapTimeout)}>
                            <TextField
                                className={classes.timeoutInput}
                                size="small"
                                variant="outlined"
                                value={maintenanceTimeout}
                                onChange={handleTimeoutChange}
                                error={!timeoutValid}
                                disabled={!notificationToPlayer}
                                inputProps={{
                                    maxLength: '3',
                                }}
                            />
                            <span>{translations[MaintenanceModeEnable.minutes]}</span>
                        </Box>
                        {!timeoutValid && (
                            <Typography
                                className={classes.errorMessage}
                                color="error"
                                variant="subtitle2"
                            >
                                {`${
                                    translations[MaintenanceModeEnable.minutesError]
                                } ${timeoutDefault} ${
                                    translations[MaintenanceModeEnable.minutes]
                                }`}
                            </Typography>
                        )}
                    </Box>
                )}
                <DialogActions>
                    <ButtonPrimaryText onClick={handleClose} >
                        {translations['gen-cancel']}
                    </ButtonPrimaryText>
                    <ButtonPrimaryText onClick={handleSubmit} dataA="maintenance-mode-images-apply-button">
                        {translations['gen-apply']}
                    </ButtonPrimaryText>
                </DialogActions>
            </Dialog>
        </div>,
        document.getElementById('modal') || document.createElement('div')
    );
};
