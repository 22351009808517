import React, { useState, useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Link, withRouter, useHistory } from 'react-router-dom';
import Api from 'api/Api';
import {
    Box,
    Chip,
    Grid,
    Button,
    IconButton,
    Divider,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    TablePagination,
} from '@mui/material';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import PageLayoutWrapper from 'components/PageLayoutWrapper/PageLayoutWrapper';
import SearchFieldWithSuggest from 'components/SearchField/SearchFieldWithSuggest';
import { isSearchValueAcceptable } from 'components/SearchField/business';
import ActionsMenu from './components/ActionsMenu/ActionsMenu';
import BackdropSpinner from 'components/Common/BackdropSpinner';
import NoResultsFound from 'components/NoResultsFound';
import TablePaginationActions from 'components/TablePaginationActions/TablePaginationActions';

import UserFilters from './components/UserFilters/UserFilters';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';
import {
    IUserList,
    USER_TYPES,
    USER_FILTERS,
    USER_FILTERS_TITLES,
    IFilterList
} from 'api/models/user';

import { rowsPerPageOptions, IFilterListItem, localStorageFiltersKeys } from 'const';

import { headUsers } from 'data';
import { cancelRequests } from 'utils/requestCancelation';
import { getFromLocalStorage } from 'utils/devUtils';

import { formatDateByPattern, formatToIsoDateString } from 'utils/formatDate';
import { toggleItemInArray } from 'utils/arrayMethods';

const fullNameSorting = (a, b) => ((a.firstName || '') + (a.lastName || '')).localeCompare((b.firstName || '') + (b.lastName || ''));

const emptyUser: IUserList = {
    id: '',
    email: '',
    emailHash: 0,
    firstName: '',
    lastName: '',
    userName: '',
    enabled: false,
    userTypes: [],
    isInternal: false,
    passwordUpdateDate: ''
};

const filterListInitial = {
    userTypes: [],
    dublicateEmails: []
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        }
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(6, 6, 3),

        [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(6, 2, 2),
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            padding: theme.spacing(2),
        }
    },
    titleWrap: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
        margin: 0,
        fontSize: '1.5rem',
        fontWeight: 500,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    titleLabel: {
        height: '21px',
        marginLeft: theme.spacing(1),
        color: theme.palette.common.white,
        backgroundColor: '#808080',
        fontSize: '12px'
    },
    addUser: {
        minWidth: 120,
        textTransform: 'none',
        whiteSpace: 'nowrap'
    },
    searchWrap: {
        display: 'flex',
        position: 'relative',
        alignSelf: 'normal',
        marginBottom: theme.spacing(3),

        [theme.breakpoints.down('sm')]: {
            display: 'block',
            order: -1,
            marginBottom: theme.spacing(2),
            paddingLeft: theme.spacing(1.5),
            paddingRight: theme.spacing(1.5),
        }
    },
    searchIcon: {
        position: 'absolute',
        right: '0',
        top: theme.spacing(-15.25)
    },
    wrapOpen: {
        height: theme.spacing(4.5),

        '& fieldset': {
            [theme.breakpoints.down('sm')]: {
                height: theme.spacing(5),
                opacity: 1
            }
        }
    },
    wrapActions: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    filterContainer: {
        display: 'flex',
        flex: '1 0 auto',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    filterContainerMobile: {
        display: 'none',
        marginRight: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            display: 'flex'
        },
    },
    tableWrap: {
        [theme.breakpoints.down('sm')]: {
            flexGrow: 1
        },
    },
    table: {
        tableLayout: 'fixed',
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: '4px',

        [theme.breakpoints.down('sm')]: {
            border: 'none'
        },
        '& thead': {
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        '& tr': {
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                flexDirection: 'column',
                padding: theme.spacing(1, 2),
                borderBottom: '1px solid #e0e0e0'
            },
            '&:last-child': {
                [theme.breakpoints.down('sm')]: {
                    borderBottom: 'none'
                }
            }
        },
        '& td': {
            [theme.breakpoints.down('sm')]: {
                padding: 0,
                borderBottom: 'none'
            }
        },
    },
    cell: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    tableRow: {
        '&:hover': {
            '& $actionsIcon': {
                display: 'inline-block'
            },
        }
    },
    tableRowEdit: {
        cursor: 'pointer',
    },
    actionsIcon: {
        display: 'none',
        margin: '-4px'
    },
    mobileHidden: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    menu: {
        outline: 'none',
        display: 'flex',
        flexDirection: 'column',
    },
    inline: {
        display: 'inline-block'
    },
    menuItem: {
        fontSize: '0.875rem'
    },
    fullName: {
        margin: 0,
        fontWeight: 'bold'
    },
    userName: {
        margin: 0
    },
    status: {
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    barcodeSvg: {
        display: 'none'
    }
}));

const filterBySearch = (data, text) => {
    return data.filter(({ firstName, lastName, userName }) => {
        return isSearchValueAcceptable([firstName, lastName, userName], text);
    });
};

const filterByFilters = (data, appliedFilters) => {
    return data.filter(user => {
        return Object.keys(appliedFilters).every(filter => {
            if (filter === USER_FILTERS.dublicateEmails && appliedFilters[filter].length) {
                return appliedFilters[filter].some(item => user.emailHash === Number(item.emailHash));
            }

            if (appliedFilters[filter].length) {
                if (Array.isArray(user[filter])) {
                    return user[filter].length && appliedFilters[filter].every(item => user[filter].includes(item.name));
                } else {
                    return appliedFilters[filter].some(e => e.name === user[filter]);
                }
            }

            return true;
        });
    });
};

const apiRequests = {
    userList: null
};

const UserList = () => {
    const classes = useStyles({});
    const { translations, dateTimeFormat, timeZone, permissions }: GlobalContextModel = useContext(GlobalContext);

    const history = useHistory();

    const anyActions = permissions.usersBlockUnblockPermission || permissions.usersResetPasswordPermission;

    const localStorageFilters = getFromLocalStorage(localStorageFiltersKeys.usersFilters);

    const [isLoaded, setIsLoaded] = useState(false);
    const [defaultUsers, setDefaultUsers] = useState<Array<IUserList>>([]);
    const [users, setUsers] = useState<Array<IUserList>>([]);
    const [page, setPage] = useState(0);
    const [usersPerPage, setUsersPerPage] = useState(20);
    const [activeUser, setActiveUser] = useState<IUserList>(emptyUser);
    const [searchText, setSearchText] = useState<string>('');
    const [filters, setFilters] = useState<IFilterList>(structuredClone(filterListInitial));
    const [activeFilters, setActiveFilters] = useState<IFilterList>(structuredClone(localStorageFilters || filterListInitial));
    const [appliedFilters, setAppliedFilters] = useState<IFilterList>(structuredClone(localStorageFilters || filterListInitial));

    const [isSaveFilters, setIsSaveFilters] = useState(!!localStorageFilters);

    const [anchorMenuEl, setAnchorMenuEl] = useState<null | HTMLElement>(null);

    React.useEffect(() => {
        loadData();

        return () => cancelRequests(apiRequests);
    }, []);

    const loadData = async () => {
        try {
            apiRequests.userList = Api.User.List();

            const result: Array<IUserList> = await apiRequests.userList;

            if (result) {
                const filters = structuredClone(filterListInitial);
                const dublicateEmails = {};

                filters.dublicateEmails = [];

                result.forEach(user => {
                    user.userTypes.forEach(
                        userType => {
                            !filters.userTypes.some(item => item.id === userType) && filters.userTypes.push({ id: userType, name: userType });
                        }
                    );

                    dublicateEmails.hasOwnProperty(user.emailHash)
                        ? dublicateEmails[user.emailHash] += 1
                        : dublicateEmails[user.emailHash] = 1;
                });

                Object.keys(dublicateEmails).forEach(emailHash => {
                    if (dublicateEmails[emailHash] > 1) {
                        filters.dublicateEmails.push({
                            emailHash,
                            email: result.find(item => item.emailHash === Number(emailHash))?.email,
                            count: dublicateEmails[emailHash]
                        });
                    }
                });

                if (appliedFilters.dublicateEmails.length) {
                    appliedFilters.dublicateEmails[0].count =
                        filters.dublicateEmails.find(filter => filter.emailHash === appliedFilters.dublicateEmails[0].emailHash)?.count;
                }

                setDefaultUsers(result);
                setUsers(filterByFilters(filterBySearch(result, searchText), activeFilters));
                setFilters(filters);
                setActiveFilters(appliedFilters);
                setAppliedFilters(appliedFilters);
                setIsLoaded(true);
            }
        } catch (error) {
            // handle fetch errors here
        }
    };
    const toggleFilters = (item: IFilterListItem, key: string, arr: Array<IFilterListItem>) => {
        const selectedIndex = arr.findIndex(arrItem => arrItem.id === item.id);

        return toggleItemInArray(item, selectedIndex, arr);
    };

    const handleFilter = (key: string, item: IFilterListItem) => {
        const filterArr = toggleFilters(item, key, activeFilters[key]);

        setActiveFilters({ ...activeFilters, [key]: filterArr });
    };

    const handleDublicateEmailsFilter = (emailHash: string) => {
        const selectedIndex = activeFilters[USER_FILTERS.dublicateEmails].findIndex(arrItem => arrItem.emailHash === emailHash);

        if (selectedIndex === -1) {
            const selectedUserInFilters = filters.dublicateEmails.find(user => user.emailHash === emailHash);

            setActiveFilters({ ...activeFilters, [USER_FILTERS.dublicateEmails]: [selectedUserInFilters] });
        } else {
            setActiveFilters({ ...activeFilters, [USER_FILTERS.dublicateEmails]: [] });

        }
    };

    const handleAppliedFilters = () => {
        if (isSaveFilters) {
            const filtersToStorage = {};

            for (let key in filterListInitial) {
                if (filterListInitial.hasOwnProperty(key)) {
                    filtersToStorage[key] = activeFilters[key];
                }
            }

            localStorage.setItem(localStorageFiltersKeys.usersFilters, JSON.stringify(filtersToStorage));
        }

        setUsers(filterByFilters(filterBySearch(defaultUsers, searchText), activeFilters));
        setAppliedFilters(structuredClone(activeFilters));
        setPage(0);
    };

    const handleSaveFilters = () => {
        isSaveFilters && localStorage.removeItem(localStorageFiltersKeys.usersFilters);

        setIsSaveFilters(!isSaveFilters);
    };

    const handleClearFilters = () => {
        setActiveFilters(structuredClone(filterListInitial));
        setAppliedFilters(structuredClone(filterListInitial));
        setUsers(filterBySearch(defaultUsers, searchText));
        setPage(0);
        setIsSaveFilters(false);
        localStorage.removeItem(localStorageFiltersKeys.usersFilters);
    };

    const handleClearActiveFilters = () => {
        setActiveFilters(appliedFilters);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeUsersPerPage = event => {
        setUsersPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const textSearchChange = (newText: string) => {
        if (searchText !== newText) {
            setSearchText(newText);
            setUsers(filterByFilters(filterBySearch(defaultUsers, newText), activeFilters));
            setPage(0);
        }
    };

    const onUserRowClick = (event: React.MouseEvent<HTMLElement>) => {
        const user = users.find(user => user.id == event.currentTarget.id);

        permissions.usersViewPermission && user && history.push({
            pathname: `/users/${user.id}`
        });
    };

    const changeUserStatus = (userId) => {
        const newUsers = [...users];
        const idx = newUsers.findIndex(user => user.id === userId);
        const user = newUsers[idx];

        Api.User.Block({
            id: user.id,
            enabled: !user.enabled
        })
            .then(res => {
                if (res.succeeded) {
                    user.enabled = !user.enabled;
                    setUsers(newUsers);
                }
            });
    };

    const handleActionsMenuClick = (event: React.MouseEvent<HTMLButtonElement>, user) => {
        event.stopPropagation();
        setAnchorMenuEl(event.currentTarget);
        setActiveUser(user);
    };

    const handleActionsMenuClose = (e) => {
        e.stopPropagation();
        setAnchorMenuEl(null);
    };

    const resetToDefault = () => {
        textSearchChange('');
    };

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.titleWrap}>
                    <h1 className={classes.title}>
                        {translations['gen-users']}
                    </h1>
                    <div className={classes.wrapActions}>
                        <div className={classes.filterContainerMobile}>
                            <UserFilters
                                filters={filters}
                                activeFilters={activeFilters}
                                appliedFilters={appliedFilters}
                                handleFilter={handleFilter}
                                handleDublicateEmailsFilter={handleDublicateEmailsFilter}
                                handleAppliedFilters={handleAppliedFilters}
                                handleClearFilters={handleClearFilters}
                                handleClearActiveFilters={handleClearActiveFilters}
                                handleSaveFilters={handleSaveFilters}
                                isSaveFilters={isSaveFilters}
                            />

                        </div>
                        {permissions.usersAddPermission &&
                            <Button
                                data-a="user-list-add-button"
                                component={Link}
                                to={'/users/add'}
                                className={classes.addUser}
                                variant="contained"
                                color="primary"
                            >
                                {translations['users-add-user'] || ''}
                            </Button>
                        }
                    </div>
                </div>
            </div>
            <Divider />
            <PageLayoutWrapper>
                <BackdropSpinner open={!isLoaded} />
                <div className={classes.searchWrap} >
                    <SearchFieldWithSuggest
                        data={defaultUsers.map(user => ({ firstName: user.firstName, lastName: user.lastName, userName: user.userName }))}
                        onTextChange={textSearchChange}
                        externalStyles = {{ searchIcon: classes.searchIcon, wrapOpen: classes.wrapOpen }}
                        searchValue={searchText}
                    />
                    <div className={classes.filterContainer}>
                        <UserFilters
                            filters={filters}
                            activeFilters={activeFilters}
                            appliedFilters={appliedFilters}
                            handleFilter={handleFilter}
                            handleDublicateEmailsFilter={handleDublicateEmailsFilter}
                            handleAppliedFilters={handleAppliedFilters}
                            handleClearFilters={handleClearFilters}
                            handleClearActiveFilters={handleClearActiveFilters}
                            handleSaveFilters={handleSaveFilters}
                            isSaveFilters={isSaveFilters}
                        />
                    </div>
                </div>
                {
                    isLoaded && !users.length &&
                        <Box paddingTop={12}>
                            <Grid container justifyContent="center">
                                <Grid item>
                                    <NoResultsFound
                                        onResetClick={resetToDefault}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                }
                {!!users.length &&
                    <div>
                        <div className={classes.tableWrap}>
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                            >
                                <TableHead >
                                    <TableRow>
                                        {headUsers.map((headUser) => {
                                            const show = headUser?.permissions?.some(permission => permissions[permission]) ?? true;

                                            return (
                                                show &&
                                                    <TableCell
                                                        key={headUser.id}
                                                        className={clsx(classes.cell, headUser.id !== 'name' && classes.mobileHidden)}
                                                        style={{ width: headUser.width || 'auto' }}
                                                    >
                                                        {headUser.label ? translations[headUser.label] : ''}
                                                    </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users
                                        .sort(fullNameSorting)
                                        .slice(page * usersPerPage, page * usersPerPage + usersPerPage).map(user => (
                                            <TableRow
                                                key={user.id}
                                                id={user.id}
                                                hover={permissions.usersViewPermission}
                                                className={clsx(classes.tableRow, permissions.usersViewPermission && classes.tableRowEdit)}
                                                onClick={onUserRowClick}
                                                data-a="users-table-row"
                                            >
                                                <TableCell className={classes.cell}>
                                                    <p className={classes.fullName}>
                                                        <span>{`${user.firstName || ''} ${user.lastName || ''}`}</span>
                                                        {user.isInternal &&
                                                            <Chip
                                                                data-a="user-internal-label"
                                                                className={classes.titleLabel}
                                                                label={translations['users-internal-label']}
                                                                size="small"
                                                                component={'span'}
                                                            />}
                                                    </p>
                                                    <p className={classes.userName}>
                                                        {user.userName}
                                                        <span className={classes.status}>&nbsp;&middot;&nbsp;
                                                            {user.enabled
                                                                ? translations['users-status-active']
                                                                : translations['users-status-blocked']
                                                            }
                                                        </span>
                                                    </p>
                                                </TableCell>
                                                <TableCell className={clsx(classes.cell, classes.mobileHidden)}>
                                                    <p>
                                                        {
                                                            user.passwordUpdateDate
                                                                ? formatDateByPattern(
                                                                    new Date(formatToIsoDateString(user.passwordUpdateDate)),
                                                                    dateTimeFormat,
                                                                    timeZone)
                                                                : ''
                                                        }
                                                    </p>
                                                </TableCell>
                                                <TableCell className={clsx(classes.cell, classes.mobileHidden)}>
                                                    <p>
                                                        {user.enabled
                                                            ? translations['users-status-active']
                                                            : translations['users-status-blocked']
                                                        }
                                                    </p>
                                                </TableCell>
                                                <TableCell className={classes.userName}>
                                                    <p>
                                                        {
                                                            user.userTypes?.map((type, i) => (
                                                                <span key={type}>{
                                                                    translations[`${USER_FILTERS_TITLES.userTypes}-${type}`] + `${user.userTypes.length - 1 !== i ? ', ' : ''}`
                                                                }
                                                                </span>
                                                            ))
                                                        }
                                                    </p>
                                                </TableCell>
                                                {anyActions &&
                                                    <TableCell className={classes.mobileHidden}>
                                                        <IconButton
                                                            className={clsx(
                                                                classes.actionsIcon,
                                                                user.id === activeUser?.id && Boolean(anchorMenuEl) && classes.inline
                                                            )}
                                                            disableRipple
                                                            onClick={event => handleActionsMenuClick(event, user)}
                                                            data-a={'more-button-actions'}
                                                            size="large"
                                                        >
                                                            <MoreHorizIcon color="action" />
                                                        </IconButton>
                                                    </TableCell>
                                                }
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                            {anyActions &&
                                <ActionsMenu
                                    anchorEl={anchorMenuEl}
                                    userId={activeUser.id}
                                    isResetPresent={
                                        (activeUser.userTypes.includes(USER_TYPES.emp) ||
                                        activeUser.userTypes.includes(USER_TYPES.cashier)) &&
                                        !activeUser.isInternal
                                    }
                                    isSupervisor={activeUser.userTypes.includes(USER_TYPES.supervisor)}
                                    userEnabled={activeUser.enabled}
                                    handleClose={handleActionsMenuClose}
                                    changeUserStatus={changeUserStatus}
                                    classes={classes}
                                />}
                        </div>
                        <TablePagination
                            rowsPerPageOptions={rowsPerPageOptions}
                            component="div"
                            count={users.length}
                            rowsPerPage={usersPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeUsersPerPage}
                            labelRowsPerPage={translations['hm-table-rows-per-page']}
                            ActionsComponent={(props) => <TablePaginationActions {...props} onChangePage={handleChangePage} />}
                        />
                    </div>
                }
            </PageLayoutWrapper>
        </div>
    );
};

export default withRouter(UserList);
