import React from 'react';
import clsx from 'clsx';
import useStyles from './styles';

import { IReportTicketModel } from 'api/models/reports';
import { headTicketsReportColumnsDefault, headTicketsReportSelectableColumns } from 'data';
import { IWithId } from 'const';
import {
    Box,
    TableRow,
    TableCell,
} from '@mui/material';
import TicketsReportTableCellContent from 'pages/Reports/Tickets/TicketsReportTable/TicketsReportTableCellContent/TicketsReportTableCellContent';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

const TicketsReportTableRow = ({
    activeColumns,
    row,
    handleRedirect,
    isMobileView,
}: {
    activeColumns: Array<IWithId>;
    row: IReportTicketModel;
    handleRedirect: (id: string) => void;
    isMobileView: boolean;
}) => {
    const classes = useStyles();
    const { globalSettings, translations }: GlobalContextModel = React.useContext(GlobalContext);

    return (
        <TableRow className={classes.row}
            hover
            onClick={() => handleRedirect(row.ticketId)}
            tabIndex={-1}
        >
            {!isMobileView
                ? headTicketsReportSelectableColumns.map((column, index) => {
                    const { id } = column;
                    const showColumn = (column.permission && globalSettings?.settings?.TicketsReportSettings[column.permission]) ?? true;
                    const isColumnActive = activeColumns.some(activeColumn => activeColumn.id === id);
                    const className = clsx(
                        classes.cell,
                        headTicketsReportColumnsDefault.some(defaultColumn => defaultColumn.id === id) && classes.defaultCell,
                        classes[`${column.id}Cell`],
                    );

                    return showColumn && isColumnActive ? (
                        <TableCell className={className}
                            scope={index === 0 ? 'row' : null}
                            key={id}
                        >
                            <TicketsReportTableCellContent
                                id={id}
                                isMobileView={isMobileView}
                                row={row}
                            />
                        </TableCell>
                    ): null;
                }): (
                    <>
                        <TableCell className={clsx(classes.cell, classes.firstCell)}>
                            <TicketsReportTableCellContent
                                id={'ticketId'}
                                isMobileView={isMobileView}
                                row={row}
                            />
                            <TicketsReportTableCellContent className={classes.issuedBetShopName}
                                id={'issuedBetShopName'}
                                isMobileView={isMobileView}
                                row={row}
                            />
                        </TableCell>
                        <TableCell className={classes.cell}>
                            <TicketsReportTableCellContent className={classes.creationDate}
                                id={'creationDate'}
                                isMobileView={isMobileView}
                                row={row}
                            />
                        </TableCell>
                        <TableCell className={clsx(classes.cell, classes.lastCell)}>
                            <Box className={classes.betStatusWithIcons}>
                                <TicketsReportTableCellContent className={classes.betStatus}
                                    id={'betStatus'}
                                    isMobileView={isMobileView}
                                    row={row}
                                />
                                <TicketsReportTableCellContent
                                    id={'icons'}
                                    isMobileView={isMobileView}
                                    row={row}
                                />
                            </Box>
                            <Box className={clsx(classes.boxWithTitle, classes.boxEnd)}>
                                <span className={classes.boxTitle}>
                                    {translations['emp-reports-ticket-cost']}
                                </span>
                                <TicketsReportTableCellContent
                                    id={'costAmount'}
                                    isMobileView={isMobileView}
                                    row={row}
                                />
                            </Box>
                            <Box className={clsx(classes.boxWithTitle, classes.boxEnd)}>
                                <span className={classes.boxTitle}>
                                    {translations['emp-reports-ticket-to-win']}
                                </span>
                                <TicketsReportTableCellContent
                                    id={'toWinAmount'}
                                    isMobileView={isMobileView}
                                    row={row}
                                />
                            </Box>
                        </TableCell>
                    </>
                )
            }
        </TableRow>
    );
};

export default TicketsReportTableRow;
