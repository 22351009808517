import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ breakpoints, palette, spacing }: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
        position: 'relative',

        [breakpoints.down('sm')]: {
            display: 'block',
        }
    },
    header: {
        display: 'flex',
        width: '100%',
        padding: spacing(6, 6, 3),

        [breakpoints.down('lg')]: {
            padding: spacing(6, 2, 2),
        },
        [breakpoints.down('sm')]: {
            display: 'block',
            padding: spacing(2),
        }
    },
    wrap: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flexGrow: 1,
        padding: spacing(2, 6, 0),
        backgroundColor: palette.common.white,

        [breakpoints.down('lg')]: {
            alignItems: 'normal',
            padding: spacing(2, 2, 0),
        },
        [breakpoints.down('sm')]: {
            padding: spacing(0),
        },
    },
    titleWrap: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        overflow: 'hidden',

        [breakpoints.down('sm')]: {
            marginBottom: spacing(0),
            minHeight: '36px',
        },
    },
    title: {
        margin: 0,
        fontSize: '1.5rem',
        fontWeight: 500,
        width: '100%',
    },
    divider: {
        [breakpoints.down('sm')]: {
            display: 'none',
        }
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
        padding: spacing(3, 6, 0),
        backgroundColor: palette.common.white,

        [breakpoints.down('lg')]: {
            padding: spacing(2),
        },
        [breakpoints.down('sm')]: {
            padding: spacing(0, 0, 2),
        }
    },
    alertWrapper: {
        marginBottom: spacing(2)
    },
    container: {
        marginBottom: spacing(2),
        '& > .MuiGrid-item': {
            paddingTop: spacing(0.5),
            paddingBottom: spacing(0.5)
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        minHeight: spacing(5.5),
        marginTop: spacing(2)
    },
    row: {
        padding: ` 0 0 ${spacing(3)}`,
        marginTop: spacing(0),

        [breakpoints.down('md')]: {
            marginTop: 0,
        },

        [breakpoints.down('sm')]: {
            padding: spacing(0, 2, 2)
        },

        ':last-child > &': {
            [breakpoints.down('sm')]: {
                marginBottom: 0,
            }
        },
    },
    subTitle: {
        marginTop: 0,
        marginBottom: spacing(2),

        [breakpoints.down('sm')]: {
            marginTop: -spacing(1)
        },
    },
    description: {
        fontSize: '16px',
        color: 'rgba(0, 0, 0, 0.6)',
        marginBottom: spacing(2),
    },
    note: {
        fontSize: '13px',
        color: 'rgba(0, 0, 0, 0.6)',
    },
    input: {
        position: 'relative',
        width: '100%',
        [breakpoints.down('sm')]: {
            marginBottom: spacing(1)
        },
    },
    dataPicker: {
        padding: `0 ${spacing(2)}`
    },
    button: {
        minWidth: 120,
        textTransform: 'none',
        whiteSpace: 'nowrap'
    },
    permissionError: {
        width: '100%',
        margin: 0,
        fontSize: '12px',
        color: palette.error.main,

        [breakpoints.down('sm')]: {
            padding: spacing(0, 2, 2)
        },
    }
}));

export default useStyles;
