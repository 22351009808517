import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ breakpoints, palette, spacing, typography }: Theme) => ({
    notClickableRow: {
        cursor: 'default',
    },
    betshopName: {
        [breakpoints.down('sm')]: {
            color: palette.grey[700],
            whiteSpace: 'initial',
        }
    },
    inlineTitle: {
        [breakpoints.down('sm')]: {
            marginRight: spacing(4/8),
            fontWeight: typography.fontWeightMedium,
        }
    },
    documentId: {
        [breakpoints.down('sm')]: {
            color: palette.grey[700],
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    },
    userNameWrapper: {
        [breakpoints.down('sm')]: {
            marginRight: 'auto',
        },
    },
    userName: {
        [breakpoints.down('sm')]: {
            marginRight: spacing(1),
        }
    },
    expandableTable: {
        minWidth: '100%',
        tableLayout: 'fixed',

        [breakpoints.down('sm')]: {
            display: 'block',
            borderLeft: 'none',
            borderRight: 'none',
        },
        '& thead': {
            [breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        '& tbody': {
            [breakpoints.down('sm')]: {
                display: 'block',
            }
        },
    },
    expandableTableWrapper: {
        overflow: 'auto',

        [breakpoints.down('sm')]: {
            flexGrow: 1
        }
    },
    financialPerformanceCell: {
        padding: spacing(2, 2.5, 2, 0)
    },
    masterRowCell: {
        fontWeight: 500,
        padding: spacing(0)
    },
    entityCell: {
        width: '20%',
        padding: '0px',
        maxWidth: '160px'
    },
    expandableCell: {
        padding: spacing(0)
    },
    arrowCell: {
        padding: spacing(2, 1)
    },
    hiddenArrowCell: {
        visibility: 'hidden'
    }
}));

export default useStyles;
