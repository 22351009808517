import * as React from 'react';
import { useLocation, RouteComponentProps } from 'react-router-dom';
import { Box, IconButton, Grid, Typography, Tooltip } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import PrintDisabledIcon from '@mui/icons-material/PrintDisabled';
import useStyles from './styles';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import { BetStatusBadge } from 'components/Badges/Badges';

interface ITicketDetailsHeader {
    ticketId: string;
    betStatus: number;
    isPaid: boolean;
    isPrinted: boolean;
    isPaidByCard: boolean;
}

const TicketDetailsHeader = ({
    history,
    data
}: {
        readonly history: RouteComponentProps['history'];
        readonly data: ITicketDetailsHeader;
    }) => {
    const { state } = useLocation();
    const { ticketId, betStatus, isPaid, isPrinted, isPaidByCard } = data;
    const classes = useStyles({});
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    const fromCardTransactionsReport = state && state['pathFrom'] === '/reports/card-transactions';
    const fromSupervisorReport = state && state['pathFrom'] === '/reports/supervisor';

    const backNavigationClickHandler = () => {
        if (fromCardTransactionsReport || fromSupervisorReport) {
            history.goBack();
        } else {
            history.push({ pathname: '/reports/tickets', state });
        }
    };

    return (
        <Grid container className={classes.bodyWrapContainer}>
            <Grid item>
                <IconButton
                    className={classes.iconButton}
                    onClick={backNavigationClickHandler}
                    edge="start"
                    color="inherit"
                    size="small"
                    disableRipple
                    data-a="ticket-back-nav-button"
                >
                    <ArrowBack htmlColor="black" />
            &nbsp;
                    <Typography variant="button" className={classes.iconLabel}>
                        {fromCardTransactionsReport
                            ? translations['emp-reports-card-transactions-title']
                            : fromSupervisorReport
                                ? translations['gen-supervisor-report-title']
                                : translations['gen-tickets']}
                    </Typography>
                </IconButton>
                <Typography
                    variant="h5"
                    component="h5"
                    className={classes.title}
                    data-a="ticket-id-label"
                >
                    {ticketId}
                </Typography>
                <Box display="flex" alignItems="center">
                    {ticketId &&
                        <BetStatusBadge
                            betStatus={betStatus}
                            className={classes.betStatusBadge}
                            dataA="ticket-details-status-label"
                        />
                    }
                    {isPaid && (
                        <BetStatusBadge
                            betStatus={2}
                            className={classes.betStatusBadge}
                            dataA="ticket-details-paid-label"
                        >
                            {translations['emp-payout-status-paid']}
                        </BetStatusBadge>
                    )}
                    {isPaidByCard && (
                        <BetStatusBadge
                            betStatus={0}
                            className={classes.betStatusBadge}
                            dataA="ticket-details-by-card-label"
                        >
                            {translations['emp-reports-ticket-paid-by-card']}
                        </BetStatusBadge>
                    )}
                    {ticketId && !isPrinted && (
                        <Tooltip
                            title={translations['emp-reports-ticket-is-not-printed'] || ''}
                            data-a="ticket-details-no-printed-label"
                        >
                            <PrintDisabledIcon className={classes.icon} />
                        </Tooltip>
                    )}
                </Box>
            </Grid>
        </Grid>
    );
};

export default TicketDetailsHeader;
