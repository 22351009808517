import React from 'react';
import { Order, rowsPerPageOptions } from 'const';
import {
    Box,
    List,
    TablePagination,
} from '@mui/material';

import { IReportFinancialPerformanceRowModel } from 'api/models/reports';

import TablePaginationActions from 'components/TablePaginationActions/TablePaginationActions';
import { PAGINATION_ACTIONS } from 'components/TablePaginationActions/TablePaginationActionsDumb';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';
import useStyles from './styles';
import FinancialPerformanceReportListItem from './FinancialPerformanceReportListItem/FinancialPerformanceReportListItem';
import formatStr from 'utils/formatStr';

const FinancialPerformanceReportList = ({
    page,
    rows,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
}: {
  order: Order;
  page: number;
  rows: Array<IReportFinancialPerformanceRowModel>;
  rowsPerPage: number;
  handleChangePage: (event, action: PAGINATION_ACTIONS) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    const pageNum = Math.min(Math.ceil(rows.length / rowsPerPage), page);

    const classes = useStyles({});

    return (
        <>
            <Box>
                <List className={classes.list} aria-labelledby="nested-list-subheader">
                    {rows.map((row) => (
                        <FinancialPerformanceReportListItem key={row.entityName} row={row} />
                    ))}
                </List>
            </Box>
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={pageNum}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={translations['hm-table-rows-per-page']}
                labelDisplayedRows={(paginationInfo) => {
                    return `${formatStr(translations['hm-table-pagination-displayed-rows'], { 'from': paginationInfo.from, 'to': paginationInfo.to, 'count': paginationInfo.count })}`;
                }}
                ActionsComponent={(props) => <TablePaginationActions {...props} onChangePage={handleChangePage} />}
            />
        </>
    );
};

export default FinancialPerformanceReportList;
