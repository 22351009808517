import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',

        [theme.breakpoints.down('sm')]: {
            display: 'grid',
            gridTemplateRows: 'auto auto 1fr'
        }
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(6, 6, 3),

        [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(6, 2, 2),
        },
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            padding: theme.spacing(2),
        }
    },
    titleWrap: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    title: {
        margin: 0,
        fontSize: '1.5rem',
        fontWeight: 500,
        width: '100%'
    },
    addButton: {
        minWidth: 120,
        textTransform: 'none',
        whiteSpace: 'nowrap'
    },
    searchWrap: {
        position: 'relative',
        marginBottom: theme.spacing(3),
        marginRight: theme.spacing(2),

        [theme.breakpoints.down('sm')]: {
            order: -1,
            marginBottom: theme.spacing(0),
            marginRight: theme.spacing(0),
        }
    },
}));

export default useStyles;
