import * as React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Paper, Grid, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { IProps, IChartData } from './types';
import { GRID_COLUMNS as columns, KPI_TYPES } from 'const';
import { getOptions } from './options';
import { commonStyles } from './styles';
import { dashboardValue } from '../../utils/dashboardValue';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import { formatData, countMinValueToDisplay } from './business';
import { numberStyles } from 'utils/formatNumber';

const ChartComponent = (props: IProps) => {
    const { classes, data } = props;
    const { globalSettings, translations }: GlobalContextModel = React.useContext(GlobalContext);
    const chartData: IChartData = data;

    const minValueToDisplay = chartData.kpiType === KPI_TYPES.SportGGR || KPI_TYPES.Margin ? countMinValueToDisplay(chartData.data) : 0;

    const options = getOptions(data.color, minValueToDisplay, chartData.data.map(a => ({ ...a })));
    const series = [
        {
            name: props.data.title,
            data: formatData(chartData.data.map(a => ({ ...a })), minValueToDisplay)
        }
    ];

    const value = dashboardValue(
        chartData.kpiType,
        chartData.absoluteValue,
        numberStyles(globalSettings?.settings?.Currency),
        globalSettings?.settings?.CurrencySign,
        globalSettings?.settings?.CurrencyFormat
    );

    return chartData?.data?.length ? (
        <Paper className={classes.root}>
            <Grid className={classes.headerContainer} container spacing={1}>
                <Grid item lg={columns.col6} xs={columns.col12} sm={columns.col6}>
                    <Typography className={classes.header} key={chartData.absoluteValueTitle} variant="h6" component="h6" >
                        {translations[chartData.absoluteValueTitle]}
                        <Typography className={classes.subHeader} variant="body1" component="span" gutterBottom>
                            {translations[chartData.axisXUnits]}
                        </Typography>
                    </Typography>
                </Grid>
                <Grid
                    item
                    lg={columns.col6}
                    xs={columns.col12}
                    sm={columns.col6}
                >
                    <Typography
                        className={classes.chartTitleWrap}
                        display="block"
                        variant="caption"
                        gutterBottom
                    >
                        <Typography className={classes.valueText} key={value} variant="h4" component="h4" gutterBottom>
                            {value}
                        </Typography>
                    </Typography>
                </Grid>
            </Grid>
            <ReactApexChart
                options={options}
                series={series.map(item => ({ name: item.name && translations[item.name], data: item.data }))}
                type={chartData.type}
                height={250}
            />
        </Paper>
    ) : null;
};

export default withStyles(commonStyles)(ChartComponent);
