import React, { useState } from 'react';
import clsx from 'clsx';

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { Button, IconButton, TextField, InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { IconButtonProps } from '@mui/material/IconButton';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import { transitionDuration } from 'const';
import { overrideStyles } from 'utils/styleExtension';
import { useWidth } from 'utils/customHooks';

const useStyles = makeStyles((theme: Theme) => ({
    wrap: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        backgroundColor: '#fff',

        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-start',
            width: '100%',
            height: 0,
            transitionProperty: 'height, margin-bottom',
            transitionDuration
        },
        '& input': {
            padding: `${theme.spacing(1)} 0`,
            transitionProperty: 'height, padding',
            transitionDuration,

            '&::placeholder': {
                [theme.breakpoints.down('sm')]: {
                    color: 'transparent'
                }
            },

            [theme.breakpoints.down('sm')]: {
                height: 0,
                padding: 0
            }
        },
        '& fieldset': {
            [theme.breakpoints.down('sm')]: {
                transitionProperty: 'opacity, height, padding',
                opacity: 0,
                transitionDuration,
            }
        },
    },
    wrapOpen: {
        height: theme.spacing(4.5),
        marginBottom: theme.spacing(2),

        '& input': {
            '&::placeholder': {
                [theme.breakpoints.down('sm')]: {
                    color: 'inherit'
                }
            },
            [theme.breakpoints.down('sm')]: {
                height: theme.spacing(2.5),
                padding: `${theme.spacing(1)} 0`,
                transitionProperty: 'height, padding',
                transitionDuration,
            }
        },

        '& fieldset': {
            [theme.breakpoints.down('sm')]: {
                height: theme.spacing(5),
                opacity: 1
            }
        }
    },
    searchField: {
        width: '100%',
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            '-moz-appearance': 'none',
            'margin': 0
        },
        '& input[type=number]': {
            '-webkit-appearance': 'textfield',
            '-moz-appearance': 'textfield'
        },
    },
    searchInputRoot: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        height: '2.2em',
        '&$searchInputFocused': {
            '& $searchInputNotchedOutline': {
                borderWidth: '1px',
            },
        },
    },
    searchInputFocused: {},
    searchInputNotchedOutline: {
        borderRight: 'none',
    },
    searchInput: {
        paddingRight: 0,

        '& input': {
            padding: theme.spacing(1, 0),
            transitionProperty: 'height, padding',
            transitionDuration,
            [theme.breakpoints.down('sm')]: {
                height: 0,
                padding: 0
            }
        },
        '& fieldset': {
            transitionDuration,
            [theme.breakpoints.down('sm')]: {
                height: 0,
                padding: 0,
                overflow: 'hidden'
            }
        }
    },
    searchInputOpen: {
        '& input': {
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(1, 0),
                transitionProperty: 'height, padding',
                transitionDuration,
            }
        }
    },
    startAdornment: {
        height: 0
    },
    endAdornment: {
        marginLeft: 0
    },
    searchIcon: {
        position: 'absolute',
        top: theme.spacing(-53/8),
        right: theme.spacing(-71/8),
    },
    closeInputButton: {
        textTransform: 'capitalize'
    },
    hide: {
        display: 'none'
    },
    searchButtonWrapper: {
        display: 'flex',
        overflow: 'hidden',
        flexShrink: 0,
        height: '100%',
        alignItems: 'center',

        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            top: -1,
        },
    },
    searchButton: {
        height: theme.spacing(35/8),
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,

        '&:hover': {
            boxShadow: 'none',
        },
    }
}));

const StyledCloseButton = withStyles({
    root: {
        padding: '6px'
    }
})((props: IconButtonProps) => (<IconButton aria-label="close" {...props} size="large"><CloseIcon /></IconButton>));

function SearchFieldWithButton({
    className,
    externalStyles={},
    value='',
    type,
    onChange=() => {},
    onReset=() => {},
    onSubmit=() => {},
    placeholder,
    btnPlaceholder,
    isValid = true,
    maxSymbols
}: {
    className?: string;
    externalStyles?: Object;
    value?: string;
    type?: React.InputHTMLAttributes<unknown>['type'];
    onChange?(value: string): void;
    onReset?(): void;
    onSubmit?(value: string): void;
    btnPlaceholder?: string;
    placeholder?: string;
    isValid?: boolean;
    maxSymbols: number;
}) {
    const classesBase = useStyles({});
    const classes = overrideStyles(classesBase, externalStyles);
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);
    const isMobileView = useWidth() === 'xs';

    const [isMobileSearchOpen, setIsMobileSearchOpen] = useState<boolean>(false);

    const changeHandler: React.ChangeEventHandler<HTMLInputElement> = function({ target: { value } }) {
        const maxLengthValue = value.toString().slice(0, maxSymbols);

        onChange(maxLengthValue);
    };

    const onKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            onSubmit(value);
        }
    };

    function onSearchToggle() {
        if (isMobileView) {
            isMobileSearchOpen && onReset();
            setIsMobileSearchOpen(!isMobileSearchOpen);
        }
    }

    return (
        <div className={clsx(className, classes.wrap, isMobileSearchOpen && classes.wrapOpen)} data-a="searchbyNumberWrap">
            <TextField classes={{ root: classes.searchField }}
                placeholder={placeholder?.length && placeholder}
                value={value}
                error={!isValid && value.length > 0}
                onReset={onReset}
                onChange={changeHandler}
                onKeyDown={onKeyDownHandler}
                variant="outlined"
                autoComplete="off"
                color="primary"
                type={type}
                InputProps={{
                    className: isMobileSearchOpen ? classes.searchInputOpen : classes.searchInput,
                    classes: {
                        root: classes.searchInputRoot,
                        focused: classes.searchInputFocused,
                        notchedOutline: classes.searchInputNotchedOutline,
                    },
                    startAdornment: (
                        <InputAdornment className={classes.startAdornment}
                            position="start"
                        >
                            {isMobileSearchOpen
                                ? (
                                    <span
                                        className={clsx(classes.searchIcon, classes.closeInputButton)}
                                        onClick={onSearchToggle}
                                    >
                                        {translations['cancel']}
                                    </span>)
                                : (
                                    <SearchIcon classes={isMobileView && { root: classes.searchIcon } || {}} onClick={onSearchToggle} />)}
                        </InputAdornment>),
                    endAdornment: value &&
                        <InputAdornment className={classes.endAdornment}
                            position="end"
                            data-a="InputAdornment"
                        >
                            <StyledCloseButton onClick={onReset} className={clsx(isMobileView && !isMobileSearchOpen && classes.hide)} data-a="close-button" />
                        </InputAdornment>
                }}
            />
            <div className={classes.searchButtonWrapper}>
                <Button className={classes.searchButton}
                    color="primary"
                    variant="contained"
                    onClick={() => onSubmit(value)}
                    data-a="search-button"
                >
                    {btnPlaceholder?.length && btnPlaceholder || translations['hm-search']}
                </Button>
            </div>
        </div>
    );
}

export default SearchFieldWithButton;
