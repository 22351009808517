import * as React from 'react';
import { Link, Button, Menu, MenuItem, MenuList, Theme, Typography, Icon } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { GlobalContext } from 'context/globalContext';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { GlobalContextModel } from 'api/models/general';

const { useState, useContext } = React;

const useStyles = makeStyles((theme: Theme) => ({
    wrap: {
        overflow: 'hidden',
    },
    menu: {
        width: 227,
        outline: 'none'
    },
    button: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        height: theme.spacing(6),
        padding: theme.spacing(0.5, 1, 0.5, 2),
        borderTop: `1px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.divider}`,
        borderRadius: 0,
        '&:hover': {
            backgroundColor: '#fef5eb',
        },
    },
    buttonBox: {
        display: 'flex',
        alignItems: 'center'
    },
    name: {
        margin: 0,
        padding: theme.spacing(0, 2),
        borderTop: `1px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.divider}`,
        lineHeight: 3.3,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    menuItem: {
        padding: 0,
        lineHeight: 1
    },
    link: {
        display: 'inline-block',
        width: '100%',
        overflow: 'hidden',
        fontSize: '1rem',
        boxSizing: 'border-box',
        minHeight: theme.spacing(6),
        lineHeight: '2.2',
        padding: '6px 16px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',

        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: '#fef5eb',
            textDecoration: 'none',
        },
    },
    menuIcon: {
        marginRight: theme.spacing(2)
    },
}));

const MenuOperators = () => {
    const classes = useStyles({});

    const { globalSettings, translations }: GlobalContextModel = useContext(GlobalContext);
    const currentOperatorKey = globalSettings.user?.ClientId;
    const operators = globalSettings?.user?.DomainMappings || {};
    const showOperators = Object.keys(operators).length > 1;

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleOpenMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const onClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={classes.wrap}>
            {
                showOperators
                    ? (
                        <>
                            <Button
                                id="operator-button"
                                classes={{ root: classes.button }}
                                className={classes.button}
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={handleOpenMenu}
                            >
                                <span className={classes.buttonBox}>
                                    <Icon className={classes.menuIcon}>{'business'}</Icon>
                                    <Typography
                                        noWrap
                                        align="left"
                                        variant="inherit"
                                    >
                                        {currentOperatorKey ? translations[`operator-${currentOperatorKey}`] : ''}
                                    </Typography>
                                </span>
                                {showOperators && <ArrowDropDownIcon />}
                            </Button>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={onClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                <MenuList className={classes.menu}>
                                    {Object.keys(operators).map(operator => {

                                        return (
                                            operator !== currentOperatorKey &&
                                                (
                                                    <MenuItem key={operator} className={classes.menuItem}>
                                                        <Link
                                                            key={operator}
                                                            className={classes.link}
                                                            href={`https://${operators[operator]}/`}
                                                            color="inherit"
                                                            underline="none"
                                                        >
                                                            {translations[`operator-${operator}`]}
                                                        </Link>
                                                    </MenuItem>)
                                        );
                                    })}
                                </MenuList>
                            </Menu>
                        </>
                    )
                    : (
                        <p className={classes.name}>{currentOperatorKey ? translations[`operator-${currentOperatorKey}`] : ''}</p>
                    )
            }
        </div>
    );
};

export default MenuOperators;
