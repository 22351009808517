import makeStyles from '@mui/styles/makeStyles';
import { palette } from 'theme';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            minHeight: '100%'
        }
    },
    header: {
        padding: theme.spacing(6, 6, 3),

        [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(6, 2, 2),
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        }
    },
    titleWrap: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    title: {
        margin: 0,
        fontSize: '1.5rem',
        fontWeight: 500,
        width: '100%'
    },
    alert: {
        display: 'flex',
        marginBottom: theme.spacing(3),
        padding: theme.spacing(0.5, 1),
        fontWeight: 500,
        borderRadius: '4px',
        backgroundColor: palette.primary.lighter,
        alignItems: 'center',

        '& span': {
            flex: '1 1 auto',
            padding: theme.spacing(0, 1)
        }
    },
    searchWrap: {
        position: 'relative',
        marginBottom: theme.spacing(3),
        marginRight: theme.spacing(2),

        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(0),
            marginRight: theme.spacing(0),
        }
    },
    searchIcon: {
        position: 'absolute',
        right: 0,
        top: theme.spacing(-15.25)
    },
    wrapOpen: {
        height: theme.spacing(4.5),

        '& fieldset': {
            [theme.breakpoints.down('sm')]: {
                height: theme.spacing(5),
                opacity: 1
            }
        }
    },
    controlsWrap: {
        width: '100%',

        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            padding: `0 ${theme.spacing(2)}`
        }
    },
    controlsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignSelf: 'normal',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),

        [theme.breakpoints.down('sm')]: {
            minHeight: theme.spacing(4),
            margin: theme.spacing(0),
            paddingLeft: theme.spacing(1.5),
            paddingRight: theme.spacing(1.5),
            paddingBottom: theme.spacing(2)
        }
    },
    filterContainer: {
        display: 'flex',
        flex: '1 0 auto',
        marginLeft: theme.spacing(2),
    },
    tableWrap: {
        position: 'relative',
        display: 'flex',
        alignSelf: 'normal',
        flexDirection: 'column',
        flexGrow: 1,
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(1)
        }
    },
    table: {
        tableLayout: 'fixed',
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: '4px',
        '& thead': {
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        '& tr': {
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                flexDirection: 'column',
                padding: theme.spacing(1, 2),
                borderBottom: '1px solid #e0e0e0'
            },
            '&:last-child': {
                [theme.breakpoints.down('sm')]: {
                    borderBottom: 'none'
                }
            }
        },
        '& td': {
            [theme.breakpoints.down('sm')]: {
                padding: 0,
                borderBottom: 'none'
            }
        },
    },
    headerCell: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    cell: {
        '& p': {
            margin: 0,
            wordBreak: 'break-word'
        }
    },
    nameLabel: {
        marginLeft: theme.spacing(1),
        color: theme.palette.common.white,
        backgroundColor: '#808080'
    },
    timeCell: {
        [theme.breakpoints.down('sm')]: {
            order: 0,
            '& p': {
                fontWeight: 'bold'
            }
        }
    },
    actionCell: {
        [theme.breakpoints.down('sm')]: {
            order: 1,
            '& p': {
                color: theme.palette.text.secondary,
                whiteSpace: 'initial'
            }
        },
    },
    userCell: {
        [theme.breakpoints.down('sm')]: {
            order: 2
        }
    }
}));

export default useStyles;
