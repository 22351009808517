import React from 'react';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { List, AutoSizer, CellMeasurer, CellMeasurerCache, WindowScroller } from 'react-virtualized';
import { IEposListItemModel } from 'api/models/epos';

import Api from 'api/Api';
import TerminalsTilesRow from 'pages/Epos/components/TerminalsTiles/TerminalsTilesRow/TerminalsTilesRow';

import { EPOS_REQUEST_IMGS_INTERVAL, EPOS_STATUS } from 'const';

import { columnsInRow } from 'pages/Epos/utils/utils';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import { cancelRequests } from 'utils/requestCancelation';

let observer;
let refreshTimer;
let visibleRowsIds: Set<string> = new Set();

interface IData {
    name: string;
    location: string;
    status: string;
    deliveryTime: string;
    errors: Array<string | number>;
    os?: string;
    shell?: string;
    ip?: string;
    dataSetVersion?: string;
    minStake?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
    wrap: {
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 2, 2)
        },
    }
}));

const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 200
});

const { useState, useRef, useEffect } = React;
let visibleMachineIdsArr: Set<string> = new Set();

const apiRequests = {
    terminalImages: null
};

const TerminalsTiles = ((props: {
    rows: Array<IEposListItemModel>;
    selectedIds: Array<number>;
    rowsPerPage: number;
    handleSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleRequestSort: (event: React.MouseEvent<HTMLElement>, property: keyof IData) => void;
    handleTirminalSelect: (event: React.MouseEvent<unknown>, id: number) => void;
    handleChangePage: (event: React.MouseEvent<HTMLElement>, newPage: number) => void;
    handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleClearSelectedRows: () => void;
    handleRedirect: (id: number) => void;
    isMobileView: boolean;
}) => {
    const classes = useStyles({});
    const { isCommonMenuOpen }: GlobalContextModel = React.useContext(GlobalContext) || { isCommonMenuOpen: true };
    const { rows, selectedIds, handleRedirect, handleTirminalSelect, isMobileView } = props;
    const [images, setImages] = useState({});
    const observeRef = useRef(null);
    const loadDataRef = useRef(null);
    const listRef = useRef(null);
    const loadedImages = new Set();
    const [numColumns, setNumColumns] = useState(columnsInRow(window.innerWidth));

    observer = new IntersectionObserver((entries) => {
        entries.forEach(tilesItem => {
            const id = tilesItem?.target?.attributes['data-rowindex']?.value;

            tilesItem.isIntersecting
                ? visibleRowsIds.add(id)
                : visibleRowsIds.delete(id);
        });

        refreshTimer && clearTimeout(refreshTimer);
        refreshTimer = setTimeout(() => {
            visibleRowsIds.size && loadDataRef.current(true);
        }, 100);
    }, { threshold: 0.2 });

    useEffect(() => {
        window.addEventListener('resize', onResize);

        const interval = setInterval(() => {
            loadDataRef.current(false);
        }, EPOS_REQUEST_IMGS_INTERVAL);

        return () => {
            cancelRequests(apiRequests);
            clearTimeout(refreshTimer);
            clearInterval(interval);
            window.removeEventListener('resize', onResize);
        };
    }, []);

    useEffect(() => {
        const time = setTimeout(() => {
            cache.clearAll();
            listRef.current.forceUpdateGrid();
        }, 300);

        return () => clearTimeout(time);
    }, [isCommonMenuOpen, images, rows]);

    const setVisibleIds = () => {
        visibleMachineIdsArr.clear();

        Array.from(visibleRowsIds).forEach((id: string) => {
            const itemIndexStart = Number(id) * numColumns;
            const itemIndexEnd = Number(id) * numColumns + numColumns;

            for (let i = itemIndexStart; i < itemIndexEnd; i++) {
                rows[`${i}`] && rows[`${i}`].status !== EPOS_STATUS.OFFLINE && visibleMachineIdsArr.add(rows[`${i}`].machineId);
            }
        });
    };

    const loadData = async (useCache) => {
        let res;

        setVisibleIds();

        const idsToLoad = useCache
            ? Array.from(visibleMachineIdsArr).filter(id => !loadedImages.has(id))
            : Array.from(visibleMachineIdsArr);

        if (idsToLoad.length) {
            apiRequests.terminalImages = Api.Image.GetTerminalImages(
                idsToLoad,
                []
            );

            try {
                res = await apiRequests.terminalImages;

                if (res) {
                    let resKeys = [];
                    let img = {};

                    resKeys = Object.keys(res);
                    resKeys.forEach(id => loadedImages.add(id));

                    const keysArray = [...new Set([...Object.keys(images || {}), ...resKeys])];

                    keysArray.map(resKey => res?.[resKey]?.length ? img[resKey] = res[resKey][0] : img[resKey] = images ? images[resKey] : '');

                    setImages(img);
                }
            } catch (error) {

            }
        }
    };

    const onResize = () => {
        cache.clearAll();
        listRef.current.forceUpdateGrid();
        setNumColumns(columnsInRow(window.innerWidth));
    };

    loadDataRef.current = loadData;

    const rowWithMeasurer = ({ index, parent, key, style }) => {
        return (
            <CellMeasurer
                key={key}
                cache={cache}
                parent={parent}
                columnIndex={0}
                rowIndex={index}
            >
                <TerminalsTilesRow
                    rowIndex={index}
                    numColumns={numColumns}
                    rows={rows}
                    selectedIds={selectedIds}
                    handleRedirect={handleRedirect}
                    handleTirminalSelect={handleTirminalSelect}
                    images={images}
                    style={style}
                    isMobileView={isMobileView}
                />
            </CellMeasurer>
        );
    };

    return (
        <div ref={observeRef} className={classes.wrap}>
            <WindowScroller>
                {({ height, scrollTop }) => (
                    <AutoSizer disableHeight>
                        {({ width }) => (
                            <List
                                ref={listRef}
                                autoHeight
                                height={height}
                                width={width}
                                scrollTop={scrollTop}
                                rowCount={Math.ceil(rows.length / numColumns)}
                                rowHeight={cache.rowHeight}
                                rowRenderer={rowWithMeasurer}
                                deferredMeasurementCache={cache}
                                overscanRowCount={1}
                            />
                        )}
                    </AutoSizer>
                )}
            </WindowScroller>
        </div>
    );
});

export default TerminalsTiles;

export { observer };
