import React, { useState, useEffect, useRef } from 'react';
import { useStyles as useFilterStyles, Header, ControlPanel, FilterButton } from 'components/Filters/Filters';
import { Drawer } from '@mui/material';

import { FiltersBox, FiltersBoxWithCount } from 'components/Filters/FiltersBox/FiltersBox';
import { sortingByClicked } from 'pages/Epos/utils/utils';

import { IFilterListItem } from 'const';

import {
    USER_FILTERS_TITLES
} from 'api/models/user';

const UserFilters = ({
    filters,
    activeFilters,
    appliedFilters,
    handleFilter,
    handleDublicateEmailsFilter,
    handleAppliedFilters,
    handleClearFilters,
    handleClearActiveFilters,
    handleSaveFilters,
    isSaveFilters
}) => {
    const classes = useFilterStyles({});
    const filterListOpenExpansions = {};

    Object.keys(filters).forEach(filter => filterListOpenExpansions[filter] = false);

    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const [openExpansions, setOpenExpansions] = useState({});
    const filtersWrapperRef = useRef<HTMLDivElement>();

    useEffect(() => {
        setOpenExpansions({ ...checkOpenExtension(appliedFilters) });
    }, [isOpenDrawer]);

    const handleOpenDrawer = () => {
        setIsOpenDrawer(true);
    };

    const handleCloseDrawer = () => {
        setOpenExpansions({ ...checkOpenExtension(appliedFilters) });
        setIsOpenDrawer(false);
        handleClearActiveFilters();
    };

    const handleFilterClick = (label, item) => {
        handleFilter(label, item);
    };

    const handleApplyFilters = () => {
        setOpenExpansions({ ...checkOpenExtension(appliedFilters) });
        setIsOpenDrawer(false);
        handleAppliedFilters();
    };

    const onClearFilters = () => {
        setOpenExpansions({ ...filterListOpenExpansions });
        handleClearFilters();
    };

    const handleExpanded = (key: string) => {
        setOpenExpansions({ ...openExpansions, [key]: !openExpansions[key] });
    };

    const checkOpenExtension = (activeFilterIds) => {
        const newOpenExpansions = {};

        for (let key in activeFilterIds) {
            if (activeFilterIds.hasOwnProperty(key)) {
                newOpenExpansions[key] = !!activeFilterIds[key].length;
            }
        }

        return newOpenExpansions;
    };

    function countFilterQty() {
        const filtersWithSelection = Object.values(appliedFilters)
            .filter((filters: Array<IFilterListItem>) => filters.length);

        return filtersWithSelection.length;
    }

    return (
        <>
            <FilterButton
                filterQty={countFilterQty()}
                handleOpenDrawer={handleOpenDrawer}
            />
            <Drawer
                className={classes.drawerWrap}
                classes={{
                    root: classes.drawerRoot,
                    paper: classes.drawerWrap
                }}
                anchor="right"
                open={isOpenDrawer}
                onClose={handleCloseDrawer}
            >
                <div className={classes.drawer}>
                    <Header onCloseClick={handleCloseDrawer} />
                    <div className={classes.filtersWrap}
                        ref={filtersWrapperRef}
                    >
                        {
                            filters['userTypes']?.length &&
                                <FiltersBox
                                    label="userTypes"
                                    title={USER_FILTERS_TITLES['userTypes']}
                                    data={sortingByClicked(filters['userTypes'], appliedFilters['userTypes'])}
                                    activeFilterIds={activeFilters}
                                    handleFilterClick={handleFilterClick}
                                    expanded={openExpansions['userTypes']}
                                    handleExpanded={handleExpanded}
                                    translationKey={USER_FILTERS_TITLES['userTypes'] || ''}
                                />
                        }
                        <FiltersBoxWithCount
                            label="dublicateEmails"
                            title={USER_FILTERS_TITLES['dublicateEmails']}
                            data={filters}
                            activeFilters={activeFilters}
                            appliedFilters={appliedFilters}
                            handleFilterClick={handleDublicateEmailsFilter}
                            expanded={openExpansions['dublicateEmails']}
                            handleExpanded={handleExpanded}
                            translationKey={USER_FILTERS_TITLES['dublicateEmails'] || ''}
                        />
                    </div>
                    <ControlPanel
                        onApplyFilters={handleApplyFilters}
                        onClearFilters={onClearFilters}
                        onSaveFilters={handleSaveFilters}
                        isSaveFilters={isSaveFilters}
                    />
                </div>
            </Drawer>
        </>
    );
};

export default UserFilters;
