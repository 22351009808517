import * as React from 'react';
import { CircularProgress, Backdrop } from '@mui/material';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

interface IBackdropSpinnerProps {
    open: boolean;
    fullScreen?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    backdrop: ({ fullScreen }: Partial<IBackdropSpinnerProps>) => ({
        position: fullScreen ? 'fixed' : 'absolute',
        zIndex: theme.zIndex.drawer - 1,
        backgroundColor: 'rgba(255, 255, 255, 0.8)'
    }),
}));

const BackdropSpinner = ({ open, fullScreen }: IBackdropSpinnerProps) => {
    const classes = useStyles({ fullScreen });

    return (
        <Backdrop open={open} className={classes.backdrop}>
            <CircularProgress color="primary" />
        </Backdrop>
    );
};

export default BackdropSpinner;
