import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ palette }: Theme) => ({
    outlinedInput: ({ content }: { content: string }) => ({
        '&::before': {
            content: content?.length ? `'${content}'` : '',
            flexShrink: 0,
            alignSelf: 'stretch',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: 7,
            padding: '18.5px',
            backgroundColor: '#f0f0f0',
        }
    }),
    withPrefix: {
        '&::before': {
            position: 'relative',
            left: -14,
        }
    },
    outlinedInputError: {
        '& .MuiOutlinedInput-input': {
            color: palette.error.main,
        },
    },
    notchedOutline: {
        '& > legend': {
            marginLeft: '60px',
        }
    },
    shrink: {},
    inputLabel: {
        '&$shrink': {
            transform: 'translate(74px, -6px) scale(0.75)',
        },
    },
    noWrap: {
        whiteSpace: 'nowrap',
    }
}));

export default useStyles;
