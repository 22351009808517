import * as React from 'react';
import { Grid, Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PrintIcon from '@mui/icons-material/Print';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

const useStyles = makeStyles((theme) => ({
    containter: {
        [theme.breakpoints.up('lg')]: {
            marginTop: theme.spacing(3),
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            padding: `0 ${theme.spacing(2)}`,
        },
    },
    buttonLabel: {
        padding: '0 0.5rem',
        fontSize: '0.9rem',
        lineHeight: 1.75,
        fontWeight: 600,
        textTransform: 'none',
    },
}));

const { useContext } = React;

const TicketDetailsActions = (props) => {
    const classes = useStyles({});
    const { translations, permissions }: GlobalContextModel = useContext(GlobalContext);
    const { handleReprintAuthorization, handleTiketVoid, disabledTicketVoid } = props;

    return (
        <Grid data-a="ticket-authorize-reprint-wrap" container spacing={2} className={classes.containter}>
            {permissions.reportTicketsAuthorizeReprint &&
                <Grid item>
                    <Button onClick={handleReprintAuthorization} data-a={'ticket-authorize-reprint-button'}>
                        <PrintIcon />
                        <Typography className={classes.buttonLabel}>
                            {translations['emp-reports-ticket-authorize-reprint']}
                        </Typography>
                    </Button>
                </Grid>
            }
            {permissions.reportTicketsTicketVoid &&
                <Grid item>
                    <Button onClick={handleTiketVoid} data-a={'ticket-void-button'} disabled={disabledTicketVoid}>
                        <CancelOutlinedIcon />
                        <Typography className={classes.buttonLabel}>
                            {translations['emp-reports-ticket-void']}
                        </Typography>
                    </Button>
                </Grid>
            }
        </Grid>
    );
};

export default TicketDetailsActions;
