import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { BET_STATUS } from 'const';

const useStyles = makeStyles(({ palette, spacing }: Theme) => {
    const getColorStyle = (betStatus: BET_STATUS) => {
        let colorStyle = {};

        switch (betStatus) {
            case BET_STATUS.OPENED:
                colorStyle = palette['badge'].pending;
                break;
            case BET_STATUS.LOST:
                colorStyle = palette['badge'].warning;
                break;
            case BET_STATUS.WON:
            case BET_STATUS.CASHOUT:
                colorStyle = palette['badge'].success;
                break;
            case BET_STATUS.DRAW:
            case BET_STATUS.CANCELED:
            case BET_STATUS.VOIDED:
                colorStyle = palette['badge'].neutral;
                break;
        }

        return colorStyle;
    };

    return {
        box: {
            display: 'inline-flex',
            height: spacing(25/8),
            borderRadius: spacing(4/8),
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: 'currentColor',
            padding: spacing(1/8, 8/8, 0, 8/8),
        },
        typography: {
            whiteSpace: 'nowrap',
        },
        BadgeIcon: {
            marginRight: spacing(1),
            paddingTop: 0,
            color: palette['badge'].success.color,
            alignItems: 'center',
        },
        betStatus: ({ betStatus }: { betStatus?: BET_STATUS }) => getColorStyle(betStatus),
    };
});

export default useStyles;
