import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { statusBgColors } from 'const';

interface IComponentProps {
    metricStatus?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
    iconBox: (props: IComponentProps) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: statusBgColors[props.metricStatus],
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        marginRight: '16px'
    }),
    header: { pointerEvents: 'none' },
    detailsContainer: {
        backgroundColor: '#fff',
        width: '100%'
    },
    errorMessage: {
        padding: theme.spacing(1, 1, 1, 0),
        backgroundColor: '#fad0cc',
        borderRadius: '2px',
        alignItems: 'center'
    },
    propertyName: {
        color: 'rgba(0, 0, 0, 0.6)',
        marginBottom: '8px'
    },
    propertyDesc: {
        color: '#000',
        marginBottom: '8px',
        wordWrap: 'break-word'
    },
    normalCaseButton: {
        textTransform: 'none',
        marginRight: theme.spacing(2)
    },
    graphsContainer: {
        display: 'flex',
        backgroundColor: theme.palette.grey[100],
        border: '1px solid rgba(0, 0, 0, .125)',
        borderTop: 'none',
        borderBottomLeftRadius: theme.spacing(0.5),
        borderBottomRightRadius: theme.spacing(0.5),
        overflow: 'hidden'
    },
    iFrame: {
        minHeight: '670px',
        flex: '1 1 auto'
    }
}));

export default useStyles;
