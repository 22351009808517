import React, { useContext } from 'react';

import { formatNumberToCurrencyString, numberStyles } from 'utils/formatNumber';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import clsx from 'clsx';
import useStyles from './styles';

const FinancialPerformanceReportTableCellContent = ({
    className = '',
    id,
    row,
}) => {
    const classes = useStyles();
    const { globalSettings }: GlobalContextModel = useContext(GlobalContext);

    const rawValue = row[id];
    let child;

    switch (id) {
        case 'entityName':
            child = (
                <span data-a="entity-name-label" className={clsx(className, classes.entityCell)}>{rawValue}</span>
            );
            break;
        case 'turnover':
        case 'winnings':
        case 'grossWin':
        case 'winningsPaid':
        case 'handlePerBet':
            child = (
                <span className={className}>
                    {formatNumberToCurrencyString(
                        rawValue,
                        numberStyles(globalSettings?.settings?.Currency),
                        globalSettings?.settings?.CurrencySign,
                        globalSettings?.settings?.CurrencyFormat
                    )}
                </span>
            );
            break;

        default: {
            child = <span className={className}>{rawValue}</span>;
        }
    }

    return child;
};

export default FinancialPerformanceReportTableCellContent;
