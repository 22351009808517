import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ spacing }: Theme) => ({
    formControl: {
        margin: spacing(0, 2),
    },
}));

export default useStyles;
