import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ breakpoints, palette, spacing }: Theme) => ({
    row: {
        cursor: 'pointer',

        [breakpoints.down('sm')]: {
            display: 'block',
            padding: spacing(2),
            borderBottom: '1px solid #e0e0e0',

            '&:last-child': {
                borderBottom: 'none',
            }
        }
    },
    cell: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: spacing(20),
        padding: spacing(2, 1),

        [breakpoints.down('sm')]: {
            display: 'flex',
            maxWidth: 'initial',
            border: 'none',
            marginTop: spacing(4/8),
            padding: 0,
            whiteSpace: 'initial',

            '&:last-child': {
                '&:empty': {
                    display: 'none',
                },
            }
        },
    },
    firstCell: {
        [breakpoints.down('sm')]: {
            marginTop: spacing(-3/8)
        }
    },
    lastCell: {
        [breakpoints.down('sm')]: {
            marginBottom: spacing(-4/8)
        }
    },
    dropdownCell: {
        [breakpoints.down('sm')]: {
            marginTop: spacing(6/8)
        }
    },
    defaultCell: {
        [breakpoints.down('sm')]: {
            position: 'relative',
            minWidth: '150px',
            border: 'none',
            padding: 0
        }
    },
    betStatusCell: {
        '&::before': {
            content: '""',
            float: 'right',
            minHeight: '25px',
        }
    },
    cellContent: {
        display: 'block',
        maxWidth: spacing(29),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    issuedBetShopName: {
        [breakpoints.down('sm')]: {
            color: palette.grey[700],
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    },
    creationDate: {
        [breakpoints.down('sm')]: {
            color: palette.grey[700],
        }
    },
    betStatusWithIcons: {
        [breakpoints.down('sm')]: {
            marginRight: 'auto',
            display: 'flex',
            alignItems: 'center',
        },
    },
    betStatus: {
        display: 'flex',
        alignItems: 'center',

        [breakpoints.down('sm')]: {
            marginRight: spacing(1),
        }
    },
    boxWithTitle: {
        display: 'flex',
        flexDirection: 'column',
        fontWeight: 500,
    },
    boxTitle: {
        [breakpoints.down('sm')]: {
            fontSize: `${10/16}rem`,
            textTransform: 'uppercase',
            color: '#666',
            whiteSpace: 'nowrap'
        }
    },
    boxStart: {
        marginRight: 'auto',
    },
    boxEnd: {
        marginLeft: spacing(12/8),

        '& > *': {
            textAlign: 'end',
        }
    },
    textLeft: {
        textAlign: 'left'
    },
    textRight: {
        textAlign: 'right'
    }
}));

export default useStyles;
