import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ spacing }: Theme) => ({
    accordion: {
        width: '100%',
        backgroundColor: '#F8F9FA',
    },
    accordionDetails: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingBottom: 0,
        paddingTop: 0,
    },
    dataItem: {
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: 'inherit',
        marginBottom: spacing(20/8),
        marginTop: spacing(8/8),

        '&:first-child': {
            marginTop: 0,
        }
    },
    dataLink: {
        color: 'inherit',
    },
}));

export default useStyles;
