import React, {
    useEffect,
    useRef,
    useState,
    useContext
} from 'react';
import {
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    OutlinedInput,
} from '@mui/material';
import { InputBaseComponentProps } from '@mui/material/InputBase';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import { formatNumToLocale, numberStyles } from 'utils/formatNumber';

import useStyles from './styles';

export interface IThresholdInputProps {
    className?: string;
    fullWidth?: boolean;
    name?: string;
    helperText?: string;
    helperTextNoWrap?: boolean;
    inputComparisonLabel?: string;
    inputLabel?: string;
    inputPrefix?: string;
    inputProps?: InputBaseComponentProps;
    disabled?: boolean;
    error: boolean;
    formatValue?: boolean;
    value: string | number;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
}

const ThresholdInput: React.FunctionComponent<IThresholdInputProps> = (props) => {
    const {
        className = '',
        fullWidth = false,
        name = '',
        helperText = '',
        helperTextNoWrap = false,
        inputComparisonLabel = '',
        inputLabel = '',
        inputPrefix = '',
        inputProps = {},
        disabled = false,
        error,
        formatValue = false,
        value,
        onChange: changeHandler = () => {},
    } = props;

    const styles = useStyles({ content: inputComparisonLabel });
    const { globalSettings }: GlobalContextModel = useContext(GlobalContext);

    const inputRef: React.Ref<HTMLInputElement> = useRef(null);
    const [isInputFocused, setIsInputFocused] = useState(false);

    useEffect(() => {
        const { current: input } = inputRef;
        const focusHandler = () => {
            setIsInputFocused(true);
        };
        const blurHandler = () => {
            setIsInputFocused(false);
        };

        input.addEventListener('focus', focusHandler);
        input.addEventListener('blur', blurHandler);

        return () => {
            input.removeEventListener('focus', focusHandler);
            input.removeEventListener('blur', blurHandler);
        };
    }, []);

    const hasStartAdornment = Boolean(inputPrefix.length);
    const startAdornment = hasStartAdornment
        ? (
            <InputAdornment
                disableTypography
                position="start"
            >
                {inputPrefix}
            </InputAdornment>
        ): undefined;

    const showFormattedValue = !error && !isInputFocused;

    const inputValue = formatValue && showFormattedValue
        ? formatNumToLocale(value, numberStyles(globalSettings?.settings?.Currency), false)
        : value;

    return (
        <FormControl className={className}
            variant="outlined"
            disabled={disabled}
            fullWidth={fullWidth}
        >
            {inputLabel.length
                ? (
                    <InputLabel
                        classes={{
                            root: inputComparisonLabel.length ? styles.inputLabel : undefined,
                            shrink: inputComparisonLabel.length ? styles.shrink : undefined,
                        }}
                        error={error}
                        shrink
                        htmlFor={name.length ? name : undefined}
                    >
                        {inputLabel}
                    </InputLabel>
                ): null
            }
            <OutlinedInput className={hasStartAdornment ? styles.withPrefix : ''}
                classes={{
                    root: styles.outlinedInput,
                    error: styles.outlinedInputError,
                    notchedOutline: inputComparisonLabel.length ? styles.notchedOutline : undefined,
                }}
                id={name.length ? name : undefined}
                inputRef={inputRef}
                label={inputLabel.length ? inputLabel : undefined}
                notched
                startAdornment={startAdornment}
                error={error}
                value={inputValue}
                onChange={changeHandler}
                inputProps={{
                    ...inputProps,
                    name: name.length ? name : undefined,
                    'data-a-value': value,
                }}
            />
            {helperText.length
                ? (
                    <FormHelperText className={helperTextNoWrap ? styles.noWrap : ''}
                        error={error}
                    >
                        {helperText}
                    </FormHelperText>
                ): null
            }
        </FormControl>
    );
};

export default React.memo(ThresholdInput);
