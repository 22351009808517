import axios, { Method, AxiosRequestConfig, AxiosResponse } from 'axios';
import stringify from 'qs-stringify';

import { throwNetworkError } from 'utils/requestCancelation';
import Api from 'api/Api';

import { IInitialSettingsModel } from 'api/models/general';

import { mergeDeepObjects } from 'utils/objectMethods';

import { APPLICATION_TYPE_NAMES } from 'const';

declare const initialSettings: IInitialSettingsModel;

const { EmpBffUrl: EmpBffUrl, LoginApiUrl: LoginApiUrl, SiteId: SiteId } = initialSettings;

interface IData {
    data: any; // tslint:disable-line
    status: number;
    message: string;
}

export interface CancelablePromise<T> extends Promise<T> {
    cancel: () => void;
}

export default abstract class BaseApi {
    private RootUrl = '/';

    protected QueryMethods = class {
        static readonly GET: Method = 'GET';

        static readonly DELETE: Method = 'DELETE';

        static readonly HEAD: Method = 'HEAD';

        static readonly OPTIONS: Method = 'OPTIONS';

        static readonly PUT: Method = 'PUT';

        static readonly POST: Method = 'POST';

        static readonly PATCH: Method = 'PATCH';
    };

    private DefaultConfig: AxiosRequestConfig = {
        baseURL: this.RootUrl,
        timeout: 120000,
        paramsSerializer: stringify
    };

    static Token: string = '';

    EmpBffRoute = (url: string): string => `${EmpBffUrl}${url}`;

    LoginApiRoute = (url: string): string => `${LoginApiUrl}${url}/${SiteId}/application/${APPLICATION_TYPE_NAMES.emp}`;

    private HandleResponse<T>(response: AxiosResponse, logout: boolean = false): Promise<T> {
        let loginTimer;

        if (response?.status >= 0) {
            if (logout) {
                BaseApi.Token = '';
                Api.General.GetLoginUri(true).then(res => {
                    document.location.href = res.loginUri;
                }).catch(throwNetworkError);
            }

            if (response?.data?.data?.accessToken && response?.data?.data?.expirationSeconds) {
                BaseApi.Token = response.data.data.accessToken;

                const { expirationSeconds } = response.data.data;

                loginTimer && clearTimeout(loginTimer);
                loginTimer = setTimeout(() => {
                    Api.General.GetToken('PUT');
                }, expirationSeconds * 1000);
            }

            return response.data.data;
        }

        return Promise.reject(response);
    }

    private HandleError<T>(e): Promise<T> {
        if (axios.isCancel(e)) {
            e?.data?.message && console.warn(e.data.message);
        }

        return Promise.reject(e);
    }

    private handleRequest<T>(
        params = {},
        url: string,
        method: Method = this.QueryMethods.POST,
        options: AxiosRequestConfig = this.DefaultConfig,
        additionalOptions: AxiosRequestConfig = {}
    ): CancelablePromise<T> {
        let { token: cancelToken, cancel } = axios.CancelToken.source();
        let config: AxiosRequestConfig = { ...options, method, url, cancelToken };

        cancel = cancel.bind(this, `${method} "${url}" request is canceled.`);

        if (
            method === this.QueryMethods.POST ||
            method === this.QueryMethods.PUT ||
            method === this.QueryMethods.PATCH
        ) {
            config.data = params;
        } else {
            config.params = params;
        }

        const query = axios(mergeDeepObjects(config, additionalOptions))
            .then((response: AxiosResponse<IData>) => this.HandleResponse<T>(response, method === this.QueryMethods.DELETE))
            .catch((e) => this.HandleError<T>(e));

        return Object.assign(query, { cancel });
    }

    protected QueryEmpBff<T>(
        params = {},
        url: string,
        method: Method = this.QueryMethods.POST,
        options: AxiosRequestConfig = this.DefaultConfig
    ): CancelablePromise<T> {
        if (BaseApi.Token) {
            const additionalOptions = {
                headers: {
                    Authorization: `Bearer ${BaseApi.Token}`
                }
            };

            return this.handleRequest(params, this.EmpBffRoute(url), method, options, additionalOptions);
        }
    }

    protected QueryLogin<T>(
        params = {},
        url: string,
        method: Method = this.QueryMethods.POST,
        options: AxiosRequestConfig = this.DefaultConfig
    ): CancelablePromise<T> {
        const additionalOptions = {
            withCredentials: true
        };

        return this.handleRequest(params, this.LoginApiRoute(url), method, options, additionalOptions);
    }

    protected Submit<T>(
        formData: FormData,
        url: string
    ): CancelablePromise<T> {

        let { token: cancelToken, cancel } = axios.CancelToken.source();
        const options = {
            headers: {
                'content-type': 'multipart/form-data',
            },
            cancelToken
        };

        if (BaseApi.Token) {
            options.headers['Authorization'] = `Bearer ${BaseApi.Token}`;
        }

        cancel = cancel.bind(this, `POST "${this.EmpBffRoute(url)}" request is canceled.`);

        const query = axios.post(this.EmpBffRoute(url), formData, options)
            .then((response: AxiosResponse<IData>) => this.HandleResponse<T>(response))
            .catch((e) => this.HandleError<T>(e));

        return Object.assign(query, { cancel });
    }
}
