import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ breakpoints, palette, spacing, typography }: Theme) => ({
    inlineTitle: {
        [breakpoints.down('sm')]: {
            marginRight: spacing(4/8),
            color: palette.grey[700],
        }
    },
    receiptId: {
        [breakpoints.down('sm')]: {
            fontWeight: typography.fontWeightMedium,
        }
    },
    transactionIdCell: {
        maxWidth: 'initial'
    },
}));

export default useStyles;
