import { Theme } from '@mui/material/styles';

import withStyles from '@mui/styles/withStyles';

import { AccordionSummary } from '@mui/material';

const summaryStyles = ((theme: Theme) => ({
    root: {
        minHeight: theme.spacing(8),
        padding: `0 ${theme.spacing(2)}`,
        '&$expanded': {
            minHeight: theme.spacing(8)
        },
        '&$focused': {
            backgroundColor: theme.palette.common.white
        }
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        minHeight: theme.spacing(4),
        margin: '4px 0',
        '&$expanded': {
            minHeight: theme.spacing(5),
            margin: '4px 0'
        },
        '&$focused': {
            backgroundColor: theme.palette.common.white
        }
    },
    expandIcon: {
        padding: 3
    },
    expanded: {}
}));

const StyledAccordionSummary = withStyles(summaryStyles)(
    AccordionSummary
);

export default StyledAccordionSummary;
