import * as React from 'react';
import { Paper, Typography, Grid } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { IProps, ITileData } from './types';
import { GRID_COLUMNS as columns } from 'const';
import useStyles from './styles';
import clsx from 'clsx';

import { dashboardValue } from '../../utils/dashboardValue';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import { numberStyles } from 'utils/formatNumber';

const Widget = (props: IProps) => {
    const data: ITileData = props.data;
    const classes = useStyles({ parentSelector: props.parentSelector });
    const { globalSettings, translations }: GlobalContextModel = React.useContext(GlobalContext);
    const value = dashboardValue(
        data.kpiType,
        data.value,
        numberStyles(globalSettings?.settings?.Currency),
        globalSettings?.settings?.CurrencySign,
        globalSettings?.settings?.CurrencyFormat
    );

    return data && (
        <Paper className={classes.widget} >
            <Typography className={classes.heading} align="center" variant="h6" component="h6" gutterBottom>
                {data.title && translations[data.title]}
            </Typography>
            <Typography className={classes.title} align="center" variant="h5" component="h5">
                {value}
            </Typography>
            <Grid container >
                <Grid
                    className={classes.relativeValWrap}
                    item
                    lg={columns.col6}
                    xs={columns.col6}
                    sm={columns.col6}
                >
                    <Typography
                        className={
                            clsx(
                                classes.relativeValue,
                                classes[`relativeValue_${data.leftDynamics}`])
                        }
                        variant="caption"
                    >
                        {data.leftValue + data.leftUnits}
                        <ArrowDownwardIcon className={classes.icon} />
                    </Typography>
                    <Typography className={classes.relativeTitle} component="div" variant="caption">
                        {data.leftTitle && translations[data.leftTitle]}
                    </Typography>
                </Grid>
                <Grid
                    className={clsx(classes.dayValueItem, classes.relativeValWrap)}
                    item
                    lg={columns.col6}
                    xs={columns.col6}
                    sm={columns.col6}
                >
                    <Typography
                        className={
                            clsx(
                                classes.relativeValue,
                                classes[`relativeValue_${data.rightDynamics}`])
                        }
                        variant="caption"
                    >
                        <ArrowDownwardIcon className={classes.icon} />
                        {data.rightValue + data.rightUnits}
                    </Typography>
                    <Typography className={classes.relativeTitle} component="div" variant="caption">
                        {data.rightTitle && translations[data.rightTitle]}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default Widget;
