import * as React from 'react';

import { Button } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { ArrowDropDown, ViewColumnOutlined } from '@mui/icons-material';

const useStyles = makeStyles(({ palette, spacing }: Theme) => ({
    button: {
        padding: spacing(5/8),
        borderRadius: spacing(0.5),
        textTransform: 'none',
        backgroundColor: `${palette.grey[100]}`,
    }
}));

export default function ColumnChooserButton (props: { disabled: boolean; handleClick: (event: React.MouseEvent<unknown>) => void}) {
    const { disabled, handleClick } = props;
    const classes = useStyles({});

    return (
        <Button className={classes.button}
            disabled={disabled}
            variant="outlined"
            onClick={handleClick}
            data-a="column-chooser-button"
        >
            <ViewColumnOutlined />
            <ArrowDropDown />
        </Button>
    );
}
