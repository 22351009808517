import React, { useState, useEffect } from 'react';
import { Grid, ThemeProvider, Theme, StyledEngineProvider, Box } from '@mui/material';

import {
    LocalizationProvider,
    PickersLocaleText,
    DateTimePicker
} from '@mui/x-date-pickers';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { addMinutes, startOfDay, endOfDay, isValid } from 'utils/formatDate';

import makeStyles from '@mui/styles/makeStyles';
import defaultTheme from 'theme';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext, globalSettings } from 'context/globalContext';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const useStyles = makeStyles((theme) => ({
    menuItem: {
        padding: theme.spacing(1, 2)
    }
}));

const TimePicker = ({ label, maxDate, minDate, value, onChange, onAccept }) => {
    const { dateTimeFormat }: GlobalContextModel = React.useContext(GlobalContext);

    const ampm = dateTimeFormat.includes('a');

    return (
        <DateTimePicker
            ampm={ampm}
            format={dateTimeFormat}
            maxDate = {maxDate}
            minDate = {minDate}
            label={label}
            value={value}
            onChange={onChange}
            onAccept={onAccept}
            sx={{ width: '100%' }}
            slotProps={{
                actionBar: {
                    actions: ['cancel', 'accept']
                }
            }}
        />
    );
};

const DateTimePickers = ({
    setApplyDisabled,
    fromDate,
    toDate,
    minDate: minAvailableDateInCalendar,
    changeDates,
}: {
    setApplyDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    fromDate?: Date;
    toDate?: Date;
    minDate?: Date;
    changeDates: React.Dispatch<React.SetStateAction<{ fromDate: Date; toDate: Date }>>;
}) => {
    const classes = useStyles({});
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    const customLocaleText: Partial<PickersLocaleText<any>> = {
        okButtonLabel: translations['gen-apply'],
        cancelButtonLabel: translations['gen-cancel'],
    };

    const [isFromDateChangedByUser, setFromDateChangedByUser] = useState<boolean>(false);

    useEffect(() => {
        setApplyDisabled(!validate());
    }, [fromDate, toDate]);

    const checkMinDateValidity = (date: Date) => minAvailableDateInCalendar ? date >= minAvailableDateInCalendar : true;

    const isFromDateValid = (date: Date) => {
        return isValid(date) && checkMinDateValidity(date) && date < toDate;
    };

    const isToDateValid = (date: Date) => {
        return isValid(date) && checkMinDateValidity(date) && date <= endOfDay(
            new Date(),
            {
                gameDayEnable: globalSettings?.settings?.GamingDaySettings?.Enabled,
                startOfGamingDay: globalSettings?.settings?.GamingDaySettings?.Start,
                endOfGamingDay: globalSettings?.settings?.GamingDaySettings?.End
            }
        );
    };

    const validate = () => (
        isFromDateValid(fromDate) &&
        isToDateValid(toDate) &&
        fromDate < toDate
    );

    const handleFromDateChange = (date: Date) => {
        changeDates({ fromDate: isValid(date) ? date : new Date('Invalid Date object'), toDate });
        setFromDateChangedByUser(true);
    };

    const handleToDateChange = (date: Date) => {
        const newFromDate = !isFromDateChangedByUser && isToDateValid(date)
            ? toDate < fromDate
                ? startOfDay(
                    date,
                    {
                        gameDayEnable: globalSettings?.settings?.GamingDaySettings?.Enabled,
                        startOfGamingDay: globalSettings?.settings?.GamingDaySettings?.Start,
                        endOfGamingDay: globalSettings?.settings?.GamingDaySettings?.End,
                        days: 1
                    }
                ) : fromDate
            : fromDate;

        changeDates({ fromDate: newFromDate, toDate: isValid(date) ? date : new Date('Invalid Date object') });
    };

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={defaultTheme}>
                <LocalizationProvider dateAdapter={AdapterDateFns} localeText={customLocaleText}>
                    <Box sx={{ margin: '12px 0' }}>
                        <Grid container direction="column" alignItems="center" className={classes.menuItem} data-a="date-picker-from">
                            <TimePicker
                                label="From"
                                value={fromDate}
                                onChange={handleFromDateChange}
                                onAccept={handleFromDateChange}
                                minDate={minAvailableDateInCalendar}
                                maxDate={addMinutes(toDate, -1)}
                            />
                        </Grid>
                        <Grid container direction="column" alignItems="center" className={classes.menuItem} data-a="date-picker-to">
                            <TimePicker
                                label="To"
                                value={toDate}
                                onChange={handleToDateChange}
                                onAccept={handleToDateChange}
                                minDate={isFromDateChangedByUser ? addMinutes(fromDate, 1) : minAvailableDateInCalendar}
                                maxDate={endOfDay(
                                    new Date(),
                                    {
                                        gameDayEnable: globalSettings?.settings?.GamingDaySettings?.Enabled,
                                        startOfGamingDay: globalSettings?.settings?.GamingDaySettings?.Start,
                                        endOfGamingDay: globalSettings?.settings?.GamingDaySettings?.End
                                    }
                                )}
                            />
                        </Grid>
                    </Box>
                </LocalizationProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default React.memo(DateTimePickers);
