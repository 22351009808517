import * as React from 'react';

import makeStyles from '@mui/styles/makeStyles';

import { IEposListItemModel } from 'api/models/epos';
import TerminalsTilesItem from '../TerminalsTilesItem/TerminalsTilesItem';
import { EPOS_STATUS, EPOS_STATUS_NAMES } from 'const';
import { observer } from '../TerminalsTiles';

interface IComponentProps {
    numColumns: number;
}

const gapWidth = 16; // px

const useStyles = makeStyles(() => ({
    row: {
        display: 'grid',
        marginBottom: gapWidth,
        gridColumnGap: gapWidth,
        gridRowGap: gapWidth,
        gridTemplateColumns: (props: IComponentProps) =>
            `repeat(${props.numColumns}, calc(${100 / props.numColumns}% - ${gapWidth - gapWidth / props.numColumns}px))`
    }
}));

const TerminalsTilesRow = (props: {
    rowIndex: number;
    numColumns: number;
    rows: Array<IEposListItemModel>;
    selectedIds: Array<number>;
    handleRedirect: (id: number) => void;
    handleTirminalSelect: (event: React.MouseEvent<unknown>, id: number) => void;
    images: any;
    style?: any;
    useStyles?: any;
    classes?: any;
    isMobileView: boolean;
}) => {
    const {
        rowIndex,
        numColumns,
        rows,
        selectedIds,
        handleRedirect,
        handleTirminalSelect,
        images,
        style,
        isMobileView
    } = props;
    const classes = useStyles({ numColumns });

    const rowRef = React.useRef(null);
    const startIndex = rowIndex * numColumns;
    const res = [];

    React.useEffect(() => {
        rowRef && observer?.observe(rowRef.current);

        return () => {
            rowRef.current && observer?.unobserve(rowRef.current);
        };
    }, [rowRef]);

    const getImg = (row) => {
        const { machineId } = row;

        if (row.status === EPOS_STATUS.OFFLINE) {
            return EPOS_STATUS_NAMES.OFFLINE;
        }

        return images?.[machineId] ? images[machineId] : '';
    };

    for (let i = 0; i < numColumns; i++) {
        const key = startIndex + i;

        rows.length > key && res.push(
            <TerminalsTilesItem
                key={rows[key].id}
                row={rows[key]}
                selectedIds={selectedIds}
                handleRedirect={handleRedirect}
                handleTirminalSelect={handleTirminalSelect}
                img={getImg(rows[key])}
                isMobileView={isMobileView}
            />
        );
    }

    return (
        <div ref={rowRef} style={style} data-rowindex={rowIndex} >
            <div className={classes.row}>
                {res}
            </div>
        </div>
    );
};

export default TerminalsTilesRow;
