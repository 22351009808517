import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) => ({
    cell: {
        padding: spacing(2, 1),
    },
    hideCellMobile: {
        [breakpoints.down('md')]: {
            display: 'none'
        }
    }
}));

export default useStyles;
