import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: spacing(2.5),
        [breakpoints.down('sm')]: {
            marginLeft: spacing(1),
        },
        '& .MuiButtonBase-root': {
            [breakpoints.down('sm')]: {
                padding: spacing(1),
                margin: spacing(1, 0),
            }
        }
    },
    iconButton: {

    }
}));

export default useStyles;
