import React, { useContext } from 'react';

import { Tooltip, Zoom } from '@mui/material';
import PrintDisabledIcon from '@mui/icons-material/PrintDisabled';
import { BetStatusBadge } from 'components/Badges/Badges';
import { hideTicketIdFirstChars, getBetTypesString } from 'pages/Reports/business';
import { formatNumberToCurrencyString, numberStyles } from 'utils/formatNumber';
import { formatDateByPattern, formatToIsoDateString } from 'utils/formatDate';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import clsx from 'clsx';
import useStyles from './styles';

const TicketsReportTableCellContent = ({
    className = '',
    id,
    row,
    isMobileView,
}) => {
    const classes = useStyles();
    const { globalSettings, translations, dateTimeFormat, timeZone }: GlobalContextModel = useContext(GlobalContext);

    const rawValue = row[id];
    let child;

    const Icons = ({ iconClassName = '' }) => (
        !row.isPrinted &&
            <Tooltip title={translations['emp-reports-ticket-is-not-printed']}>
                <PrintDisabledIcon className={clsx(classes.icon, iconClassName)} />
            </Tooltip>
    );

    switch (id) {
        case 'ticketId':
            child = (
                <span className={clsx(classes.ticketIdWrap, className)}>
                    <span className={classes.ticketId}>
                        {hideTicketIdFirstChars(rawValue)}
                    </span>
                    {!isMobileView && <Icons />}
                </span>);
            break;
        case 'loyaltyUserKey':
            child = rawValue
                ? (
                    <span className={clsx(classes.ticketIdWrap, className)}>
                        <Tooltip TransitionComponent={Zoom} title={rawValue} placement="top" arrow>
                            <span className={classes.ticketId}>
                                {hideTicketIdFirstChars(rawValue)}
                            </span>
                        </Tooltip>
                    </span>)
                : null;
            break;
        case 'betStatus':
            child = <BetStatusBadge className={className} betStatus={rawValue} />;
            break;
        case 'betType':
            const betTypesTooltipTitle = row.betTypeMultiples?.length
                ? getBetTypesString(row.betTypeMultiples, translations)
                : '';

            const hasDescription = row.betTypeDescription?.length > 0;
            const betTypePrefix = row.hasSgpxSelections
                ? `${translations['gen-sgpx']} `
                : '';

            const betType =
                hasDescription
                    ? row.betTypeDescription
                        .map(key => translations[key])
                        .join(' ')
                    : getBetTypesString(rawValue, translations);
            const betTypeDescription = betTypePrefix + betType;

            child = (
                <Tooltip title={betTypesTooltipTitle}>
                    <span className={className}>{betTypeDescription}</span>
                </Tooltip>);
            break;
        case 'creationDate':
        case 'settledDate':
        case 'expirationDate':
        case 'paidDate':
            const formattedDate = rawValue?.length
                ? formatDateByPattern(new Date(formatToIsoDateString(rawValue)), dateTimeFormat, timeZone)
                : '';

            child = <span className={className}>{formattedDate}</span>;
            break;
        case 'costAmount':
        case 'stakeAmount':
        case 'stakeTaxAmount':
        case 'toWinAmount':
        case 'winningsAmount':
        case 'actualWinAmount':
        case 'winTaxAmount':
        case 'comboBonusAmount':
        case 'toPayAmount':
        case 'totalPaidAmount':
            child = (
                <span className={className}>
                    {formatNumberToCurrencyString(
                        rawValue,
                        numberStyles(globalSettings?.settings?.Currency),
                        globalSettings?.settings?.CurrencySign,
                        globalSettings?.settings?.CurrencyFormat
                    )}
                </span>
            );
            break;
        case 'stakeTaxPercent':
        case 'winTaxPercent':
            child = <span className={className}>{`${rawValue}%`}</span>;
            break;
        case 'icons':
            child = <Icons iconClassName={className} />;
            break;
        default:
            child = <span className={className}>{rawValue}</span>;
    }

    return child;
};

export default TicketsReportTableCellContent;
