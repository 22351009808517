import { toggleItemInArray, sortBy } from 'utils/arrayMethods';
import {
    addMonths,
} from 'utils/formatDate';
import { headTicketsReportSelectableColumns } from 'data';
import {
    IGenericDateRange,
    IReportTicketModel,
    IReportCardTransactionModel,
    IReportVouchersModel,
    IReportSupervisorModel,
    IFinancialPerformanceSearchResponse,
} from 'api/models/reports';
import {
    APPROVAL_TYPE,
    APPROVAL_TYPE_NAMES,
    approvalTypesSeparator,
    BET_TYPE,
    BET_TYPE_NAMES,
    betTypesSeparator,
    IFilterListItem,
    IPointsOfSaleFilterListItem,
    TICKETS_SEARCH_BY_DATES,
    ORDER_BY_VOUCHERS_COLUMN,
    ORDER_BY_FINANCIAL_PERFORMANCE_COLUMN,
} from 'const';

type IDateRange = IGenericDateRange<Date>

type TicketsReportBaseFilterListKeys =
    'betShops' |
    'pointsOfSale' |
    'betStatuses' |
    'betTypes' |
    'payoutStatuses';

type VouchersReportBaseFilterListKeys =
    'issuedByEntityIds' |
    'issuedBetshopIds' |
    'cashedOutByEntityIds' |
    'cashedOutBetshopIds';

export interface ITicketsReportBaseFilterList extends Record<TicketsReportBaseFilterListKeys, Array<IFilterListItem>> {
    pointsOfSale: Array<IPointsOfSaleFilterListItem>;
}

export interface IVouchersReportBaseFilterList extends Record<VouchersReportBaseFilterListKeys, Array<IFilterListItem>> {
}

export const getPointsOfSaleFiltersBySelectedBetshops = (
    pointsOfSale: Array<IPointsOfSaleFilterListItem>,
    betShops: Array<IFilterListItem>
) => {
    const betShopsIds = betShops.map(({ id }) => id);

    return betShops.length
        ? pointsOfSale.filter(({ betShopId }) => betShopsIds.includes(betShopId))
        : pointsOfSale;
};

export const getCashiersFiltersBySelectedBetshops = (
    cashiers: Array<IPointsOfSaleFilterListItem>,
    betShops: Array<IFilterListItem>
) => {
    const betShopsIds = betShops.map(({ id }) => id);

    return betShops.length
        ? cashiers.filter(({ betShopId }) => betShopsIds.includes(betShopId))
        : cashiers;
};

export const getTerminalsFiltersBySelectedBetshops = (
    terminals: Array<IPointsOfSaleFilterListItem>,
    betShops: Array<IFilterListItem>
) => {
    const betShopsIds = betShops.map(({ id }) => id);

    return betShops.length
        ? terminals.filter(({ betShopId }) => betShopsIds.includes(betShopId))
        : terminals;
};

export interface ITicketsReportFilterList extends IDateRange, ITicketsReportBaseFilterList {
    searchByDate: TICKETS_SEARCH_BY_DATES;
    overTicketCost: Array<number>;
    overWinningsAmount: Array<number>;
    overToPayAmount: Array<number>;
    overTotalPaidAmount: Array<number>;
}

interface IVoucherReportFilterItem {
    id: string;
    name: string;
}

export interface IVouchersReportFilterList extends IDateRange, IVouchersReportBaseFilterList {
    voucherStatusIds: Array<IVoucherReportFilterItem>;
    voucherTypeIds: Array<IVoucherReportFilterItem>;
    issuedByEntityTypeIds: Array<IVoucherReportFilterItem>;
    minVoucherCost: Array<number>;
}

export interface IVouchersReportInitState {
    filtersLists: IVouchersReportBaseFilterList;
    isSearchedByNumber: boolean;
    appliedFilters: IVouchersReportFilterList;
    isAscendingOrder: boolean;
    rowsPerPage: number;
    rows: Array<IReportVouchersModel>;
    rowsCount: number;
    voucherId: number;
    page: number;
    searchText: string;
    orderByColumn: ORDER_BY_VOUCHERS_COLUMN;
}

export interface IVouchersReportInitState {
    filtersLists: IVouchersReportBaseFilterList;
    isSearchedByNumber: boolean;
    appliedFilters: IVouchersReportFilterList;
    isAscendingOrder: boolean;
    rowsPerPage: number;
    rows: Array<IReportVouchersModel>;
    rowsCount: number;
    voucherId: number;
    page: number;
    searchText: string;
    orderByColumn: ORDER_BY_VOUCHERS_COLUMN;
}

// initial filters values and reference for clearing filters
export const ticketsReportFilterListInitial: ITicketsReportFilterList = {
    searchByDate: TICKETS_SEARCH_BY_DATES.creationDate,
    betShops: [],
    pointsOfSale: [],
    betStatuses: [],
    betTypes: [],
    payoutStatuses: [],
    overTicketCost: [],
    overWinningsAmount: [],
    overToPayAmount: [],
    overTotalPaidAmount: [],
};

export const vouchersReportFilterListInitial: IVouchersReportFilterList = {
    voucherStatusIds: [],
    voucherTypeIds: [],
    issuedByEntityTypeIds: [],
    issuedByEntityIds: [],
    issuedBetshopIds: [],
    cashedOutByEntityIds: [],
    cashedOutBetshopIds: [],
    minVoucherCost: []
};

type ICardTransactionsReportBaseFilterListKeys =
    'betShops' |
    'cashiers';

export type ICardTransactionsReportBaseFilterList = Record<ICardTransactionsReportBaseFilterListKeys, Array<IFilterListItem>>

export interface ICardTransactionsReportFilterList extends IDateRange, ICardTransactionsReportBaseFilterList {}

// initial filters values and reference for clearing filters
export const cardTransactionsReportFilterListInitial: ICardTransactionsReportFilterList = {
    betShops: [],
    cashiers: [],
};

export interface ICardTransactionsReportInitState {
    filtersLists: ICardTransactionsReportBaseFilterList;
    appliedFilters: ICardTransactionsReportFilterList;
    isAscendingOrder: boolean;
    rows: Array<IReportCardTransactionModel>;
    page: number;
    rowsPerPage: number;
    rowsCount: number;
    isSearchedByTransactionID: boolean;
    searchText: string;
}

export interface ISupervisorReportInitState {
    filtersLists: ISupervisorReportBaseFilterList;
    appliedFilters: ISupervisorReportFilterList;
    isAscendingOrder: boolean;
    rows: Array<IReportSupervisorModel>;
    page: number;
    rowsPerPage: number;
    rowsCount: number;
    isSearchedByDocumentID: boolean;
    searchText: string;
}

type IFinancialPerformanceReportBaseFilterListKeys =
    'betShops' |
    'terminals' |
    'cashiers';

export interface IFinancialPerformanceReportBaseFilterList extends Record<IFinancialPerformanceReportBaseFilterListKeys, Array<IFilterListItem>> {
    cashiers: Array<IPointsOfSaleFilterListItem>;
    terminals: Array<IPointsOfSaleFilterListItem>;
}

export interface IFinancialPerformanceReportFilterList extends IDateRange, IFinancialPerformanceReportBaseFilterList {}

// initial filters values and reference for clearing filters
export const financialPerformanceReportFilterListInitial: IFinancialPerformanceReportFilterList = {
    betShops: [],
    terminals: [],
    cashiers: [],
};

export interface IFinancialPerformanceReportInitState {
    filtersLists: IFinancialPerformanceReportBaseFilterList;
    appliedFilters: IFinancialPerformanceReportFilterList;
    result: IFinancialPerformanceSearchResponse;
    rowsPerPage: number;
    page: number;
    order: boolean;
    orderBy: ORDER_BY_FINANCIAL_PERFORMANCE_COLUMN;
}

type SupervisorReportBaseFilterListKeys =
    'betShops' |
    'supervisorApprovals' |
    'supervisors';

export type ISupervisorReportBaseFilterList = Record<SupervisorReportBaseFilterListKeys, Array<IFilterListItem>>

export interface ISupervisorReportFilterList extends IDateRange, ISupervisorReportBaseFilterList {}

export interface ISupervisorReportSearchItem {
    text: string;
    isFieldOpen: boolean;
}
export interface ISupervisorReportSearchList {
    betShops: ISupervisorReportSearchItem;
    supervisors: ISupervisorReportSearchItem;
}

// initial filters values and reference for clearing filters
export const supervisorReportFilterListInitial: ISupervisorReportFilterList = {
    betShops: [],
    supervisorApprovals: [],
    supervisors: [],
};

export const supervisorReportSearchListInitial: ISupervisorReportSearchList = {
    betShops: { text: '', isFieldOpen: false },
    supervisors: { text: '', isFieldOpen: false },
};

export const getDuplicatedTicketIds = (
    rows: Array<IReportTicketModel>,
    lastDateOnPage: string,
    orderBy: keyof typeof TICKETS_SEARCH_BY_DATES
) => rows
    .filter((row) => row[orderBy] === lastDateOnPage)
    .map((row) => row.ticketId);

export const getSameDateVoucherIds = (
    rows: Array<IReportVouchersModel>,
    lastDateOnPage: string,
    orderByColumn: string
) => rows
    .filter((row) => row[orderByColumn] === lastDateOnPage)
    .map((row) => row.voucherId);

export function toggleColumns(arr: Array<any>, id: string) {
    const item = headTicketsReportSelectableColumns.find(item => item.id === id);
    const selectedIndex = arr.findIndex(item => item.id === id);

    return sortBy('position', toggleItemInArray(item, selectedIndex, arr));
}

export function toggleFilters(item: IFilterListItem, arr: Array<IFilterListItem>) {
    const selectedIndex = arr.findIndex(arrItem => arrItem.id === item.id);

    return toggleItemInArray(item, selectedIndex, arr);
}

// it means get active filters with possible multiple values
function getFilterEntriesWithSelection(appliedFilters) {
    const filterEntriesWithSelection = Object.entries(appliedFilters)
        .filter(([, filters]) =>
            Array.isArray(filters) && filters?.length
        );

    return filterEntriesWithSelection;
}

export function countFilterQty(appliedFilters) {
    const filterEntriesWithSelection = getFilterEntriesWithSelection(appliedFilters);

    return filterEntriesWithSelection.length;
}

export function checkOpenExtension(appliedFilters) {
    const filterEntriesWithSelection = getFilterEntriesWithSelection(appliedFilters);
    const openExtensionsEntries = filterEntriesWithSelection.map(([key]) => [key, true]);

    const reducer = (accumulator, [key]) => ({ ...accumulator, [key]: true });
    const openExtensionsObj = openExtensionsEntries.reduce(reducer, {});

    return openExtensionsObj;
}

export function hideTicketIdFirstChars(ticketNumber: string | number, lastVisibleCharsNum = 6, screenChar = '*', screenLength = 3) {
    const ticketNumberString = `${ticketNumber}`;
    const truncatedTicketNumber = `${ticketNumberString}`.slice(ticketNumberString.length - lastVisibleCharsNum);
    const prefix = screenChar.repeat(screenLength);

    return `${prefix}${truncatedTicketNumber}`;
}

export const ticketIdSearchValidator: (value: string) => boolean = (value) => value && /^63\d{16,16}$/.test(value) && !value.split('').every(symbol => symbol === '0');
export const cardTransactionIdSearchValidator: (value: string) => boolean = (value) => value.length && /^\d{2,15}$/.test(value);
export const documentIdSearchValidator: (value: string) => boolean = (value) => value.length && /^\d{2,18}$/.test(value);
export const voucherIdSearchValidator: (value: string) => boolean = (value) => value.length && /^\d{7,7}$/.test(value);

export const getShowPaginationRangeMs = (months: number) => {
    const nowMs = Date.now();
    const dateBefore = addMonths(new Date(nowMs), -months);

    return nowMs - dateBefore.getTime();
};

export const betTypesNameMapper = (betType: BET_TYPE) => BET_TYPE_NAMES[BET_TYPE[betType]];

export const getBetTypesString = (betTypes: Array<BET_TYPE> | BET_TYPE, translations: any, separator = betTypesSeparator) => {
    const betTypesArray = Array.isArray(betTypes) ? betTypes : [betTypes];

    return betTypesArray
        .map(betTypesNameMapper)
        .map((betTypeName) => translations[betTypeName] || '')
        .join(separator);
};

const approvalTypesNameMapper = (approvalType: APPROVAL_TYPE) => APPROVAL_TYPE_NAMES[APPROVAL_TYPE[approvalType]];

export const getApprovalTypesString = (approvalTypes: Array<APPROVAL_TYPE>, translations: any, separator = approvalTypesSeparator) => approvalTypes
    .sort()
    .map(approvalTypesNameMapper)
    .map((approvalTypeName) => translations[approvalTypeName] || '')
    .join(separator);
