import BaseApi from 'api/services/_BaseApi';
import { EmpEndpoint } from 'api/Endpoint';
import {
    IEntitiesFilterRequest,
    IPointsOfSaleModel,
} from 'api/models/reports';

import { handleCookieToPayload } from 'utils/cookieMethods';

export default class ReportingFiltersApi extends BaseApi {
    GetEntities(payload: IEntitiesFilterRequest) {
        return this.QueryEmpBff<IPointsOfSaleModel>(handleCookieToPayload(payload, 'showTestAccounts', 'IncludeTest'), EmpEndpoint.ReportFilters.GetEntities, this.QueryMethods.POST);
    }
}
