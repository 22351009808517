import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Radio from '@mui/material/Radio';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import ButtonPrimaryText from 'components/Buttons/ButtonPrimaryText';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import Api from 'api/Api';
import { cancelRequests } from 'utils/requestCancelation';

import { IShellVersionItem } from 'api/models/release-server';

import { ShellApplicationType } from 'const';

const useStyles = makeStyles((theme: Theme) => ({
    wrap: {
        minWidth: '420px',

        [theme.breakpoints.down('md')]: {
            minWidth: '320px',
        }
    },
    listItem: {
        padding: theme.spacing(0.5, 0),
        cursor: 'pointer'
    }
}));

const apiRequests = {
    shellVersionList: null
};

const UpdateShellModal = (
    props: {
        isOpen: boolean;
        title: string;
        onClose: () => void;
        onConfirm: (id: string) => void;
    }) => {
    const { isOpen, title, onClose, onConfirm } = props;
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);
    const classes = useStyles();

    const [shellVersions, setShellVersion] = React.useState([]);
    const [selected, setSelected] = React.useState<string>('');

    React.useEffect(() => {
        loadData();

        return () => cancelRequests(apiRequests);
    }, []);

    const loadData = async () => {
        const payload = {
            applicationType: `${ShellApplicationType.Terminal}`
        };

        try {
            apiRequests.shellVersionList = Api.ReleaseServer.GetShellVersions(payload);

            const result: Array<IShellVersionItem> = await apiRequests.shellVersionList;

            if (result) {
                setShellVersion([...new Set(result.map(shell => shell.version))]);
            }
        } catch (error) {
            // handle fetch errors here
        }
    };

    const handleToggle = (shell: string) => () => {
        setSelected(shell);
    };

    function handleClick() {
        setSelected('');
        onConfirm(selected);
    }

    const handleClose = () => {
        setSelected('');
        onClose();
    };

    return ReactDOM.createPortal(
        <div className={classes.wrap}>
            <Dialog
                classes={{ paper: classes.wrap }}
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title && translations[title]}</DialogTitle>
                <DialogContent>
                    <List disablePadding>
                        {
                            shellVersions.map((shell, i) =>
                                (
                                    <ListItem className={classes.listItem} key={i} dense onClick={handleToggle(shell)}>
                                        <Radio
                                            color={'primary'}
                                            checked={selected === shell}
                                            name="item.id"
                                        />
                                        {`Shell ${shell}`}
                                    </ListItem>)
                            )
                        }
                    </List>
                </DialogContent>
                <DialogActions>
                    <ButtonPrimaryText onClick={handleClose}>
                        {translations['gen-cancel']}
                    </ButtonPrimaryText>
                    <ButtonPrimaryText onClick={handleClick}>
                        {translations['gen-update']}
                    </ButtonPrimaryText>
                </DialogActions>
            </Dialog>
        </div>,
        document.getElementById('modal')
    );
};

export default UpdateShellModal;
