import * as React from 'react';
import Icon from '@mui/material/Icon';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
    errorEposIcon: {
        marginTop: '-7px',
        marginBottom: '-7px',
        marginRight: '5px'
    }
}));

const defaultIcon = 'monetization_on';

const ErrorIcon = (props: {id: number; color: string; fontSize: 'inherit' | 'medium' | 'small' | 'large'}) => {
    let icon = '';
    const fontSize = props.fontSize || 'medium';

    switch (props.id) {
        case 1:
            icon = 'web';
            break;
        case 2:
            icon = 'monetization_on';
            break;
        case 3:
            icon = 'monetization_on';
            break;
        case 4:
            icon = 'print';
            break;
        case 8:
            icon = 'network_check';
            break;
        case 15:
            icon = 'get_app';
            break;
        default:
            icon = defaultIcon;
    }

    const classes = useStyles({});

    return (
        <Icon color="error" className={classes.errorEposIcon} fontSize={fontSize}>{icon}</Icon >
    );
};

export default ErrorIcon;
