/* global JSON */
import React from 'react';

import { ThemeProvider, Theme, StyledEngineProvider, CssBaseline } from '@mui/material';
import defaultTheme from 'theme';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import ButtonPrimaryText from 'components/Buttons/ButtonPrimaryText';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const CustomPrompt = ({ message, cleanUp }) => {
    const parsedMessage = JSON.parse(message);
    const cancel = () => cleanUp(false);
    const ok = () => cleanUp(true);

    return (
        <>
            <CssBaseline />
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={defaultTheme}>
                    <Dialog open onClose={cancel}>
                        <DialogTitle id="alert-dialog-title">{parsedMessage.title}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {parsedMessage.msg}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <ButtonPrimaryText dataA="custom-prompt-button-yes" onClick={ok} >
                                {parsedMessage.yes}
                            </ButtonPrimaryText>
                            <ButtonPrimaryText dataA="custom-prompt-button-no" onClick={cancel} >
                                {parsedMessage.no}
                            </ButtonPrimaryText>
                        </DialogActions>
                    </Dialog>
                </ThemeProvider>
            </StyledEngineProvider>
        </>);
};

export default CustomPrompt;
