import makeStyles from '@mui/styles/makeStyles';
import { palette } from 'theme';
import { statusColors, pageWrapContainer } from 'const';

interface IComponentProps {
    status: number;
}

const useStyles = makeStyles(theme => ({
    bodyWrapContainer: {
        width: '100%',
        maxWidth: pageWrapContainer.maxWidth
    },
    title: {
        marginTop: theme.spacing(1),
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    statusContainer: {
        marginBottom: theme.spacing(0)
    },
    status: (props: IComponentProps) => ({
        display: 'flex',
        alignItems: 'center',
        '&::before': {
            content: '\'\'',
            display: 'inline-block',
            width: '8px',
            height: '8px',
            borderRadius: '2px',
            backgroundColor: statusColors[props.status],
            verticalAlign: 'middle',
            marginRight: '8px'
        }
    }),
    iconButton: {
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    iconLabel: {
        fontSize: '0.9rem',
        fontWeight: 600
    },
    warning: {
        height: '80%',
        backgroundColor: palette.error.main
    },
}));

export default useStyles;
