import GeneralApi from './services/GeneralApi';
import EposApi from './services/EposApi';
import ImageApi from './services/ImageApi';
import KpiApi from './services/KpiApi';
import UserApi from './services/UserApi';
import RoleApi from './services/RoleApi';
import AuditLogApi from './services/AuditLogApi';
import ResourceApi from './services/ResourceApi';
import NetworkManagementApi from './services/NetworkManagementApi';
import ReleaseServerApi from './services/ReleaseServerApi';
import CardTransactionsReportApi from './services/CardTransactionsReportApi';
import FinancialPerformanceReportApi from './services/FinancialPerformanceReportApi';
import SupervisorReportApi from './services/SupervisorReportApi';
import TicketsReportApi from './services/TicketsReportApi';
import VouchersReportApi from './services/VouchersReportApi';
import PlayerStatementApi from './services/PlayerStatementApi';
import ReportFiltersApi from './services/ReportFiltersApi';

const Api = {
    General: new GeneralApi(),
    Epos: new EposApi(),
    Image: new ImageApi(),
    Kpi: new KpiApi(),
    User: new UserApi(),
    Role: new RoleApi(),
    AuditLog: new AuditLogApi(),
    Resource: new ResourceApi(),
    NetworkManagement: new NetworkManagementApi(),
    ReleaseServer: new ReleaseServerApi(),
    CardTransactionsReportApi: new CardTransactionsReportApi(),
    SupervisorReport: new SupervisorReportApi(),
    TicketsReport: new TicketsReportApi(),
    VouchersReport: new VouchersReportApi(),
    PlayerStatement: new PlayerStatementApi(),
    FinancialPerformanceReport: new FinancialPerformanceReportApi(),
    ReportFilters: new ReportFiltersApi()
};

export default Api;
