import { useState, useEffect, useContext } from 'react';
import debounce, { IFunctionWithAnyParams } from './debounce';

import { INPUT_DEBOUNCE_INTERVAL } from 'const';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import { getCurrentLanguage } from 'utils/language';
import { formatDateFactory } from 'utils/formatDate';

import {
    Breakpoint,
    Theme,
    useTheme,
} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

type BreakpointOrNull = Breakpoint | null;

export const useDebounce = (value, delay = INPUT_DEBOUNCE_INTERVAL) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value]);

    return debouncedValue;
};

export const useFunctionDebounce = (func: IFunctionWithAnyParams, delay = INPUT_DEBOUNCE_INTERVAL, immediate = false) => {
    const [debouncedFunc, setDebouncedFunc] = useState(() => debounce(func, delay, immediate));

    useEffect(() => {
        setDebouncedFunc(() => debounce(func, delay, immediate));

        return debouncedFunc.clear;
    }, [func, delay, immediate]);

    useEffect(() => {
        return debouncedFunc.clear;
    }, []);

    return debouncedFunc;
};

export function useFormatDate(options) {
    const { globalSettings }: GlobalContextModel = useContext(GlobalContext);
    const currentLocale = getCurrentLanguage(globalSettings?.languages)['Code'];
    const [formatDate, setFormatDate] = useState(() => formatDateFactory(currentLocale, options));

    useEffect(() => {
        setFormatDate(() => formatDateFactory(currentLocale, options));
    }, [currentLocale]);

    return formatDate;
}

export function useWidth() {
    const theme: Theme = useTheme();
    const keys: readonly Breakpoint[] = [...theme.breakpoints.keys].reverse();

    return (
        keys.reduce((output: BreakpointOrNull, key: Breakpoint) => {
            const matches = useMediaQuery(theme.breakpoints.up(key));

            return !output && matches ? key : output;
        }, null) || 'xs'
    );
}
