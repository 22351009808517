import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    infoName: {
        fontSize: '12px',
        color: 'rgba(0, 0, 0, 0.6)',
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        textTransform: 'uppercase'
    },
    infoValue: {
        fontSize: '14px',
        color: 'rgba(0, 0, 0, 0.87)',
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5)
    },
    absoluteLoader: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        marginLeft: '-20px',
        marginTop: '-20px'
    }
}));

export default useStyles;
