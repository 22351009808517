import React from 'react';import { Box, Grid, Typography } from '@mui/material';

import ThresholdInput, { IThresholdInputProps } from 'components/ThresholdInput/ThresholdInput';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
    tieWord: {
        display: 'flex',
        justifyContent: 'center',
    },
}));

export interface ISupervisorThresholdProps {
    className?: string;
    title?: string;
    tieWord?: string;
    thresholdInputsProps: Array<IThresholdInputProps>;
}

const SupervisorThreshold: React.FunctionComponent<ISupervisorThresholdProps> = ({
    className = '',
    title = '',
    tieWord = '',
    thresholdInputsProps
}) => {
    const classes = useStyles({});

    return (
        <Box className={className}
            mb={1.5}
        >
            <Grid
                container
                spacing={3}
            >
                {title.length
                    ? (
                        <Grid item xs={12}>
                            <Typography variant="h6">{title}</Typography>
                        </Grid>
                    ) : null
                }
                {tieWord.length ? (
                    <React.Fragment >
                        <Grid item xs={12} md={5.8}>
                            <ThresholdInput {...thresholdInputsProps[0]} fullWidth />
                        </Grid>
                        <Grid item xs={12} md={0.4} sx={{ alignSelf: 'center' }}>
                            <Box
                                className={classes.tieWord}
                            >
                                <Typography>{tieWord}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={5.8}>
                            <ThresholdInput {...thresholdInputsProps[1]} fullWidth />
                        </Grid>
                    </React.Fragment>
                ) : (
                    thresholdInputsProps.map((props) => (
                        <Grid item xs={12} sm key={props.name}>
                            <ThresholdInput {...props} fullWidth />
                        </Grid>
                    ))
                )}
            </Grid>
        </Box>
    );
};

export default SupervisorThreshold;
