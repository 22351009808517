import * as React from 'react';
import clsx from 'clsx';
import { Avatar, Button, ListItemText, Menu, MenuItem, MenuList, Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import getCookieValue from 'utils/cookieMethods';
import { LanguageListItemModel, GlobalContextModel } from 'api/models/general';
import { LanguageIdCookie } from 'const';

import { GlobalContext, updateTranslationsList } from 'context/globalContext';

const { useState, useEffect, useContext } = React;

const useStyles = makeStyles((theme: Theme) => ({
    wrap: {
        overflow: 'hidden'
    },
    menu: {
        minWidth: 200,
        outline: 'none'
    },
    menuFlag: {
        marginLeft: theme.spacing(1.5),
    },
    flag: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        marginRight: theme.spacing(2),
        borderRadius: '50%'
    },
    button: {
        width: '100%',
        padding: `${theme.spacing(1)} 0`,
        overflow: 'hidden',
        '&:hover': {
            backgroundColor: '#fef5eb',
            '& span': {
                color: theme.palette.primary.main
            }
        },
    },
    buttonWithoutHover: {
        cursor: 'auto',
        '&:hover': {
            backgroundColor: 'inherit',
            '& span': {
                color: 'inherit'
            }
        },
    },
    language: {
        textTransform: 'none',
        textAlign: 'left'
    }
}));

const MenuLanguages = ({ closeParentMenu }) => {
    const classes = useStyles({});

    const { globalSettings }: GlobalContextModel = useContext(GlobalContext);
    const languages = globalSettings?.languages;

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [selectedFlag, setSelectedFlag] = useState<string>('');
    const [selectedLanguage, setSelectedLanguage] = useState<string>('');

    useEffect(() => {
        const cookieValue = getCookieValue(LanguageIdCookie);
        const langItem: LanguageListItemModel = cookieValue
            ? languages?.find(lang => lang.Id.toString() === cookieValue)
            : languages?.find(lang => lang.IsDefault);

        langItem && setSelectedFlag(langItem?.Title.toLowerCase());
        langItem && setSelectedLanguage(langItem?.LocalTitle);
    }, [globalSettings]);

    const handleOpenMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleLanguage = (lang: LanguageListItemModel) => {
        setSelectedFlag(lang?.Title.toLowerCase());
        setSelectedLanguage(lang?.LocalTitle);
        setAnchorEl(null);
        closeParentMenu();
        updateTranslationsList();
    };

    const onClose = () => {
        setAnchorEl(null);
    };

    return (
        languages?.length && selectedFlag && selectedLanguage
            ? (
                <div className={classes.wrap}>
                    <Button
                        id="lang-button"
                        className={clsx(classes.button, languages?.length <= 1 && classes.buttonWithoutHover)}
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleOpenMenu}
                    >
                        <Avatar
                            className={clsx(classes.flag, classes.menuFlag)}
                            alt={selectedLanguage}
                            src={`../../img/countries/${selectedFlag}.png`}
                        />
                        <ListItemText className={classes.language}>{selectedLanguage}</ListItemText>
                    </Button>
                    {
                        languages?.length > 1 &&
                        (
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={onClose}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                <MenuList className={classes.menu}>
                                    {languages?.map(lang => {
                                        return (
                                            <MenuItem onClick={() => handleLanguage(lang)} key={lang.Id}>
                                                <Avatar
                                                    className={classes.flag}
                                                    alt={lang.Title}
                                                    src={`../../img/countries/${lang?.Title?.toLowerCase()}.png`}
                                                />
                                                <ListItemText className={classes.language}>{lang.LocalTitle}</ListItemText>
                                            </MenuItem>
                                        );
                                    })}
                                </MenuList>
                            </Menu>
                        )
                    }
                </div>)
            : null
    );
};

export default MenuLanguages;
