import React, {
    useContext,
    useState,
    useEffect
} from 'react';

import {
    createTheme,
    Divider,
    Grid,
    Typography,
    TextField,
    ThemeProvider,
    StyledEngineProvider,
    Snackbar,
} from '@mui/material';

import MuiAlert from '@mui/material/Alert';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import {
    pickerDateToUtc,
    formatToIsoDateString,
} from 'utils/formatDate';

import defaultTheme, { palette } from 'theme';

import { REPORTS_NAMES, EXCEL_EXPORT_ERROR_ENUM, initRequestInfo } from 'const';

import ButtonWithLinearProgress from 'components/Buttons/ButtonWithLinearProgress';
import AlertMessageFullWidth from 'components/Notifications/AlertMessageFullWidth';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import { startOfDay, endOfDay, addMonths, isValid } from 'utils/formatDate';
import formatStr from 'utils/formatStr';

import { setLanguageCookie } from 'utils/cookieMethods';

import Api from 'api/Api';
import { throwNetworkError } from 'utils/requestCancelation';

import useStyles from './styles';

import { EXPORT_REPORT_TYPE } from 'api/models/reportsExport';
import { mqttClient, initMqttConnection, subscribe as subscribeToMqtt } from 'api/mqttClient';
import { onMessageExcelHandler, ExportToExcelContext, state as mqttState, setState as setMqttState } from 'context/exportToExcel';

import { IRequestInfo } from 'api/models/reports';

const extendedDefaultMuiTheme = createTheme(defaultTheme, {
    palette: {
        primary: {
            main: palette.primary.main
        }
    }
});

let pendingGetExport = false;

const PlayerStatementReport = () => {
    const classes = useStyles({});
    const { globalSettings, translations, timeZone, dateTimeFormat }: GlobalContextModel = useContext(GlobalContext);

    const ExportToExcelState = useContext(ExportToExcelContext);

    const ampm = dateTimeFormat.includes('a');

    const MAX_RANGE_MONTH = 18;

    const playerStatementReportMaxRange = globalSettings?.settings?.ExcelExportSettings?.PlayerStatementReportMaxRangeMonths > MAX_RANGE_MONTH
        ? MAX_RANGE_MONTH
        : globalSettings?.settings?.ExcelExportSettings?.PlayerStatementReportMaxRangeMonths;

    const playerStatementReportMaxRangeInMilliseconds = 1000 * 60 * 60 * 24 * 31 * playerStatementReportMaxRange;

    const {
        percentage: valueExportToExcel,
        isLoading: isLoadingExportToExcel,
        isFailed: isFailedExportToExcel,
        errorCode: excelExportError
    } = ExportToExcelState[REPORTS_NAMES.playerStatement];

    const [firstSubmit, setFirstSubmit] = useState(true);
    const [loyaltyIDValue, setLoyaltyIDValue] = useState('');
    const [dateValue, setDateValue] = useState(startOfDay(
        new Date(),
        {
            gameDayEnable: globalSettings?.settings?.GamingDaySettings?.Enabled,
            startOfGamingDay: globalSettings?.settings?.GamingDaySettings?.Start,
            endOfGamingDay: globalSettings?.settings?.GamingDaySettings?.End
        }
    ));
    const [invalidDateRangeForExport, setInvalidDateRangeForExport] = useState(false);
    const [ showNotification, setShowNotification ] = useState(false);
    const [ requestInfo, setRequestInfo ] = useState<IRequestInfo>({ ...initRequestInfo });

    useEffect(() => {
        if (valueExportToExcel === 100) {
            setShowNotification(true);
            setRequestInfo({
                status: 'success',
                message: translations['player-statement-success-notification']
            });
        }

        if (isFailedExportToExcel) {
            let message = '';

            switch (excelExportError) {
                case EXCEL_EXPORT_ERROR_ENUM.NoData:
                    message = translations['player-statement-nodata-error'];
                    break;
                case EXCEL_EXPORT_ERROR_ENUM.GenerationError:
                    message = translations['general-error'];
                    break;

                default:
                    message = translations['player-statement-error-notification'];
                    break;
            }

            setShowNotification(true);
            setRequestInfo({
                status: 'error',
                message
            });
        }
    }, [valueExportToExcel, isFailedExportToExcel, excelExportError]);

    const onChangeLoyaltyID = (value) => {
        setLoyaltyIDValue(value.replace(/\D/g, ''));
    };

    const onChangeDate = (date: Date) => {
        if (date.getTime() - new Date().getTime() > playerStatementReportMaxRangeInMilliseconds) {
            setInvalidDateRangeForExport(true);
        } else {
            setInvalidDateRangeForExport(false);
        }

        setDateValue(isValid(date) ? date : new Date('Invalid Date object'));
    };

    const handleDownload = () => {
        const fromDate = (typeof dateValue === 'string')
            ? formatToIsoDateString(dateValue as Date)
            : pickerDateToUtc(dateValue as unknown as Date, timeZone).toISOString();
        const toDate = pickerDateToUtc(new Date() as unknown as Date, timeZone).toISOString();
        const payload = {
            fromDate,
            toDate,
            loyaltyId: loyaltyIDValue,
            reportType: EXPORT_REPORT_TYPE.PlayerStatement,
        };

        setFirstSubmit(false);

        if (new Date(toDate).getTime() - new Date(startOfDay(
            new Date(),
            {
                gameDayEnable: globalSettings?.settings?.GamingDaySettings?.Enabled,
                startOfGamingDay: globalSettings?.settings?.GamingDaySettings?.Start,
                endOfGamingDay: globalSettings?.settings?.GamingDaySettings?.End
            }
        )).getTime() >= playerStatementReportMaxRangeInMilliseconds) {
            setInvalidDateRangeForExport(true);
        } else {
            if (!pendingGetExport && loyaltyIDValue) {
                pendingGetExport = true;
                Api.PlayerStatement.GetExport(setLanguageCookie(payload, globalSettings.languages, 'languageId')).then(res => {
                    const {
                        jwtToken,
                        reportId
                    } = res;

                    if (jwtToken) {
                        !mqttClient?.connected && initMqttConnection(jwtToken, globalSettings.settings.MqttSettigs.BrokerKongUrl);
                        subscribeToMqtt({
                            topic: globalSettings.settings.MqttSettigs.ReportsExportStatusTopic + reportId,
                            onMessageHandler: (response) => onMessageExcelHandler(
                                response,
                                reportId,
                                globalSettings.settings.MqttSettigs.ReportsExportStatusTopic + reportId,
                                REPORTS_NAMES.playerStatement)
                        });
                    }
                }).catch((error) => {
                    const status = error.data.status;
                    const message = error.data.message;

                    if (status === -1) {
                        setShowNotification(true);
                        setRequestInfo({
                            status: 'error',
                            message
                        });
                    } else {
                        setMqttState({
                            ...mqttState,
                            [REPORTS_NAMES.playerStatement]: {
                                percentage: 0,
                                isLoading: false,
                                isFailed: true,
                                errorCode: null
                            }
                        });
                    }

                    throwNetworkError(error);
                }).finally(() => {
                    pendingGetExport = false;
                });
            }
        }
    };

    const handleHideAlert = () => {
        setInvalidDateRangeForExport(false);
    };

    const handleCloseNotification = () => {
        setShowNotification(false);
    };

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.titleWrap}>
                    <h1 className={classes.title} data-a="player-statement-page-name">
                        {translations['gen-player-statement']}
                    </h1>
                </div>
            </div>
            <Divider className={classes.divider} />
            <div className={classes.wrap}>
                <div className={classes.alertWrapper}>
                    <AlertMessageFullWidth
                        label={`${formatStr(
                            translations['emp-transaction-export-to-excel-error-days'],
                            { 'days': playerStatementReportMaxRange * 31 }
                        )}`}
                        show={invalidDateRangeForExport}
                        handleHideAlert={handleHideAlert}
                    >
                        {
                            translations['gen-dismiss']
                        }
                    </AlertMessageFullWidth>
                </div>
                <Grid container spacing={2} className={classes.row}>
                    <Grid item md={4} sm={12}>
                        <h2 className={classes.subTitle} data-a="player-statement-generate-report">{translations['player-statement-generate-report']}</h2>
                        <Typography className={classes.description} variant="body2" data-a="player-statement-description">{translations['player-statement-description']}</Typography>
                        <Typography className={classes.note} variant="body2" data-a="player-statement-note">{translations['player-statement-note']}</Typography>
                    </Grid>
                    <Grid item md={8} sm={12}>
                        <Grid container spacing={2}>
                            <Grid item sm={6} xs={12}>
                                <TextField
                                    id="player-statement-loyaltyID"
                                    error={!firstSubmit && !loyaltyIDValue}
                                    helperText={!firstSubmit && !loyaltyIDValue ? translations['gen-valid-msg-empty-field'] : ''}
                                    className={classes.input}
                                    value={loyaltyIDValue}
                                    label={translations['player-statement-loyalty-id']}
                                    onChange={(event) => onChangeLoyaltyID(event.target.value)}
                                    variant="outlined"
                                    color="primary"
                                    inputProps={{ maxLength: 20 }}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <StyledEngineProvider injectFirst>
                                    <ThemeProvider theme={extendedDefaultMuiTheme}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Grid container direction="column" alignItems="center" data-a="date-picker-from">
                                                <DateTimePicker
                                                    ampm={ampm}
                                                    format={dateTimeFormat}
                                                    maxDate = {endOfDay(
                                                        new Date(new Date().toLocaleString('en-US', { timeZone })),
                                                        {
                                                            gameDayEnable: globalSettings?.settings?.GamingDaySettings?.Enabled,
                                                            startOfGamingDay: globalSettings?.settings?.GamingDaySettings?.Start,
                                                            endOfGamingDay: globalSettings?.settings?.GamingDaySettings?.End
                                                        }
                                                    )}
                                                    minDate = {
                                                        addMonths(
                                                            startOfDay(
                                                                new Date(new Date().toLocaleString('en-US', { timeZone })),
                                                                {
                                                                    gameDayEnable: globalSettings?.settings?.GamingDaySettings?.Enabled,
                                                                    startOfGamingDay: globalSettings?.settings?.GamingDaySettings?.Start,
                                                                    endOfGamingDay: globalSettings?.settings?.GamingDaySettings?.End
                                                                }
                                                            ),
                                                            - playerStatementReportMaxRange)
                                                    }
                                                    label={'From'}
                                                    value={dateValue}
                                                    onChange={onChangeDate}
                                                    onAccept={onChangeDate}
                                                    data-a="palyer-statement-date"
                                                    sx={{ width: '100%' }}
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </ThemeProvider>
                                </StyledEngineProvider>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item sm={12} className={classes.buttonContainer}>
                                <ButtonWithLinearProgress
                                    label={translations['player-statement-download-button'] as string}
                                    value={valueExportToExcel}
                                    handleLoading={handleDownload}
                                    loading={isLoadingExportToExcel && !isFailedExportToExcel}
                                    contained
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={showNotification}
                autoHideDuration={6000}
                onClose={handleCloseNotification}
            >
                <MuiAlert
                    severity={requestInfo.status}
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseNotification}
                >
                    {requestInfo.message}
                </MuiAlert>
            </Snackbar>
        </div>
    );
};

export default PlayerStatementReport;
