import * as React from 'react';
import clsx from 'clsx';

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
    Button,
    Box,
    Checkbox,
    Grid,
    TextField,
    FormControl,
    FormControlLabel,
    InputLabel,
    Select,
    FormHelperText,
    IconButton,
} from '@mui/material';

import CheckboxRadioListWithSearchModal from 'components/Modal/CheckboxRadioListWithSearchModal/CheckboxRadioListWithSearchModal';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import ButtonPrimaryText from 'components/Buttons/ButtonPrimaryText';

import { GlobalContext } from 'context/globalContext';

import { OPERATORS_SPECIFIC_USER_KEYS } from 'const';
import { userChangeBetshop } from 'data';

import { IOperatorSpecificProperties } from 'api/models/user';
import { INetworkBetshop } from 'api/models/network-management';

import { GlobalContextModel } from 'api/models/general';

import { useWidth } from 'utils/customHooks';

const { useState, useContext } = React;

const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        '& $wrap': {
            '&:last-of-type': {
                borderBottom: 'none'
            },
        }
    },
    wrap: {
        borderBottom: '1px solid #E2E2E2',
    },
    wrapLast: {
        borderBottom: 'none'
    },
    container: {
        display: 'grid',
        gridColumnGap: theme.spacing(3),
        gridRowGap: theme.spacing(3),
        gridTemplateColumns: 'repeat(2, 1fr)',
        alignItems: 'start',
        marginBottom: theme.spacing(2),

        [theme.breakpoints.down('sm')]: {
            display: 'block',
            width: '100%'
        },
    },
    containerMultitenancy: {
        display: 'flex',
        marginBottom: theme.spacing(1)
    },
    containerSingle: {
        display: 'block'
    },
    item: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'nowrap',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(2),
            flexWrap: 'wrap'
        },
    },
    itemSpace: {
        marginRight: theme.spacing(6)
    },
    operatorItem: {
        width: '100%'
    },
    row: {
        padding: theme.spacing(1, 0),
        marginTop: theme.spacing(1),

        [theme.breakpoints.down('md')]: {
            marginTop: 0,
        },

        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        },

        ':last-child > &': {
            [theme.breakpoints.down('sm')]: {
                marginBottom: 0,
            }
        },
    },
    operatorRow: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            padding: 0
        }
    },
    operatorTitle: {
        fontWeight: 500,
        margin: `0 0 ${theme.spacing(2)}`
    },
    iconButton: {
        marginLeft: theme.spacing(1)
    },
    groupLabel: {
        marginTop: 0,
        marginBottom: theme.spacing(2),

        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2)
        },
    },
    groupSubHeading: {
        marginTop: 0,
        color: 'rgba(0, 0, 0, 0.38)',

        [theme.breakpoints.down('lg')]: {
            marginTop: theme.spacing(2)
        },
    },
    formControl: {
        position: 'relative',
        width: '100%',
    },
    selectLabel: {
        background: theme.palette.background.default
    },
    selectLabelFocus: {
        transform: 'translate(14px, -6px) scale(0.75)'
    },
    mobileButton: {
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.error.main
    },
    error: {
        margin: theme.spacing(1, 0),
        color: theme.palette.error.main
    },
    checkboxRow: {
        marginTop: -theme.spacing(3)
    },
    //betshops
    groupsContainer: {
        width: '100%'
    },
    groupsButton: {
        height: theme.spacing(7),
        width: '100%',
        lineHeight: 1,
        '&:hover': {
            borderColor: theme.palette.common.black,
            backgroundColor: 'inherit'
        }
    },
    groupsButtonError: {
        border: `1px solid ${theme.palette.error.main}`,
        color: theme.palette.error.main
    },
    hideMobile: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    }
}));

const UserOperators = (
    props: {
        isMultitenancyOperators: boolean;
        firstSubmit: boolean;
        clientId: string;
        selectRoleInputName: string;
        selectOperatorName: string;
        availableOperatorsPermissions: object;
        operatorsList: Array<string>;
        operatorsRole: object;
        cashierRoles: object;
        operatorsBetshops: Array<INetworkBetshop>;
        initOperatorsState: IOperatorSpecificProperties;
        operatorsState: IOperatorSpecificProperties;
        errorFields: object;
        errorMessagesKeys: object;
        operatorsSpecificUserErrors: object;
        isInternal: boolean;
        readOnly?: boolean;
        operatorsSpecificUserState: object;
        handleRoleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
        handleCashierRoleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
        handleEmpBetshopsChange: (betshopsSelected: Array<number>, betshopsCurrentOperator: string) => void;
        handleCashierBetshopsChange: (betshopSelected: number, betshopsCurrentOperator: string) => void;
        handleHideShopData: (betshopsCurrentOperator: string) => void;
        handleOperatorRemove: Function;
        handleOperatorModalView: Function;
        handleOperatorsSpecificUserState: Function;
    }) => {
    const {
        isMultitenancyOperators,
        firstSubmit,
        clientId,
        selectRoleInputName,
        selectOperatorName,
        availableOperatorsPermissions,
        operatorsList,
        operatorsRole,
        cashierRoles,
        operatorsBetshops,
        initOperatorsState,
        operatorsState,
        errorFields,
        errorMessagesKeys,
        operatorsSpecificUserErrors,
        isInternal,
        readOnly = false,
        operatorsSpecificUserState,
        handleRoleChange,
        handleCashierRoleChange,
        handleEmpBetshopsChange,
        handleCashierBetshopsChange,
        handleHideShopData,
        handleOperatorRemove,
        handleOperatorModalView,
        handleOperatorsSpecificUserState
    } = props;

    const empBetshopsModal = 'emp-betshops-modal';
    const cashierBetshopsModal = 'cashier-betshops-modal';

    const isMobileView = useWidth() === 'xs';

    const { globalSettings, translations, permissions }: GlobalContextModel = useContext(GlobalContext);

    const classes = useStyles();

    const enabledFeatures = globalSettings.features;

    const [betshopsModalView, setBetshopsModalView] = useState(false);
    const [betshopsCurrentOperator, setBetshopsCurrentOperator] = useState(isMultitenancyOperators ? '' : clientId);
    const [currentModalType, setCurrentModalType] = useState('');

    const handleOperatorModal = () => {
        handleOperatorModalView();
    };
    const handleBetshopModal = (currentOperator: string, modalType: string) => {
        setBetshopsModalView(true);
        setBetshopsCurrentOperator(currentOperator);
        setCurrentModalType(modalType);
    };
    const handleCloseBetshopsModal = () => {
        setBetshopsModalView(false);
    };
    const handleConfirmBetshopsModal = (selectedIds: Array<number>) => {
        setBetshopsModalView(false);
        currentModalType === empBetshopsModal
            ? handleEmpBetshopsChange(selectedIds, betshopsCurrentOperator)
            : handleCashierBetshopsChange(selectedIds[0], betshopsCurrentOperator);
    };

    const isReadOnly = (): boolean => {
        const hasChangeBetshopPermission = availableOperatorsPermissions?.[betshopsCurrentOperator]?.includes(userChangeBetshop);
        const hasInitBetshop = initOperatorsState?.[betshopsCurrentOperator]?.[OPERATORS_SPECIFIC_USER_KEYS.cashier]?.betshopId;
        const disabledAccessToEdition = isMultitenancyOperators && !operatorsList.includes(betshopsCurrentOperator);
        const alreadyHasBetshopNotInGroups = (hasInitBetshop ? !hasChangeBetshopPermission : hasInitBetshop) && currentModalType !== empBetshopsModal;
        // to check

        return readOnly || disabledAccessToEdition || alreadyHasBetshopNotInGroups;
    };

    const sortedOperatorNames = Object.keys(operatorsState)?.sort((a, b) => a.localeCompare(b));

    return (
        <Grid container spacing={2} className={classes.row}>
            <Grid item md={4} sm={12} xs={12}>
                <h2 className={classes.groupLabel}>{translations['users-role-and-security-heading']}</h2>
                <h3 className={classes.groupSubHeading}>{translations['users-role-and-security-subheading']}</h3>
            </Grid>
            <Grid item md={8} sm={12} xs={12} className={classes.wrapper}>
                {isMultitenancyOperators
                    ? (
                        sortedOperatorNames?.map((operatorName, idx) => {
                            const operatorRole = operatorsState[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.emp]?.roleId;
                            const cashierRole = operatorsState[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.cashier]?.roleId;
                            const operatorDisabled = !operatorsList.includes(operatorName);
                            const roleList = operatorsRole[operatorName];
                            const cashierList = cashierRoles[operatorName];
                            const empBetshopSelected = operatorsState[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.emp] !== null
                                ? operatorsState[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.emp]?.betshops || []
                                : operatorsState[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.supervisor]?.betshops || [];
                            const cashierBetshopSelected = operatorsState[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.cashier]?.betshopId;
                            const hideShopData = operatorsState[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.cashier]?.hideShopData;
                            const helperText = !firstSubmit && errorFields[operatorName] && translations[errorMessagesKeys[operatorName]];

                            return (
                                <div className={clsx(classes.wrap, (Object.keys(operatorsState).length === idx + 1) && classes.wrapLast)} key={operatorName} >
                                    <div className={clsx(classes.row, classes.operatorRow)}>
                                        <div className={clsx(classes.item, classes.operatorItem)}>
                                            <TextField
                                                disabled={operatorDisabled || isInternal || readOnly}
                                                fullWidth
                                                helperText={helperText}
                                                variant="outlined"
                                                value={translations[`operator-${operatorName}`]}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                data-a="users-operator-name"
                                            />
                                        </div>
                                        <div className={classes.item}>
                                            {
                                                operatorDisabled
                                                    ? null
                                                    : (!isInternal && !isMobileView &&
                                                        <IconButton
                                                            className={classes.iconButton}
                                                            data-a="remove-operator-button"
                                                            disabled={readOnly}
                                                            onClick={() => handleOperatorRemove(operatorName)}
                                                            size="large"
                                                        >
                                                            <DeleteOutlineIcon
                                                                color={readOnly ? 'disabled' : 'action'}
                                                            />
                                                        </IconButton>)
                                            }
                                        </div>
                                    </div>
                                    <div className={clsx(classes.container, classes.containerMultitenancy)}>
                                        <FormControlLabel
                                            disabled={operatorDisabled || isInternal || readOnly}
                                            value={operatorsSpecificUserState?.[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.emp]}
                                            control={
                                                <Checkbox
                                                    data-a="emp-checkbox"
                                                    color="primary"
                                                    onClick={() => handleOperatorsSpecificUserState(operatorName, OPERATORS_SPECIFIC_USER_KEYS.emp)}
                                                    checked={!!operatorsSpecificUserState?.[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.emp]}
                                                />
                                            }
                                            label={translations['users-emp']}
                                        />
                                        {
                                            enabledFeatures[operatorName]?.Supervisor?.Enabled &&
                                                <FormControlLabel
                                                    disabled={operatorDisabled || readOnly}
                                                    value={operatorsSpecificUserState?.[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.supervisor]}
                                                    control={
                                                        <Checkbox
                                                            data-a="supervisor-checkbox"
                                                            color="primary"
                                                            onClick={
                                                                () => handleOperatorsSpecificUserState(operatorName, OPERATORS_SPECIFIC_USER_KEYS.supervisor)
                                                            }
                                                            checked={!!operatorsSpecificUserState?.[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.supervisor]}
                                                        />
                                                    }
                                                    label={translations['users-supervisor']}
                                                />
                                        }
                                        {
                                            enabledFeatures[operatorName]?.Cashier?.Enabled &&
                                                <FormControlLabel
                                                    disabled={operatorDisabled || readOnly}
                                                    value={operatorsSpecificUserState?.[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.cashier]}
                                                    control={
                                                        <Checkbox
                                                            data-a="cashier-checkbox"
                                                            color="primary"
                                                            onClick={() => handleOperatorsSpecificUserState(operatorName, OPERATORS_SPECIFIC_USER_KEYS.cashier)}
                                                            checked={!!operatorsSpecificUserState?.[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.cashier]}
                                                        />
                                                    }
                                                    label={translations['users-cashier']}
                                                />
                                        }
                                    </div>
                                    {(operatorsSpecificUserState?.[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.emp] ||
                                        operatorsSpecificUserState?.[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.supervisor]) &&
                                            <div data-a="emp-supervisor-wrap">
                                                {
                                                    operatorsSpecificUserState?.[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.emp] &&
                                                        !operatorsSpecificUserState?.[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.supervisor] &&
                                                            <h4 className={classes.operatorTitle}>{translations['users-emp-access']}</h4>
                                                }
                                                {
                                                    operatorsSpecificUserState?.[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.supervisor] &&
                                                        !operatorsSpecificUserState?.[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.emp] &&
                                                            <h4 className={classes.operatorTitle}>{translations['users-supervisor-access']}</h4>
                                                }
                                                {
                                                    operatorsSpecificUserState?.[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.supervisor] &&
                                                        operatorsSpecificUserState?.[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.emp] &&
                                                            <h4 className={classes.operatorTitle}>{translations['users-emp-supervisor-access']}</h4>
                                                }
                                                <div className={clsx(
                                                    classes.container,
                                                    !operatorsSpecificUserState?.[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.emp] && classes.containerSingle
                                                )}
                                                >
                                                    {operatorsSpecificUserState?.[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.emp] &&
                                                        <div className={classes.item}>
                                                            <FormControl
                                                                disabled={operatorDisabled || isInternal || readOnly}
                                                                variant="outlined"
                                                                className={classes.formControl}
                                                                error={
                                                                    !firstSubmit &&
                                                                    // eslint-disable-next-line max-len
                                                                    (operatorsSpecificUserErrors?.[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.emp]?.[selectRoleInputName] ||
                                                                    // eslint-disable-next-line max-len
                                                                    operatorsSpecificUserErrors?.[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.supervisor]?.[selectRoleInputName])
                                                                }
                                                            >
                                                                <InputLabel
                                                                    id={operatorName}
                                                                    color="primary"
                                                                    className={clsx(classes.selectLabel, operatorRole && classes.selectLabelFocus)}
                                                                    htmlFor={operatorName}
                                                                >
                                                                    {translations['users-emp-role']}
                                                                </InputLabel>
                                                                <Select
                                                                    labelId={operatorName}
                                                                    native
                                                                    value={operatorRole || ''}
                                                                    onChange={handleRoleChange}
                                                                    color="primary"
                                                                    displayEmpty
                                                                    inputProps={{
                                                                        name: operatorName,
                                                                        id: operatorName,
                                                                        'data-a': 'select-role'
                                                                    }}
                                                                >
                                                                    <option value="" disabled>{translations['users-emp-role']}</option>
                                                                    {roleList?.map(role => (
                                                                        <option key={role.id} value={role.id}>{role.name}</option>
                                                                    ))}
                                                                </Select>
                                                                {(!firstSubmit &&
                                                                    // eslint-disable-next-line max-len
                                                                    (operatorsSpecificUserErrors?.[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.emp]?.[selectRoleInputName] ||
                                                                    // eslint-disable-next-line max-len
                                                                    operatorsSpecificUserErrors?.[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.supervisor]?.[selectRoleInputName])) &&
                                                                        <FormHelperText data-a="select-role-error">
                                                                            {translations[errorMessagesKeys[selectRoleInputName]]}
                                                                        </FormHelperText>
                                                                }
                                                            </FormControl>
                                                        </div>
                                                    }
                                                    <div className={clsx(classes.item, operatorDisabled && classes.itemSpace)}>
                                                        <FormControl
                                                            className={classes.groupsContainer}
                                                            variant="outlined"
                                                            error={
                                                                !firstSubmit && operatorsSpecificUserErrors?.[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.emp]?.['betshops']
                                                            }
                                                        >
                                                            <Button
                                                                className={clsx(
                                                                    classes.groupsButton,
                                                                    !firstSubmit &&
                                                                    operatorsSpecificUserErrors?.[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.emp]?.['betshops'] &&
                                                                    classes.groupsButtonError)
                                                                }
                                                                variant="outlined"
                                                                onClick={() => handleBetshopModal(operatorName, empBetshopsModal)}
                                                                disabled={false}
                                                                data-a="users-assign-betshops-button-emp"
                                                            >
                                                                {
                                                                    empBetshopSelected?.length > 1
                                                                        ? `${empBetshopSelected?.length} ${translations['groups-x-betshops-selected']}`
                                                                        : empBetshopSelected?.length === 1
                                                                            ? `1 ${translations['groups-betshop-selected']}`
                                                                            : translations['users-select-betshop']
                                                                }
                                                            </Button>
                                                            {!firstSubmit && operatorsSpecificUserErrors?.[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.emp]?.['betshops'] &&
                                                                <FormHelperText data-a="button-betshops-error">{translations[errorMessagesKeys['betshops']]}</FormHelperText>}
                                                        </FormControl>
                                                    </div>
                                                </div>
                                            </div>
                                    }

                                    {operatorsSpecificUserState?.[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.cashier] &&
                                        <div data-a="cashier-wrap">
                                            <h4 className={classes.operatorTitle}>{translations['users-cashier-access']}</h4>
                                            <div className={clsx(classes.container)}>
                                                <div className={classes.item}>
                                                    <FormControl
                                                        disabled={operatorDisabled || readOnly}
                                                        variant="outlined"
                                                        className={classes.formControl}
                                                        error={!firstSubmit &&
                                                            // eslint-disable-next-line max-len
                                                            operatorsSpecificUserErrors?.[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.cashier]?.[selectRoleInputName]
                                                        }
                                                    >
                                                        <InputLabel
                                                            id={operatorName}
                                                            color="primary"
                                                            className={clsx(classes.selectLabel, cashierRole && classes.selectLabelFocus)}
                                                            htmlFor={operatorName}
                                                        >
                                                            {translations['users-cashier-role']}
                                                        </InputLabel>
                                                        <Select
                                                            labelId={operatorName}
                                                            native
                                                            value={cashierRole || ''}
                                                            onChange={handleCashierRoleChange}
                                                            color="primary"
                                                            displayEmpty
                                                            inputProps={{
                                                                name: operatorName,
                                                                id: operatorName,
                                                                'data-a': 'select-cashier-role'
                                                            }}
                                                        >
                                                            <option value="" disabled>{translations['users-cashier-role']}</option>
                                                            {cashierList?.map(role => (
                                                                <option key={role.id} value={role.id}>{role.name}</option>
                                                            ))}
                                                        </Select>
                                                        {(!firstSubmit &&
                                                            // eslint-disable-next-line max-len
                                                            operatorsSpecificUserErrors?.[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.cashier]?.[selectRoleInputName]) &&
                                                                <FormHelperText data-a="select-cashier-role-error">
                                                                    {translations[errorMessagesKeys[selectRoleInputName]]}
                                                                </FormHelperText>
                                                        }
                                                    </FormControl>
                                                </div>
                                                <div className={clsx(classes.item, operatorDisabled && classes.itemSpace)}>
                                                    <FormControl
                                                        className={classes.groupsContainer}
                                                        variant="outlined"
                                                        error={!firstSubmit &&
                                                            operatorsSpecificUserErrors?.[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.cashier]?.['betshops']
                                                        }
                                                    >
                                                        <Button
                                                            className={clsx(
                                                                classes.groupsButton,
                                                                !firstSubmit &&
                                                                    operatorsSpecificUserErrors?.[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.cashier]?.['betshops'] &&
                                                                    classes.groupsButtonError
                                                            )}
                                                            variant="outlined"
                                                            onClick={() => handleBetshopModal(operatorName, cashierBetshopsModal)}
                                                            disabled={false}
                                                            data-a="users-assign-betshop-button-cashier"
                                                        >
                                                            {
                                                                cashierBetshopSelected
                                                                    ? `1 ${translations['groups-betshop-selected']}`
                                                                    : translations['users-select-betshop']
                                                            }
                                                        </Button>
                                                        {!firstSubmit &&
                                                            operatorsSpecificUserErrors?.[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.cashier]?.['betshops'] &&
                                                                <FormHelperText data-a="button-betshops-error">{translations[errorMessagesKeys['betshops']]}</FormHelperText>}
                                                    </FormControl>
                                                </div>
                                                <FormControlLabel
                                                    disabled={operatorDisabled || readOnly}
                                                    classes={{ root: classes.checkboxRow }}
                                                    control={
                                                        <Checkbox
                                                            checked={hideShopData}
                                                            onChange={() => handleHideShopData(operatorName)}
                                                            name="hide-shop-data"
                                                            color="primary"
                                                        />
                                                    }
                                                    label={translations['users-hide-shop-data']}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {isMobileView &&
                                        <Box
                                            alignItems={'center'}
                                        >
                                            <Button
                                                data-a="remove-operator-button"
                                                disabled={readOnly}
                                                className={classes.mobileButton}
                                                variant="contained"
                                                color="primary"
                                                startIcon={<DeleteOutlineIcon />}
                                                onClick={() => handleOperatorRemove(operatorName)}
                                            >
                                                {translations['gen-remove']}
                                            </Button>
                                        </Box>
                                    }
                                    {(!firstSubmit &&
                                        Object?.values(operatorsSpecificUserState?.[operatorName] || {})?.every(e => !e) &&
                                        operatorsSpecificUserErrors?.[operatorName]?.[OPERATORS_SPECIFIC_USER_KEYS.general]?.['EmptyFields']) &&
                                            <FormHelperText data-a="empty-operator-error" className={classes.error} error={errorFields[selectOperatorName]}>
                                                {translations['users-valid-msg-empty-operator']}
                                            </FormHelperText>}
                                </div>
                            );
                        })
                    )
                    : (
                        <div className={classes.wrap}>
                            <div className={clsx(classes.container, classes.containerMultitenancy)}>
                                <div>
                                    <FormControlLabel
                                        className={classes.hideMobile}
                                        value={operatorsSpecificUserState?.[clientId]?.[OPERATORS_SPECIFIC_USER_KEYS.emp]}
                                        disabled={readOnly || isInternal}
                                        control={
                                            <Checkbox
                                                data-a="emp-checkbox"
                                                color="primary"
                                                onClick={() => handleOperatorsSpecificUserState(clientId, OPERATORS_SPECIFIC_USER_KEYS.emp)}
                                                checked={!!operatorsSpecificUserState?.[clientId]?.[OPERATORS_SPECIFIC_USER_KEYS.emp]}
                                            />
                                        }
                                        label={translations['users-emp']}
                                    />
                                </div>
                                {
                                    enabledFeatures[clientId].Supervisor.Enabled &&
                                        <FormControlLabel
                                            value={operatorsSpecificUserState?.[clientId]?.supervisor}
                                            disabled={readOnly}
                                            control={
                                                <Checkbox
                                                    data-a="supervisor-checkbox"
                                                    color="primary"
                                                    onClick={() => handleOperatorsSpecificUserState(clientId, OPERATORS_SPECIFIC_USER_KEYS.supervisor)}
                                                    checked={!!operatorsSpecificUserState?.[clientId]?.supervisor}
                                                />
                                            }
                                            label={translations['users-supervisor']}
                                        />
                                }
                                {
                                    enabledFeatures[clientId].Cashier.Enabled &&
                                        <FormControlLabel
                                            className={classes.hideMobile}
                                            value={operatorsSpecificUserState?.[clientId]?.[OPERATORS_SPECIFIC_USER_KEYS.cashier]}
                                            disabled={readOnly}
                                            control={
                                                <Checkbox
                                                    data-a="cashier-checkbox"
                                                    color="primary"
                                                    onClick={() => handleOperatorsSpecificUserState(clientId, OPERATORS_SPECIFIC_USER_KEYS.cashier)}
                                                    checked={!!operatorsSpecificUserState?.[clientId]?.[OPERATORS_SPECIFIC_USER_KEYS.cashier]}
                                                />
                                            }
                                            label={translations['users-cashier']}
                                        />
                                }
                            </div>
                            {(operatorsSpecificUserState?.[clientId]?.[OPERATORS_SPECIFIC_USER_KEYS.emp] ||
                                operatorsSpecificUserState?.[clientId]?.[OPERATORS_SPECIFIC_USER_KEYS.supervisor]) &&
                                    <div data-a="emp-supervisor-wrap">
                                        {
                                            operatorsSpecificUserState?.[clientId]?.[OPERATORS_SPECIFIC_USER_KEYS.emp] &&
                                                !operatorsSpecificUserState?.[clientId]?.[OPERATORS_SPECIFIC_USER_KEYS.supervisor] &&
                                                    <h4 className={classes.operatorTitle}>{translations['users-emp-access']}</h4>
                                        }
                                        {
                                            operatorsSpecificUserState?.[clientId]?.[OPERATORS_SPECIFIC_USER_KEYS.supervisor] &&
                                                !operatorsSpecificUserState?.[clientId]?.[OPERATORS_SPECIFIC_USER_KEYS.emp] &&
                                                    <h4 className={classes.operatorTitle}>{translations['users-supervisor-access']}</h4>
                                        }
                                        {
                                            operatorsSpecificUserState?.[clientId]?.[OPERATORS_SPECIFIC_USER_KEYS.supervisor] &&
                                                operatorsSpecificUserState?.[clientId]?.[OPERATORS_SPECIFIC_USER_KEYS.emp] &&
                                                    <h4 className={classes.operatorTitle}>{translations['users-emp-supervisor-access']}</h4>
                                        }
                                        <div className={clsx(
                                            classes.container,
                                            !operatorsSpecificUserState?.[clientId]?.[OPERATORS_SPECIFIC_USER_KEYS.emp] && classes.containerSingle
                                        )}
                                        >
                                            {operatorsSpecificUserState?.[clientId]?.[OPERATORS_SPECIFIC_USER_KEYS.emp] &&
                                                <div className={classes.item}>
                                                    <FormControl
                                                        variant="outlined"
                                                        className={classes.formControl}
                                                        disabled={isInternal || readOnly}
                                                        error={
                                                            !firstSubmit &&
                                                            // eslint-disable-next-line max-len
                                                            (operatorsSpecificUserErrors?.[clientId]?.[OPERATORS_SPECIFIC_USER_KEYS.emp]?.[selectRoleInputName] ||
                                                            // eslint-disable-next-line max-len
                                                            operatorsSpecificUserErrors?.[clientId]?.[OPERATORS_SPECIFIC_USER_KEYS.supervisor]?.[selectRoleInputName])
                                                        }
                                                    >
                                                        <InputLabel
                                                            color="primary"
                                                            className={clsx(classes.selectLabel, operatorsState?.[clientId] && classes.selectLabelFocus)}
                                                            htmlFor="select-role"
                                                        >
                                                            {translations['users-select-role']}
                                                        </InputLabel>
                                                        <Select
                                                            native
                                                            value={operatorsState?.[clientId]?.[OPERATORS_SPECIFIC_USER_KEYS.emp]?.roleId || ''}
                                                            onChange={handleRoleChange}
                                                            color="primary"
                                                            displayEmpty
                                                            inputProps={{
                                                                name: clientId,
                                                                id: clientId,
                                                                'data-a': 'select-role'
                                                            }}
                                                        >
                                                            <option value="" disabled>{translations['users-select-role']}</option>
                                                            {operatorsRole[clientId]?.map(role => (
                                                                <option key={role.id} value={role.id}>{role.name}</option>
                                                            ))}
                                                        </Select>
                                                        {
                                                            !firstSubmit &&
                                                            // eslint-disable-next-line max-len
                                                            (operatorsSpecificUserErrors?.[clientId]?.[OPERATORS_SPECIFIC_USER_KEYS.emp]?.[selectRoleInputName] ||
                                                            // eslint-disable-next-line max-len
                                                            operatorsSpecificUserErrors?.[clientId]?.[OPERATORS_SPECIFIC_USER_KEYS.supervisor]?.[selectRoleInputName]) &&
                                                                <FormHelperText data-a="select-role-error">{translations[errorMessagesKeys[selectRoleInputName]]}</FormHelperText>}
                                                    </FormControl>
                                                </div>
                                            }
                                            <div className={classes.item}>
                                                <FormControl
                                                    className={classes.groupsContainer}
                                                    variant="outlined"
                                                    error={!firstSubmit && operatorsSpecificUserErrors?.[clientId]?.[OPERATORS_SPECIFIC_USER_KEYS.emp]?.['betshops']}
                                                    disabled={isInternal || readOnly}
                                                >
                                                    <Button
                                                        className={clsx(
                                                            classes.groupsButton,
                                                            !firstSubmit &&
                                                            operatorsSpecificUserErrors?.[clientId]?.[OPERATORS_SPECIFIC_USER_KEYS.emp]?.['betshops'] &&
                                                            classes.groupsButtonError)
                                                        }
                                                        variant="outlined"
                                                        onClick={() => handleBetshopModal(clientId, empBetshopsModal)}
                                                        disabled={false}
                                                    >
                                                        {
                                                            operatorsState?.[clientId]?.[OPERATORS_SPECIFIC_USER_KEYS.emp]?.betshops?.length > 1
                                                                ? `${operatorsState?.[clientId]?.[OPERATORS_SPECIFIC_USER_KEYS.emp]?.betshops?.length} ${translations['groups-x-betshops-selected']}`
                                                                : operatorsState?.[clientId]?.[OPERATORS_SPECIFIC_USER_KEYS.emp]?.betshops?.length === 1
                                                                    ? `${operatorsState?.[clientId]?.[OPERATORS_SPECIFIC_USER_KEYS.emp]?.betshops?.length} ${translations['groups-betshop-selected']}`
                                                                    : translations['users-select-betshop']
                                                        }
                                                    </Button>
                                                    {!firstSubmit && operatorsSpecificUserErrors?.[clientId]?.[OPERATORS_SPECIFIC_USER_KEYS.emp]?.['betshops'] &&
                                                        <FormHelperText data-a="button-groups-error">{translations[errorMessagesKeys['betshops']]}</FormHelperText>}
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                            }
                            {operatorsSpecificUserState?.[clientId]?.[OPERATORS_SPECIFIC_USER_KEYS.cashier] &&
                                <div data-a="cashier-wrap">
                                    <h4 className={classes.operatorTitle}>{translations['users-cashier-access']}</h4>
                                    <div className={clsx(classes.container)}>
                                        <div className={classes.item}>
                                            <FormControl
                                                disabled={readOnly}
                                                variant="outlined"
                                                className={classes.formControl}
                                                error={!firstSubmit &&
                                                    // eslint-disable-next-line max-len
                                                    operatorsSpecificUserErrors?.[clientId]?.[OPERATORS_SPECIFIC_USER_KEYS.cashier]?.[selectRoleInputName]
                                                }
                                            >
                                                <InputLabel
                                                    id={clientId}
                                                    color="primary"
                                                    className={clsx(
                                                        classes.selectLabel,
                                                        operatorsState[clientId]?.[OPERATORS_SPECIFIC_USER_KEYS.cashier]?.roleId && classes.selectLabelFocus)}
                                                    htmlFor={clientId}
                                                >
                                                    {translations['users-cashier-role']}
                                                </InputLabel>
                                                <Select
                                                    labelId={clientId}
                                                    native
                                                    value={operatorsState[clientId]?.[OPERATORS_SPECIFIC_USER_KEYS.cashier]?.roleId || ''}
                                                    onChange={handleCashierRoleChange}
                                                    color="primary"
                                                    displayEmpty
                                                    inputProps={{
                                                        name: clientId,
                                                        id: clientId,
                                                        'data-a': 'select-cashier-role'
                                                    }}
                                                >
                                                    <option value="" disabled>{translations['users-cashier-role']}</option>
                                                    {cashierRoles[clientId]?.map(role => (
                                                        <option key={role.id} value={role.id}>{role.name}</option>
                                                    ))}
                                                </Select>
                                                {(!firstSubmit &&
                                                    // eslint-disable-next-line max-len
                                                    operatorsSpecificUserErrors?.[clientId]?.[OPERATORS_SPECIFIC_USER_KEYS.cashier]?.[selectRoleInputName]) &&
                                                        <FormHelperText data-a="select-cashier-role-error">
                                                            {translations[errorMessagesKeys[selectRoleInputName]]}
                                                        </FormHelperText>
                                                }
                                            </FormControl>
                                        </div>
                                        <div className={clsx(classes.item)}>
                                            <FormControl
                                                className={classes.groupsContainer}
                                                variant="outlined"
                                                error={!firstSubmit &&
                                                    operatorsSpecificUserErrors?.[clientId]?.[OPERATORS_SPECIFIC_USER_KEYS.cashier]?.['betshops']
                                                }
                                            >
                                                <Button
                                                    className={clsx(
                                                        classes.groupsButton,
                                                        !firstSubmit &&
                                                            operatorsSpecificUserErrors?.[clientId]?.[OPERATORS_SPECIFIC_USER_KEYS.cashier]?.['betshops'] &&
                                                            classes.groupsButtonError
                                                    )}
                                                    variant="outlined"
                                                    onClick={() => handleBetshopModal(clientId, cashierBetshopsModal)}
                                                >
                                                    {
                                                        operatorsState[clientId]?.[OPERATORS_SPECIFIC_USER_KEYS.cashier]?.betshopId
                                                            ? `1 ${translations['groups-betshop-selected']}`
                                                            : translations['users-select-betshop']
                                                    }
                                                </Button>
                                                {!firstSubmit &&
                                                    operatorsSpecificUserErrors?.[clientId]?.[OPERATORS_SPECIFIC_USER_KEYS.cashier]?.['betshops'] &&
                                                        <FormHelperText data-a="button-groups-error">{translations[errorMessagesKeys['betshops']]}</FormHelperText>}
                                            </FormControl>
                                        </div>
                                        <FormControlLabel
                                            disabled={readOnly}
                                            classes={{ root: classes.checkboxRow }}
                                            control={
                                                <Checkbox
                                                    checked={operatorsState[clientId]?.[OPERATORS_SPECIFIC_USER_KEYS.cashier]?.hideShopData}
                                                    onChange={() => handleHideShopData(clientId)}
                                                    name="hide-shop-data"
                                                    color="primary"
                                                />
                                            }
                                            label={translations['users-hide-shop-data']}
                                        />
                                        {(!firstSubmit &&
                                            Object.values(operatorsSpecificUserState?.[clientId]).every(e => !e) &&
                                            operatorsSpecificUserErrors?.[clientId]?.[OPERATORS_SPECIFIC_USER_KEYS.general]?.['EmptyFields']) &&
                                                <FormHelperText data-a="empty-operator-error" className={classes.error} error={errorFields[selectOperatorName]}>
                                                    {translations['users-valid-msg-empty-operator-single']}
                                                </FormHelperText>}
                                    </div>
                                </div>
                            }
                        </div>
                    )
                }
                {
                    isMultitenancyOperators && !isInternal &&
                        <>
                            <Grid item sm={12} xs={12}>
                                <ButtonPrimaryText
                                    dataA="users-add-operators"
                                    disabled={readOnly}
                                    variant="outlined"
                                    onClick={handleOperatorModal}
                                >
                                    {translations['users-add-operators'] || ''}
                                </ButtonPrimaryText>
                            </Grid>
                            {(!firstSubmit && errorFields[selectOperatorName]) &&
                                <FormHelperText className={classes.error} error={errorFields[selectOperatorName]}>
                                    {translations[errorMessagesKeys[selectOperatorName]]}
                                </FormHelperText>}
                        </>
                }
            </Grid>
            <CheckboxRadioListWithSearchModal
                isOpen={betshopsModalView}
                onClose={handleCloseBetshopsModal}
                onConfirm={handleConfirmBetshopsModal}
                data={operatorsBetshops[betshopsCurrentOperator]?.sort((a, b) => a.name.localeCompare(b.name))}
                selectedIds={
                    currentModalType === empBetshopsModal
                        ? operatorsState?.[betshopsCurrentOperator]?.[OPERATORS_SPECIFIC_USER_KEYS.emp] !== null
                            ? operatorsState?.[betshopsCurrentOperator]?.[OPERATORS_SPECIFIC_USER_KEYS.emp]?.betshops || []
                            : operatorsState?.[betshopsCurrentOperator]?.[OPERATORS_SPECIFIC_USER_KEYS.supervisor]?.betshops || []
                        : [operatorsState?.[betshopsCurrentOperator]?.[OPERATORS_SPECIFIC_USER_KEYS.cashier]?.betshopId] || []
                }
                readOnly={isReadOnly()}
                isPermissionMessageShow={currentModalType !== empBetshopsModal}
                multiple={currentModalType === empBetshopsModal}
                onlyOneChange={
                    currentModalType !== empBetshopsModal &&
                    !initOperatorsState?.[betshopsCurrentOperator]?.[OPERATORS_SPECIFIC_USER_KEYS.cashier]?.betshopId &&
                    (!globalSettings.user.IsInternal ||
                    !permissions.userChangeBetshopPermission)
                }
            />
        </Grid>
    );
};

export default UserOperators;
