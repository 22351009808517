import React from 'react';
import clsx from 'clsx';

import { Button } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
    button: {
        color: theme.palette.primary.main,
    }
}));

const ButtonPrimaryText = ({
    className,
    children,
    dataA = '',
    onClick,
    variant = 'text',
    disabled = false
}: {
    className?: string;
    children?: React.ReactNode;
    dataA?: string;
    onClick: () => void;
    variant?: 'text' | 'outlined' | 'contained';
    disabled?: boolean;
}) => {
    const classes = useStyles({});

    return (
        <Button className={clsx(className, classes.button)} data-a={dataA} onClick={onClick} variant={variant} disabled={disabled}>
            {children}
        </Button>
    );
};

export default ButtonPrimaryText;
