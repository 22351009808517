import * as React from 'react';
import { Box, Typography, Theme } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import useStyles from './styles';

import { GlobalContext } from 'context/globalContext';
import { formatDateByPattern, formatToIsoDateString } from 'utils/formatDate';

import { GlobalContextModel } from 'api/models/general';
import { ITicketDetailsModel, IExpandedDetailsBlocks } from 'api/models/reports';

import { BET_STATUS } from 'const';

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': { borderBottom: 0 },
        '&:before': { display: 'none' },
        '&$expanded': { margin: 'auto' }
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles((theme: Theme) => ({
    root: {
        minHeight: 68,
    },
    content: {
        margin: '0',
        alignItems: 'center',
        '&$expanded': {
            margin: '0'
        },

        [theme.breakpoints.down('md')]: {
            flexWrap: 'wrap'
        }
    },
    expanded: {
        backgroundColor: theme.palette.grey[100]
    },
    expandIcon: {
        [theme.breakpoints.down('md')]: {
            position: 'absolute',
            top: theme.spacing(2),
            right: theme.spacing(2),
        }
    },
    modalBox: {
        padding: `0 ${theme.spacing(3)}`,
        marginBottom: theme.spacing(2),
    },
    inputWrap: {
        '& .Mui-focused': {
            color: `${theme.palette.primary.main}`,
        }
    },
}))(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
}))(MuiAccordionDetails);

const { useContext } = React;

interface ITicketDetailsCancel {
    data: ITicketDetailsModel;
    isExpanded: boolean;
    onExpansionClick(name: keyof IExpandedDetailsBlocks): void;
}

export const TicketDetailsCancel = (props) => {
    const { data, isExpanded, onExpansionClick }: ITicketDetailsCancel = props;
    const { globalSettings, translations, dateTimeFormat, timeZone }: GlobalContextModel = useContext(GlobalContext);
    const classes = useStyles({});

    const { betStatus } = data;

    return (
        <Accordion expanded={isExpanded} data-a="ticket-void-block">
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                onClick={() => onExpansionClick('cancel')}
            >
                <Box className={classes.iconBox}>
                    <CancelOutlinedIcon color="error" />
                </Box>
                <Box className={classes.reprintBox}>
                    <Box display="flex" flexDirection="column" justifyContent="center" flexGrow={1}>
                        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
                            {betStatus === BET_STATUS.VOIDED
                                ? translations['emp-reports-ticket-void-details']
                                : translations['emp-reports-ticket-cancel-details']
                            }
                        </Typography>
                    </Box>
                </Box>
            </AccordionSummary>
            <AccordionDetails
                id={'ticketVoidDetails'}
                className={classes.secondaryDetailsBox}
            >
                <Box padding={3} className={classes.blockDetailsFull}>
                    {betStatus === BET_STATUS.VOIDED &&
                        <div className={classes.mainBlockDetailsBox} data-a="ticket-void-by">
                            <Typography
                                variant="body2"
                                className={classes.mainBlockDetailsName}
                            >
                                {translations['emp-reports-ticket-void-by']}
                            </Typography>
                            <Typography variant="body2" className={classes.mainBlockDetailsDesc}>
                                {
                                    data.canceledInformation?.canceledByEntityName ?? '-'
                                }
                            </Typography>
                        </div>
                    }
                    <div className={classes.mainBlockDetailsBox} data-a="ticket-void-on">
                        <Typography
                            variant="body2"
                            className={classes.mainBlockDetailsName}
                        >
                            {betStatus === BET_STATUS.VOIDED
                                ? translations['emp-reports-ticket-void-on']
                                : translations['emp-reports-ticket-cancel-on']
                            }
                        </Typography>
                        <Typography variant="body2" className={classes.mainBlockDetailsDesc}>
                            {
                                data.canceledInformation?.canceledDateTime
                                    ? formatDateByPattern(
                                        new Date(
                                            formatToIsoDateString(data.canceledInformation.canceledDateTime)),
                                        dateTimeFormat,
                                        timeZone
                                    )
                                    : '-'
                            }
                        </Typography>
                    </div>
                    <div className={classes.mainBlockDetailsBox} data-a="ticket-void-reason">
                        <Typography
                            variant="body2"
                            className={classes.mainBlockDetailsName}
                        >
                            {translations['emp-reports-ticket-reason']}
                        </Typography>
                        <Typography variant="body2" className={classes.mainBlockDetailsDesc}>
                            {
                                data.canceledInformation?.reasonId !== null
                                    ? translations[`ticket-void-reason-${
                                        globalSettings.settings.TicketsReportSettings.CancelReasons.find(
                                            reason => reason.Id === data.canceledInformation.reasonId
                                        )?.Reason
                                    }`]
                                    : '-'
                            }
                        </Typography>
                    </div>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default TicketDetailsCancel;
