import React, { useState } from 'react';
import useBaseStyles from 'pages/Reports/Tickets/TicketsReportTable/TicketsReportTableRow/styles';
import useStyles from './styles';

import { IReportFinancialPerformanceRowModel } from 'api/models/reports';
import {
    IconButton,
    Collapse,
    ListItemText,
    ListItem,
    Grid,
    Typography,
} from '@mui/material';

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import FinancialPerformanceReportTableCellContent from '../../FinancialPerformanceReportTable/FinancialPerformanceReportTableCellContent/FinancialPerformanceReportTableCellContent';
import { generalFinancialDataTranslations } from 'const';
import { translations } from 'context/globalContext';

const FinancialPerformanceReportListItem = ({
    row,
}: {
  row: IReportFinancialPerformanceRowModel;
}) => {
    const baseClasses = useBaseStyles();
    const localClasses = useStyles();
    const classes = { ...baseClasses, ...localClasses };
    const [isOpen, setIsOpen] = useState(false);

    return (
        <ListItem className={classes.listItem}>
            <IconButton
                aria-label="expand list item"
                className={classes.listItemButton}
                onClick={() => setIsOpen(!isOpen)}
                size="large"
            >
                <ListItemText
                    className={classes.listItemText}
                    primary={row.entityName}
                />
                {isOpen ? (
                    <KeyboardArrowUp className={classes.arrowIcon} />
                ) : (
                    <KeyboardArrowDown className={classes.arrowIcon} />
                )}
            </IconButton>
            <Collapse
                className={classes.collapseGrid}
                in={isOpen}
                component="div"
                timeout="auto"
                unmountOnExit
            >
                <Grid container spacing={2}>
                    {row.childrenFinancialData.map((item) => (
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            key={item.entityId}
                            className={classes.gridContainer}
                        >
                            <Typography className={classes.entityText}>
                                {item.entityName}
                            </Typography>
                            <Grid container spacing={2} className={classes.itemGridContainer}>
                                {Object.entries(item)
                                    .slice(4)
                                    .map(([key], index) => (
                                        <React.Fragment key={key}>
                                            <Grid
                                                item
                                                xs={6}
                                                className={
                                                    index % 2 === 0 ? classes.textLeft : classes.textRight
                                                }
                                            >
                                                <Typography
                                                    variant="body1"
                                                    className={classes.summaryText}
                                                >
                                                    {translations[generalFinancialDataTranslations[key]]}
                                                </Typography>
                                                <FinancialPerformanceReportTableCellContent
                                                    id={key}
                                                    row={row}
                                                    className={classes.gridValueText}
                                                />
                                            </Grid>
                                        </React.Fragment>
                                    ))}
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Collapse>
        </ListItem>
    );
};

export default FinancialPerformanceReportListItem;
