import theme from 'theme';

export const commonStyles = () => ({
    root: {
        padding: '1.8rem 1.9rem',
        boxShadow: 'none',
        overflow: 'hidden',
        border: '1px solid #e0e0e0',
        [theme.breakpoints.down('sm')]: {
            border: 'none',
            background: 'transparent',
            padding: '0',
            marginTop: '1.2rem'
        }
    },
    header: {
        fontSize: '1em',
        fontWeight: 500,
        color: '#4e4e4e',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1em',
        }
    },
    headerContainer: {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            padding: '0px'
        }
    },
    subHeader: {
        marginLeft: '0.4rem',
        fontSize: '0.75rem',
        color: '#4e4e4e'
    },
    valueText: {
        peddingLeft: 0,
        marginBottom: '0',
        fontSize: '2em',
        fontWeight: 500,
        [theme.breakpoints.down('sm')]: {
            fontSize: '2em',
        }
    },
    chartTitleWrap: {
        'text-align': 'right',
        [theme.breakpoints.down('sm')]: {
            'text-align': 'left'
        }
    }
});

export const sheetStyles = {
    paper: {
        background: 'black',
        color: '#fff'
    },
};

export const arrowStyles = {
    arrow: { '&::after': { backgroundColor: 'black' } },
};
