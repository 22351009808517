import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) => ({
    approvalDateWrap: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',

        [breakpoints.down('sm')]: {
            marginRight: 'auto',
            minWidth: '150px',
            padding: 0,
        }
    },
    approvalDate: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    entityCell: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '160px',
        padding: spacing(2, 0),

        [breakpoints.down('sm')]: {
            display: 'flex',
            maxWidth: 'initial',
            border: 'none',
            marginTop: spacing(4/8),
            padding: 0,
            whiteSpace: 'initial',

            '&:last-child': {
                '&:empty': {
                    display: 'none',
                },
            }
        },
    },
}));

export default useStyles;
