import * as React from 'react';
import { statusColors } from 'const';

interface IComponentProps {
    status: number;
}

const styles = {
    wrap: {
        display: 'flex',
        alignItems: 'flex-start'
    },
    square: (props: IComponentProps) => ({
        width: '8px',
        minWidth: '8px',
        height: '8px',
        marginTop: '6px',
        marginRight: '8px',
        borderRadius: '1px',
        backgroundColor: statusColors[props.status]
    }),
    name: {
        display: 'flex',
        flexWrap: 'wrap',
        wordWrap: 'break-word'
    },
};

const TerminalStatus = (props: {status: number; statusName: string; transformDate: Date | string; isMobileView: boolean }) => {
    const { status, statusName, transformDate, isMobileView } = props;

    return (
        <div style={styles.wrap}>
            {!isMobileView && <span style={styles.square({ status })} />}
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <span style={{ paddingRight: '6px' }}>{`${statusName}`}</span>
                <span>{transformDate.toString()}</span>
            </div>
        </div>
    );
};

export default TerminalStatus;
