import * as React from 'react';

import { Switch, Route } from 'react-router-dom';
import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import MenuPanel from 'components/MenuMain/MenuPanel';
import Dashboard from 'pages/Dashboard/Dashboard';
import Epos from 'pages/Epos/Epos';
import UserList from 'pages/Users/UserList/UserList';
import AuditLogs from 'pages/Users/AuditLogs/AuditLogs';
import RoleList from 'pages/Roles/RoleList/RoleList';
import EposDetails from 'pages/EposDetails/EposDetails';
import UserAdd from 'pages/Users/UserAdd/UserAdd';
import RoleAdd from 'pages/Roles/RoleAdd/RoleAdd';
import BetshopList from 'pages/Betshops/BetshopList/BetshopList';
import BetshopAdd from 'pages/Betshops/BetshopAdd/BetshopAdd';
import TicketsReport from 'pages/Reports/Tickets/TicketsReport';
import TicketDetails from 'pages/Reports/Tickets/TicketDetails/TicketDetails';
import VoucherDetails from 'pages/Reports/Vouchers/VoucherDetails/VoucherDetails';
import SupervisorReport from 'pages/Reports/Supervisor/SupervisorReport';
import CardTransactionsReport from 'pages/Reports/CardTransactions/CardTransactionsReport';
import FinancialPerformanceReport from 'pages/Reports/FinancialPerfomance/FinancialPerformanceReport';
import VouchersReport from 'pages/Reports/Vouchers/VouchersReport';
import PlayerStatementReport from 'pages/Reports/PlayerStatement/PlayerStatementReport';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import { menuItems } from 'data';

import { initIdleness } from 'utils/userIdleness';
import OddsSheets from 'pages/OddsSheets/OddsSheets';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        height: '100vh',
        [theme.breakpoints.down('sm')]: {
            height: 'calc(100vh - 60px)', //brouser url bar height
            flexDirection: 'column'
        }
    },
    wrap: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100%'
    },
    content: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flexGrow: 1,
        minWidth: 0,
    },
}));

function HomeComponent() {
    const { permissions }: GlobalContextModel = React.useContext(GlobalContext);

    const TagName = menuItems.find(menuItem => permissions[menuItem.permission])?.component || React.Fragment;

    return <TagName />;
}

const Root = () => {
    const classes = useStyles({});
    const { globalSettings, permissions }: GlobalContextModel = React.useContext(GlobalContext);

    const {
        dashboardPermission,
        eposPermission,
        usersViewPermission,
        usersAddPermission,
        rolesViewPermission,
        rolesAddPermission,
        auditLogsPermission,
        networkManagementBetshopsView,
        networkManagementBetshopsAdd,
        reportTicketsView,
        reportSupervisorView,
        reportCardTransactionsView,
        reportVouchersView,
        reportPlayerStatementView,
        reportFinancialPerformanceView,
        oddsSheetsPermission,
    } = permissions;

    React.useEffect(() => {
        globalSettings?.settings?.IdleTimeout && initIdleness(globalSettings.settings.IdleTimeout * 60 * 1000);
    }, [globalSettings]);

    return (
        <div className={classes.root}>
            <MenuPanel />
            <main className={classes.content}>
                <Switch>
                    <Route exact path="/" component={HomeComponent} />
                    {dashboardPermission && <Route exact path="/dashboard" component={Dashboard} />}
                    {eposPermission && <Route exact path="/epos" component={Epos} />}
                    {eposPermission && <Route exact path="/epos/:id" render={(props) => <EposDetails {...props} />} />}
                    {usersViewPermission && <Route exact path="/users" component={UserList} />}
                    <Route exact path="/users/:id" render={(props) => (
                        usersAddPermission && props.match.params.id === 'add' ||
                        usersViewPermission && props.match.params.id !== 'add') &&
                            <UserAdd key={props.location.key} {...props} />}
                    />
                    {rolesViewPermission && <Route exact path="/role-list" component={RoleList} />}
                    {rolesAddPermission && <Route exact path="/role-add" component={RoleAdd} />}
                    {rolesViewPermission && <Route exact path="/role-edit" component={RoleAdd} />}
                    {auditLogsPermission && <Route exact path="/audit-logs" component={AuditLogs} />}
                    {networkManagementBetshopsView && <Route exact path="/betshops" component={BetshopList} />}
                    <Route exact path="/betshops/:id" render={(props) => (
                        networkManagementBetshopsAdd && props.match.params.id === 'add' ||
                        networkManagementBetshopsView && !isNaN(Number(props.match.params.id))) &&
                            <BetshopAdd key={props.location.key} {...props} />}
                    />
                    {reportTicketsView && <Route exact path="/reports/tickets" component={TicketsReport} />}
                    {reportTicketsView && <Route exact path="/reports" component={TicketsReport} />}
                    {reportTicketsView && <Route exact path="/reports/tickets/:id" render={(props) => <TicketDetails {...props} />} />}
                    {reportCardTransactionsView && <Route exact path="/reports/card-transactions" component={CardTransactionsReport} />}
                    {reportFinancialPerformanceView && <Route exact path="/reports/financial-performance" component={FinancialPerformanceReport} />}
                    {reportSupervisorView && <Route exact path="/reports/supervisor" component={SupervisorReport} />}
                    {reportVouchersView && <Route exact path="/reports/vouchers" component={VouchersReport} />}
                    {reportVouchersView && <Route exact path="/reports/vouchers/:id" render={(props) => <VoucherDetails {...props} />} />}
                    {reportPlayerStatementView && <Route exact path="/reports/player-statement" component={PlayerStatementReport} />}
                    {oddsSheetsPermission && <Route exact path="/odds-sheets" component={OddsSheets} />}
                </Switch>
            </main>
        </div>
    );
};

export default Root;
