import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';

import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import { Box, Button, ButtonGroup, Drawer, Divider, Typography, FormControlLabel, Checkbox, Tooltip } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';

import {
    filterListOpenExpansions,
    EPOS_FILTERS,
    EPOS_FILTERS_TITLES,
    EPOS_FILTERS_TRANLATION_KEY,
    IFilterListItem,
    IFilterList
} from 'const';

import { FiltersBox } from './FiltersBox/FiltersBox';
import FiltersWithSearch from './FiltersWithSearch/FiltersWithSearch';

import InfoOutlined from '@mui/icons-material/InfoOutlined';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import { useWidth } from 'utils/customHooks';

const searchListInitial = {
    [EPOS_FILTERS.location]: { text: '', isFieldOpen: false }
};

const ControlPanelPadding = 8;

export const useStyles = makeStyles((theme: Theme) => ({
    titleWrap: {
        padding: `${theme.spacing(2)} ${theme.spacing(0.5)} ${theme.spacing(2)} ${theme.spacing(2)}`
    },
    toggleFiltersButtonGroup: {
        borderRadius: '4px',
        textTransform: 'none',
        backgroundColor: `${theme.palette.grey[100]}`,
    },
    toggleFiltersButtonGroupActive: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.light
    },
    toggleFiltersButton: {
        textTransform: 'none',
        whiteSpace: 'nowrap',
        '& > .MuiButton-label': {
            lineHeight: 0.85,
            whiteSpace: 'nowrap'
        }
    },
    smallButton: {
        position: 'relative',
        zIndex: 2,
        minWidth: theme.spacing(5),
        padding: `5px ${theme.spacing(1)}`
    },
    drawerRoot: {
        '& > .MuiBackdrop-root': {
            backgroundColor: 'transparent'
        }
    },
    drawerWrap: {
        width: '350px',

        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    drawer: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column'
    },
    filtersWrap: {
        flexGrow: 1,
        overflowX: 'hidden',
        overflowY: 'auto',
    },
    drawerBox: {
        padding: theme.spacing(2)
    },
    drawerCloseButton: {
        padding: '6px',
        minWidth: theme.spacing(5),
        borderRadius: '50%'
    },
    controlPanel: {
        padding: theme.spacing(1, 2, 2)
    },
    buttonFullWidth: {
        width: '100%',
        marginBottom: `${ControlPanelPadding}px`,
        backgroundColor: `${theme.palette.text.primary}`,
        '&:hover': {
            backgroundColor: `${theme.palette.text.secondary}`,
        }
    },
    buttonHalfWidth: {
        width: `calc(50% - ${ControlPanelPadding / 2}px)`,
    },
    buttonClear: {
        width: '100%'
    },
    icon: {
        position: 'relative',
        zIndex: 1
    }
}));

export const FilterButton = ((props: {
    filterQty: number;
    handleOpenDrawer: () => void;
}) => {
    const classes = useStyles({});
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);
    const { filterQty, handleOpenDrawer } = props;
    const isMobileView = useWidth() === 'xs';

    const filterButtonName = filterQty
        ? filterQty === 1
            ? translations['hm-filter-1-filter-applied']
            : `${filterQty} ${translations['hm-filter-x-filters-applied']}`
        : translations['hm-filter-filters'] || '';

    return (
        <ButtonGroup
            variant="outlined"
            className={clsx(classes.toggleFiltersButtonGroup, filterQty && classes.toggleFiltersButtonGroupActive)}
            aria-label="split button"
        >
            <Button
                className={clsx(classes.toggleFiltersButton, isMobileView && classes.smallButton)}
                onClick={handleOpenDrawer}
                data-a="filters-button"
            >
                {isMobileView
                    ? <FilterListIcon className={classes.icon} />
                    : filterButtonName
                }
            </Button>
        </ButtonGroup>
    );
});

export const Header = ({ onCloseClick }) => {
    const classes = useStyles({});
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    return (
        <>
            <div className={classes.titleWrap}>
                <Box display="flex" justifyContent="space-between">
                    <Typography variant="h5">{translations['hm-filter-filters']}</Typography>
                    <Button className={classes.drawerCloseButton} size="small" onClick={onCloseClick} data-a="filters-close-button">
                        <CloseIcon />
                    </Button>
                </Box>
            </div>
            <Divider />
        </>
    );
};

export const ControlPanel = ({
    onApplyFilters, onClearFilters, disabled = false, isSaveFilters = false, onSaveFilters
}: {
    onApplyFilters: () => void;
    onClearFilters: () => void;
    disabled?: boolean;
    isSaveFilters?: boolean;
    onSaveFilters?: () => void;
}) => {
    const classes = useStyles({});
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    useEffect(() => {
        const applyButton = document.getElementById('applyFiltersButton');
        const clearButton = document.getElementById('clearFiltersButton');

        const handleButtonClick = () => {
            window.dispatchEvent(new CustomEvent('buttonClicked'));
        };

        if (applyButton && clearButton) {
            applyButton.addEventListener('click', handleButtonClick);
            clearButton.addEventListener('click', handleButtonClick);
        }

        return () => {
            if (applyButton && clearButton) {
                applyButton.removeEventListener('click', handleButtonClick);
                clearButton.removeEventListener('click', handleButtonClick);
            }
        };
    }, []);

    return (
        <>
            <Divider />
            <div className={classes.controlPanel}>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isSaveFilters}
                                onChange={onSaveFilters}
                                name="save-filters"
                                color="primary"
                            />
                        }
                        label={translations['gen-save-filters']}
                    />
                    <Tooltip
                        title={translations['emp-filters-save-tooltip']}
                        data-a="emp-filters-save-tooltip"
                        placement="top-end"
                        sx={{ cursor: 'pointer' }}
                    >
                        <InfoOutlined fontSize="small" data-a="emp-filters-save-tooltip-icon" />
                    </Tooltip>
                </Box>
                <Box>
                    <Button
                        disabled={disabled}
                        className={classes.buttonFullWidth}
                        variant="contained"
                        color="primary"
                        onClick={onApplyFilters}
                        data-a="filters-apply-button"
                        id="applyFiltersButton"
                    >
                        {translations['gen-apply']}
                    </Button>
                </Box>
                <Box display="flex" justifyContent="space-between">
                    <Button
                        className={classes.buttonClear}
                        variant="outlined"
                        color="inherit"
                        onClick={onClearFilters}
                        data-a="filters-clear-button"
                        id="clearFiltersButton"
                    >
                        {translations['gen-clear']}
                    </Button>
                </Box>
            </div>
        </>
    );
};

const TerminalFilters = function (props: {
    isMobileView: boolean;
    filterQty: number;
    filterList: IFilterList;
    activeFilterIds: IFilterList;
    appliedActiveFilters: IFilterList;
    isSaveFilters: boolean;
    handleFilter: (key: string, item: IFilterListItem) => void;
    handleAppliedFilters: () => void;
    handleClearFilters: () => void;
    handleClearActiveFilters: () => void;
    handleSaveFilters: () => void;
}) {
    const classes = useStyles({});
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const [openExpansions, setOpenExpansions] = useState({});
    const [searchInFiltersList, setSearchInFiltersList] = useState(searchListInitial);

    const filtersWrapperRef = useRef<HTMLDivElement>();

    const {
        filterQty,
        filterList,
        handleFilter,
        activeFilterIds,
        appliedActiveFilters,
        isSaveFilters,
        handleSaveFilters,
        handleAppliedFilters,
        handleClearFilters,
        handleClearActiveFilters
    } = props;

    useEffect(() => {
        setOpenExpansions({ ...checkOpenExtension(appliedActiveFilters) });
    }, [isOpenDrawer]);

    const handleOpenDrawer = () => {
        setIsOpenDrawer(true);
    };

    const handleCloseDrawer = () => {
        setOpenExpansions({ ...checkOpenExtension(appliedActiveFilters) });
        setIsOpenDrawer(false);
        handleClearActiveFilters();
    };

    const handleFilterClick = (label, item) => {
        handleFilter(label, item);
    };

    const handleApplyFilters = () => {
        setOpenExpansions({ ...checkOpenExtension(appliedActiveFilters) });
        setIsOpenDrawer(false);
        setSearchInFiltersList({ ...searchListInitial });
        handleAppliedFilters();
    };

    const onClearFilters = () => {
        setOpenExpansions({ ...filterListOpenExpansions });
        handleClearFilters();
        setSearchInFiltersList({ ...searchListInitial });
    };

    const handleSearchInFilters = (key: string, text: string, isFieldOpen: boolean) => {
        const newValue = { text, isFieldOpen };

        setSearchInFiltersList({ ...searchInFiltersList, [key]: newValue });
    };

    const handleExpanded = (key: string) => {
        setOpenExpansions({ ...openExpansions, [key]: !openExpansions[key] });
    };

    const checkOpenExtension = (activeFilterIds) => {
        const newOpenExpansions = {};

        for (let key in activeFilterIds) {
            if (activeFilterIds.hasOwnProperty(key)) {
                newOpenExpansions[key] = !!activeFilterIds[key].length;
            }
        }

        return newOpenExpansions;
    };

    return (
        <React.Fragment>
            <FilterButton
                filterQty={filterQty}
                handleOpenDrawer={handleOpenDrawer}
            />
            <Drawer
                className={classes.drawerWrap}
                classes={{
                    root: classes.drawerRoot,
                    paper: classes.drawerWrap
                }}
                anchor="right"
                open={isOpenDrawer}
                onClose={handleCloseDrawer}
            >
                <div data-a="terminal-filters" className={classes.drawer}>
                    <Header onCloseClick={handleCloseDrawer} />
                    <div className={classes.filtersWrap}
                        ref={filtersWrapperRef}
                    >
                        {
                            filterList?.[EPOS_FILTERS.location]?.length
                                ? (
                                    <FiltersWithSearch
                                        label={EPOS_FILTERS.location}
                                        title={EPOS_FILTERS_TITLES.location}
                                        expanded={openExpansions[EPOS_FILTERS.location]}
                                        data={filterList[EPOS_FILTERS.location]?.sort((a, b) => a.name.localeCompare(b.name))}
                                        activeFilterIds={activeFilterIds[EPOS_FILTERS.location]}
                                        appliedActiveFilters={appliedActiveFilters[EPOS_FILTERS.location]}
                                        handleFilterClick={handleFilterClick}
                                        handleExpanded={handleExpanded}
                                        handleSearchInFilters={handleSearchInFilters}
                                        searchText={searchInFiltersList[EPOS_FILTERS.location].text}
                                        isSearchFieldShown={searchInFiltersList[EPOS_FILTERS.location].isFieldOpen}
                                        scrollElement={filtersWrapperRef.current}
                                    />)
                                : null
                        }
                        {
                            Object.keys(EPOS_FILTERS).map(key => {
                                if (key !== EPOS_FILTERS.location && filterList[key]?.length) {
                                    return (
                                        <FiltersBox
                                            key={key}
                                            label={key}
                                            title={EPOS_FILTERS_TITLES[key]}
                                            data={filterList[key]?.sort((a, b) => a.name.localeCompare(b.name))}
                                            activeFilterIds={activeFilterIds}
                                            handleFilterClick={handleFilterClick}
                                            expanded={openExpansions[key]}
                                            handleExpanded={handleExpanded}
                                            translationKey={EPOS_FILTERS_TRANLATION_KEY?.[key] || ''}
                                        />
                                    );
                                }
                            })
                        }
                    </div>
                    <ControlPanel
                        onApplyFilters={handleApplyFilters}
                        onClearFilters={onClearFilters}
                        onSaveFilters={handleSaveFilters}
                        isSaveFilters={isSaveFilters}
                    />
                </div>
            </Drawer>
        </React.Fragment>
    );
};

export default React.memo(TerminalFilters);
