import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>({
    modalBox: {
        padding: `0 ${theme.spacing(3)}`,
        marginBottom: `${theme.spacing(2)}`,
    },
    dialogContent: {
        paddingTop: 0,
        overflowY: 'initial',
    },
    imgTitleWrap: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    title: {
        margin: 0,
        paddingBottom: theme.spacing(1),
        fontSize: '1rem',
        lineHeight: 1,
        fontWeight: 500
    },
    titleDescription: {
        margin: 0,
        color: theme.palette.text.secondary,
        fontWeight: 400,
        fontSize: '0.875rem'
    },
    button: {
        minWidth: 'auto',
        textTransform: 'capitalize',
        whiteSpace: 'nowrap',
        lineHeight: 1,

        '&:hover': {
            backgroundColor: 'transparent',
            shadow: 'none'
        }
    },
    uploadButton: {
        padding: theme.spacing(1, 2)
    },
    removeButton: {
        color: theme.palette.primary.main,
        padding: theme.spacing(1, 0)
    },
    imagesWrap: {
        width: '100%',
        display: 'grid',
        gridColumnGap: theme.spacing(1),
        gridRowGap: theme.spacing(1),
        gridTemplateColumns: 'repeat(4, 1fr)',
        marginTop: theme.spacing(2)
    },
    imagesDisabled: {
        padding: theme.spacing(0, 3, 3, 3)
    },
    imageWrap: {
        width: '100%',
        paddingTop: '80%',
        display: 'flex',
        alignItems: 'center',
        backgroundSize: 'contain',
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.down('xs')]: {
            backgroundSize: 'cover'
        }
    },
    image: {
        width: '100%',
        cursor: 'pointer',
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: '2px'
    },
    loadingImg: {
        position: 'relative',
        paddingTop: '80%',
        backgroundColor: `${theme.palette.grey[100]}`,
        border: 'none',
    },
    loadingDots: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        backgroundColor: theme.palette.grey[200],
        animation: 'dot-flashing 1s infinite linear alternate',
        animationDelay: '.5s',
        '&::before': {
            content: '\'\'',
            display: 'block',
            position: 'absolute',
            left: '-15px',
            top: 0,
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            backgroundColor: theme.palette.grey[200],
            animation: 'dot-flashing 1s infinite linear alternate',
            animationDelay: '0s',
        },
        '&::after': {
            content: '\'\'',
            display: 'block',
            position: 'absolute',
            right: '-15px',
            top: 0,
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            backgroundColor: theme.palette.grey[200],
            animation: 'dot-flashing 1s infinite linear alternate',
            animationDelay: '1s',
        },
    },
    loadingText: {
        margin: 0,
        padding: theme.spacing(1, 0),
        color: theme.palette.text.secondary,
        fontSize: '0.8125rem',
        fontWeight: 500
    },
    imageActive: {
        borderColor: `${theme.palette.primary.main}`
    },
    inputWrap: {
        '& .Mui-focused': {
            color: `${theme.palette.primary.main}`,
        }
    },
    errrorBox: {
        minHeight: '18px',
    },
    errorMessage: {
        padding: theme.spacing(1, 0),
        fontSize: '0.75rem'
    },
    checkboxRow: {
        marginTop: theme.spacing(2)
    },
    inputWrapTimeout: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(2)
    },
    timeoutInput: {
        width: '55px',
        marginRight: theme.spacing(2),
        '& input': {
            color: theme.palette.common.black,
            textAlign: 'right',
        }
    }
})
);

export default useStyles;
