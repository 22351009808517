import * as React from 'react';
import { Box, Grid, Typography, List, ListItem } from '@mui/material';

import useStyles from './styles';

import { METRIC_TYPES } from 'const';
import { formatNumberToCurrencyString, numberStyles } from 'utils/formatNumber';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

interface ITerminalListItem {
    headerString?: string;
    lines: Object;
    classes: {
        infoName: string;
        infoValue: string;
    };
    lastItem?: boolean;
}

const getTerminalData = (eposItem, additionalData) => {
    const metrics = {
        networkMetrics: additionalData?.metrics?.[METRIC_TYPES.NETWORK],
        shellMetrics: additionalData?.metrics?.[METRIC_TYPES.SHELL]
    };

    const { networkMetrics, shellMetrics } = metrics;

    return {
        location: eposItem.location,
        cash: additionalData?.cashBalance,
        balance: additionalData?.balance,
        network: {
            ip: networkMetrics?.properties?.MainIp,
            vpn: networkMetrics?.properties?.VpnIp
        },
        shell: {
            os: shellMetrics?.properties?.OperatingSystem,
            version: shellMetrics?.properties?.Version
        }
    };
};

const TerminalListItem = (props: ITerminalListItem) => {
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    return (
        <ListItem divider={!props.lastItem}>
            <Box paddingLeft={1} paddingRight={1} width="100%">
                <Grid container>
                    {props.headerString
                        ? (
                            <Grid item xs={12}>
                                <Typography gutterBottom variant="h6">{translations[props.headerString]}</Typography>
                            </Grid>
                        )
                        : null
                    }
                    {props.lines
                        ? Object.keys(props.lines).map((key) => (
                            <React.Fragment key={key}>
                                <Grid item xs={6}>
                                    <Typography className={props.classes.infoName}>{translations[key]}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography className={props.classes.infoValue}>
                                        {props.lines[key] === undefined ? '-' : props.lines[key]}
                                    </Typography>
                                </Grid>
                            </React.Fragment>
                        ))
                        : null
                    }
                </Grid>
            </Box>
        </ListItem>
    );
};

const TerminalInformation = ({ additionalData, eposItem }) => {
    const { globalSettings }: GlobalContextModel = React.useContext(GlobalContext);
    const terminalData = getTerminalData(eposItem, additionalData);
    const classes = useStyles({});

    const shellLines = {
        'gen-betshop': terminalData.location,
        'gen-os': terminalData?.shell?.os,
    };

    if (eposItem?.linuxPatchVersion) {
        shellLines['gen-linux-patch-version'] = eposItem?.linuxPatchVersion;
    }

    shellLines['gen-shell'] = terminalData?.shell?.version;

    return (
        <Box marginBottom={3} border="solid 1px #e0e0e0" bgcolor="#fff" borderRadius="4px">
            <List style={{ paddingBottom: 0 }}>
                <TerminalListItem
                    headerString="hmd-terminal-information"
                    lines={{
                        'hmd-terminal-info-cash':
                            terminalData.cash === undefined
                                ? terminalData.cash
                                : formatNumberToCurrencyString(terminalData.cash,
                                    numberStyles(globalSettings?.settings?.Currency),
                                    globalSettings?.settings?.CurrencySign,
                                    globalSettings?.settings?.CurrencyFormat
                                ),
                        'hmd-terminal-info-balance':
                            terminalData.balance === undefined
                                ? terminalData.balance
                                : formatNumberToCurrencyString(terminalData.balance,
                                    numberStyles(globalSettings?.settings?.Currency),
                                    globalSettings?.settings?.CurrencySign,
                                    globalSettings?.settings?.CurrencyFormat
                                )
                    }}
                    classes={classes}
                />
                <TerminalListItem
                    lines={{
                        'hmd-terminal-info-ip-address': terminalData?.network?.ip,
                        'hmd-terminal-info-vpn': terminalData?.network?.vpn
                    }}
                    classes={classes}
                />
                <TerminalListItem
                    lines={shellLines}
                    classes={classes}
                    lastItem
                />
            </List>
        </Box>
    );
};

export default TerminalInformation;
