import React from 'react';
import { Order, ORDER_BY_FINANCIAL_PERFORMANCE_COLUMN, rowsPerPageOptions } from 'const';
import {
    Box,
    Table,
    TableBody,
    TablePagination,
} from '@mui/material';

import { IReportFinancialPerformanceRowModel } from 'api/models/reports';

import TablePaginationActions from 'components/TablePaginationActions/TablePaginationActions';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';
import FinancialPerformanceReportTableRow from './FinancialPerformanceReportTableRow/FinancialPerformanceReportTableRow';
import FinancialPerformanceReportTableHead from './FinancialPerformanceReportTableHead/FinancialPerformanceReportTableHead';
import useStyles from './styles';
import formatStr from 'utils/formatStr';

const FinancialPerformanceReportTable = ({
    order,
    orderByColumn,
    page,
    rows,
    hideSorting,
    rowsPerPage,
    handleSort,
    handleChangePage,
    handleChangeRowsPerPage
}: {
    order: Order;
    orderByColumn: ORDER_BY_FINANCIAL_PERFORMANCE_COLUMN;
    page: number;
    rows: Array<IReportFinancialPerformanceRowModel>;
    hideSorting?: boolean;
    rowsPerPage: number;
    handleSort: (event: React.MouseEvent<HTMLElement>, orderByColumn: ORDER_BY_FINANCIAL_PERFORMANCE_COLUMN) => void;
    handleChangePage: (event: React.MouseEvent<HTMLElement>, newPage: number) => void;
    handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    const pageNum = Math.min(Math.ceil(rows.length / rowsPerPage), page);

    const classes = useStyles({});

    return (
        <>
            <Box className={classes.tableWrapper}>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    data-a="financial-performance-report-table"
                >
                    <FinancialPerformanceReportTableHead
                        classes={classes}
                        order={order}
                        onSort={handleSort}
                        hideSorting={hideSorting}
                        orderByColumn={orderByColumn}
                    />
                    <TableBody>
                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                            <FinancialPerformanceReportTableRow
                                key={row.entityName}
                                row={row}
                            />
                        ))}
                    </TableBody>
                </Table>
            </Box>
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={pageNum}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={translations['hm-table-rows-per-page']}
                labelDisplayedRows={(paginationInfo) => {
                    return `${formatStr(translations['hm-table-pagination-displayed-rows'], { 'from': paginationInfo.from, 'to': paginationInfo.to, 'count': paginationInfo.count })}`;
                }}
                ActionsComponent={(props) => <TablePaginationActions {...props} onChangePage={handleChangePage} />}
            />
        </>);
};

export default FinancialPerformanceReportTable;
