import * as React from 'react';
import clsx from 'clsx';
import { uid } from 'react-uid';

import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { Paper, IconButton, TextField, InputAdornment } from '@mui/material';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import Downshift from 'downshift';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { IconButtonProps } from '@mui/material/IconButton';
import { IEposListSearchModel } from 'api/models/epos';
import { IUserSearchModel } from 'api/models/user';
import { ILogSearchModel } from 'api/models/auditlog';
import { IBetshopSearchModel } from 'api/models/network-management';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';
import { transitionDuration } from 'const';
import { overrideStyles } from 'utils/styleExtension';
import { isSearchValueAcceptable } from './business';

import { useWidth } from 'utils/customHooks';

const { useState, useRef } = React;

const maxSuggestions = 10;
const searchBorderWidth = 2;

const useStyles = makeStyles((theme: Theme) => ({
    wrap: {
        position: 'relative',
        width: '100%',
        backgroundColor: '#fff',

        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: 0,
            transitionProperty: 'height',
            transitionDuration
        },
        '& fieldset': {
            [theme.breakpoints.down('sm')]: {
                transitionProperty: 'opacity',
                opacity: 0,
                transitionDuration
            }
        }
    },
    wrapOpen: {
        height: theme.spacing(4.5),
        marginBottom: theme.spacing(2),

        '& fieldset': {
            [theme.breakpoints.down('sm')]: {
                height: theme.spacing(5),
                opacity: 1
            }
        }
    },
    searchField: {
        width: '100%',
    },
    searchInput: {
        '& input': {
            padding: `${theme.spacing(1)} 0`,
            transitionProperty: 'height, padding',
            transitionDuration,

            '&::placeholder': {
                [theme.breakpoints.down('sm')]: {
                    color: 'transparent'
                }
            },

            [theme.breakpoints.down('sm')]: {
                height: 0,
                padding: 0
            }
        },
        '& fieldset': {
            transitionProperty: 'height',
            transitionDuration,
            [theme.breakpoints.down('sm')]: {
                height: 0,
                padding: 0,
                overflow: 'hidden'
            }
        }
    },
    searchInputOpen: {
        '& input': {
            '&::placeholder': {
                [theme.breakpoints.down('sm')]: {
                    color: 'inherit'
                }
            },
            [theme.breakpoints.down('sm')]: {
                height: theme.spacing(2.5),
                padding: `${theme.spacing(1)} 0`,
                transitionProperty: 'height, padding',
                transitionDuration,
            }
        }
    },
    inputAdornment: {
        height: 0
    },
    searchIcon: {
        position: 'absolute',
        right: '0',
        top: theme.spacing(-6.5)
    },
    closeInputButton: {
        textTransform: 'capitalize'
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        width: `calc(100% - ${2 * searchBorderWidth}px)`,
        [theme.breakpoints.down('sm')]: {
            width: `calc(100% - ${theme.spacing(3.5)})`,
            right: '14px', // 16px - 2px for including border
        }
    },
    suggestionItem: {
        width: '100%',
        fontSize: '0.875rem',
        minHeight: theme.spacing(5),
        '& > span': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        }
    },
    hide: {
        display: 'none'
    }
}));

const StyledCloseButton = withStyles({
    root: {
        padding: '6px'
    }
})((props: IconButtonProps) => (<IconButton aria-label="close" {...props} size="large"><CloseIcon /></IconButton>));

interface IRenderSuggestionProps {
    highlightedIndex: number | null;
    index: number;
    itemProps: MenuItemProps<'div', { button?: never }>;
    selectedItem: string;
    suggestion: string;
    inputValue: string;
}

function SearchFieldWithSuggest(
    props: {
        data: Array<IEposListSearchModel | IUserSearchModel | ILogSearchModel | IBetshopSearchModel>;
        onTextChange(arg0?: string): void;
        searchValue: string;
        externalStyles?: Object;
}
) {
    const { onTextChange, data, externalStyles={}, searchValue = '' } = props;

    const classesBase = useStyles({});
    const classes = overrideStyles(classesBase, externalStyles);

    const isMobileView = useWidth() === 'xs';

    const [isMobileSearchOpen, setIsMobileSearchOpen] = useState<boolean>(false);

    const inputWrapRef = useRef(null);
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    function getSuggestions(value: string) {
        const inputValue = value.trim().toLowerCase();

        if (inputValue.length <= 0) { return []; }

        return data.reduce((res, epos) => {
            Object.keys(epos).map((prop) => {
                let val = epos[prop];

                if (isSearchValueAcceptable([val], inputValue)) {
                    res.push(val);
                }
            });

            return res;
        }, [])
            .filter((suggestion, index, self) => self.indexOf(suggestion) === index)
            .slice(0, maxSuggestions - 1);
    }

    function renderSuggestion(suggestionProps: IRenderSuggestionProps) {
        const { suggestion, index,
            itemProps,
            highlightedIndex, selectedItem, inputValue } = suggestionProps;
        const isHighlighted = highlightedIndex === index;
        const isSelected = (selectedItem || '').indexOf(suggestion) > -1;

        const matchedInputValue = inputValue.split('').map(e => /[A-Za-z0-9]/.test(e) ? e : '\\' + e).join('');
        const splittedStr = String(suggestion).split(new RegExp(`(${matchedInputValue})`, 'gi'));

        const formattedStr = (
            <span>
                {splittedStr.map((s, i) => s === inputValue ? <b key={i}>{s}</b> : s)}
            </span>);

        return (
            <MenuItem
                {...itemProps}
                className={classes.suggestionItem}
                key={uid(suggestion, index)}
                selected={isHighlighted}
                component="div"
                style={{
                    fontWeight: isSelected ? 500 : 400,
                }}
            >
                {formattedStr}
            </MenuItem>
        );
    }

    function stateReducer(state, changes) {
        switch (changes.type) {
            case Downshift.stateChangeTypes.mouseUp:
                return changes.isOpen ? changes : { ...changes, inputValue: state.inputValue };
            case Downshift.stateChangeTypes.blurInput:
            case Downshift.stateChangeTypes.touchEnd:
                return { ...changes, inputValue: state.inputValue };
            case Downshift.stateChangeTypes.clickItem:
                inputWrapRef.current.querySelector('input').blur();

                return changes;
            default:
                return changes;
        }
    }

    function onSearchToggle() {
        isMobileView && isMobileSearchOpen && onTextChange('');
        isMobileView && setIsMobileSearchOpen(!isMobileSearchOpen);
    }

    return (
        <Downshift
            stateReducer={stateReducer}
            onChange={(selection) => (onTextChange?.(selection || ''))}
            onInputValueChange={(inputValue: string) => {
                if (!onTextChange) { return; }

                onTextChange(inputValue);
            }}
            initialInputValue={searchValue}
            inputValue={searchValue}
        >
            {({
                clearSelection,
                getInputProps,
                getItemProps,
                highlightedIndex,
                inputValue,
                isOpen,
                openMenu,
                toggleMenu,
                selectedItem
            }) => {
                const { onBlur, onFocus, onClearText, onChange, onKeyDown } = getInputProps({
                    onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
                        if (event.key === 'Enter') {
                            event.preventDefault();
                            event.currentTarget.blur();
                            toggleMenu();
                        } else
                        if (!isOpen) {
                            openMenu();
                        }
                    },
                    onChange: () => null,
                    onFocus: openMenu,
                    onClearText: () => {
                        clearSelection();
                    }
                });

                return (
                    <div className={clsx(classes.wrap, isMobileSearchOpen && classes.wrapOpen)}>
                        <TextField
                            ref={inputWrapRef}
                            className={classes.searchField}
                            placeholder={translations['hm-search']}
                            id="epos-text-search"
                            value={inputValue}
                            variant="outlined"
                            autoComplete="off"
                            color="primary"
                            InputProps={{
                                onBlur,
                                onChange,
                                onFocus,
                                onKeyDown,
                                className: isMobileSearchOpen ? classes.searchInputOpen : classes.searchInput,
                                startAdornment: (
                                    <InputAdornment
                                        position="start"
                                        className={classes.inputAdornment}
                                    >
                                        {isMobileSearchOpen
                                            ? (
                                                <span
                                                    className={clsx(classes.searchIcon, classes.closeInputButton)}
                                                    onClick={onSearchToggle}
                                                >
                                                    {translations['cancel']}
                                                </span>)
                                            : (
                                                <SearchIcon classes={isMobileView && { root: classes.searchIcon } || {}} onClick={onSearchToggle} />)}
                                    </InputAdornment>),
                                endAdornment: inputValue &&
                                    <InputAdornment position="end">
                                        <StyledCloseButton onClick={onClearText} className={clsx(isMobileView && !isMobileSearchOpen && classes.hide)} />
                                    </InputAdornment>
                            }}
                        />
                        <div>
                            {isOpen
                                ? (
                                    <Paper className={classes.paper} square>
                                        {getSuggestions(inputValue).map((suggestion, index) =>
                                            renderSuggestion({
                                                suggestion,
                                                index,
                                                itemProps: getItemProps({ item: suggestion }),
                                                highlightedIndex,
                                                selectedItem,
                                                inputValue
                                            })
                                        )}
                                    </Paper>
                                ) : null}
                        </div>
                    </div>
                );
            }}
        </Downshift>
    );
}

export default SearchFieldWithSuggest;
