import * as React from 'react';

import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';
import { Checkbox, FormControlLabel } from '@mui/material/';

export const useStyles = makeStyles((theme: Theme) => ({
    button: {
        display: 'flex',
        margin: 0,
        padding: `5px ${theme.spacing(2)}`,
        borderRadius: '4px 0 0 4px',
        border: `1px solid ${theme.palette.grey[400]}`,
        borderRight: 'none',
        textTransform: 'none',
        backgroundColor: `${theme.palette.grey[100]}`,
    },
    buttonPadded: {
        display: 'flex',
        padding: `5px ${theme.spacing(2)}`,
        border: `1px solid ${theme.palette.grey[400]}`,
        marginLeft: 0,
        marginRight: theme.spacing(1),
        borderRadius: '4px',
        textTransform: 'none',
        backgroundColor: `${theme.palette.grey[100]}`,
    },
    checkbox: {
        padding: 0,
        marginRight: theme.spacing(1)
    },
    label: {
        fontSize: '0.875rem',
        lineHeight: 0.85,
        fontWeight: 500,
        whiteSpace: 'nowrap'
    }
}));

function SelectedQuantity(props: {
    selectedIds: Array<number>;
    rowsCount: number;
    handleSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    roundBorder: boolean;
}) {
    const { selectedIds, rowsCount, handleSelectAllClick, roundBorder } = props;
    const classes = useStyles({});
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    return (
        <FormControlLabel
            className={selectedIds.length > 0 && selectedIds.length <= rowsCount && roundBorder ? classes.button : classes.buttonPadded}
            control={
                <Checkbox
                    className={classes.checkbox}
                    color="primary"
                    name="checkbox-all"
                    indeterminate={selectedIds && selectedIds.length > 0 && selectedIds.length < rowsCount}
                    checked={Boolean(selectedIds.length) && selectedIds.length === rowsCount}
                    onChange={handleSelectAllClick}
                    inputProps={{ 'aria-label': 'select all' }}
                />}
            label={
                <span className={classes.label}>
                    {selectedIds?.length
                        ? `${selectedIds.length} ${translations['hm-selected']}`
                        : translations['hm-select-all']}
                </span>
            }
        />
    );
}

export default React.memo(SelectedQuantity);
