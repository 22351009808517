import * as React from 'react';
import { unsubscribe as unsubscribeToMqtt } from 'api/mqttClient';

import { EXCEL_EXPORT_ERROR_ENUM, REPORTS_NAMES } from 'const';
import BaseApi from 'api/services/_BaseApi';
import { EmpEndpoint } from 'api/Endpoint';

import { IInitialSettingsModel } from 'api/models/general';

declare const initialSettings: IInitialSettingsModel;

const { EmpBffUrl: EmpBffUrl } = initialSettings;

interface IResponse {
    Percentage: number;
    IsCompleted: boolean;
    IsFailed: boolean;
    ReportId: string;
    ErrorCode: EXCEL_EXPORT_ERROR_ENUM | null;
}
interface IReportsItem {
    percentage: number;
    isLoading: boolean;
    isFailed: boolean;
    errorCode: 1 | 2 | null;
}
type IState = {
    [key in REPORTS_NAMES]: IReportsItem;
}

const initialStateItem: IReportsItem = {
    percentage: 0,
    isLoading: false,
    isFailed: false,
    errorCode: null
};

const initialState = {
    [REPORTS_NAMES.cardTransactions]: { ...initialStateItem },
    [REPORTS_NAMES.supervisor]: { ...initialStateItem },
    [REPORTS_NAMES.tickets]: { ...initialStateItem },
    [REPORTS_NAMES.vouchers]: { ...initialStateItem },
    [REPORTS_NAMES.playerStatement]: { ...initialStateItem },
    [REPORTS_NAMES.financialPerformance]: { ...initialStateItem }
};

export let state: IState, setState;

let timer;
const time = 30000;

const startTimer = (topic) => {
    return setTimeout(() => {
        unsubscribeToMqtt(topic);
        setState({
            [REPORTS_NAMES.cardTransactions]: { ...initialStateItem },
            [REPORTS_NAMES.supervisor]: { ...initialStateItem },
            [REPORTS_NAMES.tickets]: { ...initialStateItem },
            [REPORTS_NAMES.vouchers]: { ...initialStateItem },
            [REPORTS_NAMES.playerStatement]: { ...initialStateItem },
            [REPORTS_NAMES.financialPerformance]: { ...initialStateItem }
        });
    }, time);
};

const stopTimer = () => {
    timer && clearInterval(timer);
};
const resetTimer = (topic) => {
    stopTimer();
    timer = startTimer(topic);
};
let previusResponse ='';

export const onMessageExcelHandler = async (response: string, reportId: string, topic: string, name: REPORTS_NAMES) => {
    if (response === previusResponse) {
        return;
    }

    previusResponse = response;

    const data: IResponse = JSON.parse(response);

    if (data.ReportId === reportId) {
        if (!timer) {
            timer = startTimer(topic);
        } else {
            resetTimer(topic);
        }

        if (data.IsFailed) {
            unsubscribeToMqtt(topic);
            setState({
                ...state,
                [name]: {
                    percentage: 0,
                    isLoading: false,
                    isFailed: true,
                    errorCode: data.ErrorCode
                }
            });
        } else {
            if (data.IsCompleted) {
                unsubscribeToMqtt(topic);
                stopTimer();

                const url = `${EmpBffUrl}/${EmpEndpoint.ReportsExport.GetFile}?reportId=${reportId}`;

                fetch(url, {
                    method: 'get',
                    referrerPolicy: 'no-referrer',
                    headers: new Headers({
                        Authorization: `Bearer ${BaseApi.Token}`
                    })
                })
                    .then(result => {
                        if (result?.status === 200 || result?.status === 204) {
                            let filename = '';

                            for (let entry of result?.headers?.entries()) {
                                if (entry?.[0]?.includes('content-disposition')) {
                                    filename = entry?.[1].split('\"')?.[1];
                                }
                            }

                            filename && result.blob().then(res => {
                                const aElement = document.createElement('a');

                                aElement.setAttribute('download', filename);

                                const href = URL.createObjectURL(res);

                                aElement.href = href;
                                aElement.setAttribute('target', '_blank');
                                aElement.click();
                                URL.revokeObjectURL(href);
                            });

                            setState({
                                ...state,
                                [name]: {
                                    percentage: 0,
                                    isLoading: false,
                                    isFailed: false,
                                    errorCode: null
                                }
                            });
                        }
                    }).catch(() => {
                        setState({
                            ...state,
                            [name]: {
                                percentage: 0,
                                isLoading: false,
                                isFailed: true,
                                errorCode: EXCEL_EXPORT_ERROR_ENUM.GenerationError
                            }
                        });
                    });
            } else {
                setState({
                    ...state,
                    [name]: {
                        percentage: data.Percentage,
                        isLoading: !data.IsCompleted || !data.IsFailed,
                        isFailed: false,
                        errorCode: null
                    }
                });
            }

        }
    }
};

export const ExportToExcelContext = React.createContext(initialState);

export const ExportToExcelProvider = ({ children }) => {
    [state, setState] = React.useState(initialState);

    return (
        <ExportToExcelContext.Provider value={state}>
            {children}
        </ExportToExcelContext.Provider>
    );
};
