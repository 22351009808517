import * as React from 'react';
import { CircularProgress } from '@mui/material';

const Spinner = ({ className }) => (
    <div className={className}>
        <CircularProgress color="primary" />
    </div>
);

export default Spinner;
