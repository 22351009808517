import React from 'react';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import useStyles from './styles';

export enum PAGINATION_ACTIONS {
    FIRST,
    PREV,
    NEXT,
    LAST,
}

function TablePaginationActions({
    onChangePage,
    isFirstPage,
    isLastPage,
}: {
    onChangePage: (event: React.MouseEvent<HTMLButtonElement>, action: PAGINATION_ACTIONS) => void;
    isFirstPage?: boolean;
    isLastPage?: boolean;
}) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <IconButton
                onClick={(e) => onChangePage(e, PAGINATION_ACTIONS.FIRST)}
                disabled={isFirstPage}
                aria-label="first page"
                size="large"
            >
                <FirstPageIcon />
            </IconButton>
            <IconButton
                onClick={(e) => onChangePage(e, PAGINATION_ACTIONS.PREV)}
                disabled={isFirstPage}
                aria-label="previous page"
                size="large"
            >
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton
                onClick={(e) => onChangePage(e, PAGINATION_ACTIONS.NEXT)}
                disabled={isLastPage}
                aria-label="next page"
                size="large"
            >
                <KeyboardArrowRight />
            </IconButton>
            <IconButton
                onClick={(e) => onChangePage(e, PAGINATION_ACTIONS.LAST)}
                disabled={isLastPage}
                aria-label="last page"
                size="large"
            >
                <LastPageIcon />
            </IconButton>
        </div>
    );
}

export default TablePaginationActions;
