import * as React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { withRouter, Prompt } from 'react-router-dom';

import {
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    TextField,
    Grid,
    IconButton,
    Typography
} from '@mui/material';

import RolePermissions from './components/RolePermissions/RolePermissions';

import { ArrowBack } from '@mui/icons-material';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import PageLayoutWrapper from 'components/PageLayoutWrapper/PageLayoutWrapper';
import BackdropSpinner from 'components/Common/BackdropSpinner';

import { IRoleAdd } from 'api/models/role';
import { roleOverviewInputs } from 'data';

import { pageWrapContainer } from 'const';

import { arraysEqual } from 'utils/arrayMethods';

import Api from 'api/Api';
import { IResource } from 'api/models/resource';

const nameKey = roleOverviewInputs[0].name;
const descriptionKey = roleOverviewInputs[1].name;

const { useState, useContext, useCallback } = React;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        }
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(6)} ${theme.spacing(6)} ${theme.spacing(0)}`,

        [theme.breakpoints.down('lg')]: {
            padding: `${theme.spacing(6)} ${theme.spacing(2)} 0`,
        },
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            padding: `${theme.spacing(2)} ${theme.spacing(1.5)} 0`,
        }
    },
    titleWrap: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
        fontSize: '1.5rem',
        fontWeight: 500,
        width: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    iconButton: {
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    iconLabel: {
        fontSize: '0.9rem',
        fontWeight: 600
    },
    bodyWrapCenter: {
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    bodyWrapContainer: {
        width: '100%',
        maxWidth: pageWrapContainer.maxWidth
    },
    form: {
        width: '100%',
        maxWidth: pageWrapContainer.maxWidth,
        paddingBottom: theme.spacing(3),

        [theme.breakpoints.down('lg')]: {
            paddingBottom: theme.spacing(2)
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(-2),
            overflow: 'hidden'
        }
    },
    row: {
        padding: theme.spacing(3, 0, 0),

        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        },

        '& > div': {
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },

        ':last-child > &': {
            [theme.breakpoints.down('sm')]: {
                marginBottom: 0,
            }
        },
    },
    checkboxRow: {
        marginTop: theme.spacing(2)
    },
    groupLabel: {
        marginTop: 0,
        marginBottom: 0,

        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2)
        },
    },
    groupLabelPermission: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(2),
            marginLeft: theme.spacing(2)
        },
    },
    input: {
        position: 'relative',
        width: '100%'
    },
    divider: {
        margin: `${theme.spacing(4)} 0`,

        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(0, 2, 4, 2)
        },
    },
    footer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2),

        [theme.breakpoints.down('sm')]: {
            padding: `0 ${theme.spacing(2)}`,
        }
    },
    actionButton: {
        textTransform: 'none',
        marginLeft: theme.spacing(2)
    },
    serverError: {
        margin: '8px 14px 0',
        padding: 0,
        fontSize: '0.75rem',
        color: theme.palette.error.main
    },
    wrapInternal: {
        display: 'flex',
        alignItems: 'center'
    },
    helperText: {
        display: 'inline-block',
        margin: '18px 7px 0px -12px',
        fontSize: '0.75rem',
        color: theme.palette.grey[700]
    }
}));

const RoleAdd = ({ history }) => {
    const isRoleAddPage = document.location.href.includes('/role-add');

    let requiredErrorsInit = {};
    let valuesInit = {};
    let errorMessagesInit = {};
    const classes = useStyles({});
    const { globalSettings, translations, permissions }: GlobalContextModel = useContext(GlobalContext);

    const isReadOnlyPage = !isRoleAddPage && !permissions.rolesEditPermission;

    const internalUser = globalSettings.user.IsInternal;

    roleOverviewInputs.forEach(item => {
        if (item.required) {
            requiredErrorsInit[item.name] = true;
            errorMessagesInit[item.name] = 'users-valid-msg-empty-field';
        }

        valuesInit[item.name] = isRoleAddPage ? '' : localStorage.getItem(`role${item.name}`) || '';
    });

    const [values, setValues] = useState(valuesInit);
    const [errorFields, setErrorFields] = useState(requiredErrorsInit);
    const [errorMessagesKeys, setErrorMessagesKeys] = useState(errorMessagesInit);
    const [firstSubmit, setFirstSubmit] = useState(true);
    const [submitState, setSubmitState] = useState(false);
    const [serverErrorsKeys, setServerErrorsKeys] = useState([]);
    const [isInternal, setIsInternal] = useState(false);
    const [initInternal, setInitInternal] = useState(false);

    const [initPermissions, setInitPermissions] = useState([]);

    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [permissionError, setPermissionError] = useState(false);

    const handleRoleDetailsData = (roleDetails) => {
        setIsInternal(roleDetails.isInternal);
        setInitInternal(roleDetails.isInternal);
        setSelectedPermissions(roleDetails.scopes ?? []);
        setInitPermissions(roleDetails.scopes ?? []);
    };

    const handleChange = (name, required) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [name]: event.target.value });
        !firstSubmit && setErrorFields({ ...errorFields, [name]: required && !event.target.value });
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let trimValues = values;
        let newErrors = errorFields;
        let newErrorMessagesKeys = errorMessagesKeys;

        firstSubmit && setFirstSubmit(false);

        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                trimValues[key] = values[key].trim();
            }
        }

        for (const key in errorFields) {
            if (errorFields.hasOwnProperty(key)) {
                const isRequired = roleOverviewInputs.find(el => el.name === key)?.required;

                newErrors[key] = isRequired && !trimValues[key];
            }
        }

        const allFieldsFilled = Object.values(newErrors).every(field => !field);

        if (allFieldsFilled && selectedPermissions.length) {
            submitForm();
        }

        setValues({ ...trimValues });
        setErrorFields({ ...newErrors });
        setErrorMessagesKeys({ ...newErrorMessagesKeys });
        !selectedPermissions.length && setPermissionError(true);
    };

    const apiRequest = () => {
        return document.location.href.includes('/role-add')
            ? Api.Role.Add({
                name: values[nameKey],
                description: values[descriptionKey],
                isInternal,
                scopes: selectedPermissions
            })
            : Api.Role.Edit({
                id: localStorage.getItem('roleId'),
                name: values[nameKey],
                description: values[descriptionKey],
                isInternal,
                scopes: selectedPermissions
            });
    };

    const submitForm = () => {
        setSubmitState(true);
        apiRequest()
            .then(res => {
                if (res.succeeded) {
                    clearValues();
                    history.push({ pathname: '/role-list' });
                } else {
                    let newErrorFields = errorFields;
                    let newErrorMessagesKeys = errorMessagesKeys;
                    let newServerErrorsKeys = [];

                    setSubmitState(false);
                    res.errors.forEach(error => {
                        if (error.description && errorFields.hasOwnProperty(error.description)) {
                            newErrorFields[error.description] = true;
                            newErrorMessagesKeys[error.description] = error.code;
                        } else {
                            newServerErrorsKeys.push(error.code);
                        }
                    });

                    setErrorFields(newErrorFields);
                    setErrorMessagesKeys(newErrorMessagesKeys);
                    setServerErrorsKeys(newServerErrorsKeys);
                }
            })
            .catch(error => console.warn(error));
    };

    const clearValues = () => {
        setValues(valuesInit);
        setSelectedPermissions(initPermissions);
        setIsInternal(initInternal);
    };

    const isValueChanged = () => {
        const valuesChanged = Object.entries(values).some(([key, value]) => valuesInit[key] !== value);
        const permissionsChanged = initPermissions ? !arraysEqual(initPermissions, selectedPermissions) : Boolean(selectedPermissions.length);

        return permissionsChanged || valuesChanged || initInternal !== isInternal;
    };

    const handleInternal = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsInternal(event.target.checked);
    };

    const handlePermissions = (permissions: Array<IResource>) => {
        const permissionsChecked = [];

        permissions.forEach(permission => !permission.children.length && permissionsChecked.push(permission.id));
        setSelectedPermissions(permissionsChecked);
        setPermissionError(false);
    };

    const memoizedHandlePermission = useCallback(handlePermissions, []);
    const memoizedHandleRoleDetailsData = useCallback(handleRoleDetailsData, []);

    const handleCancel = () => {
        history.push({ pathname: '/role-list' });
    };

    const promptMessage = JSON.stringify({
        title: translations['gen-discard-changes-title'],
        msg: translations['gen-discard-changes-msg'],
        yes: translations['gen-yes'],
        no: translations['gen-no'],
    });

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.bodyWrapContainer}>
                    <IconButton
                        className={classes.iconButton}
                        onClick={() => history.push({ pathname: '/role-list' })}
                        edge="start"
                        color="inherit"
                        size="small"
                        disableRipple
                    >
                        <ArrowBack htmlColor="black" />
                        &nbsp;
                        <Typography variant="button" className={classes.iconLabel}>{translations['gen-roles']}</Typography>
                    </IconButton>
                    <h1 className={classes.title}>
                        {!isRoleAddPage ? localStorage.getItem('roleName') : translations['role-add-role']}
                    </h1>
                </div>
            </div>
            <Divider />
            <PageLayoutWrapper>
                <BackdropSpinner open={submitState} />
                <form className={classes.form} onSubmit={handleSubmit}>
                    <Grid container spacing={2} className={classes.row}>
                        <Grid item md={4} sm={12}>
                            <h2 className={classes.groupLabel}>{translations['role-role-overview']}</h2>
                        </Grid>
                        <Grid item md={8} sm={12}>
                            <Grid container spacing={3}>
                                {roleOverviewInputs.map(({ id, name, label, helperTextKey = '', required = true, disabled = false, maxLength, type = 'text' }: IRoleAdd) => {
                                    const helperText = !firstSubmit && errorFields[name] && translations[errorMessagesKeys[name]] ||
                                        (!disabled || (isRoleAddPage && helperTextKey) ? `${translations[helperTextKey]}` : ' ');

                                    return (
                                        <Grid item sm={6} xs={12} key={id}>
                                            <TextField
                                                error={!firstSubmit && errorFields[name]}
                                                helperText={helperText}
                                                className={classes.input}
                                                value={values[name]}
                                                label={translations[label]}
                                                id={name}
                                                type={type}
                                                inputProps={{ maxLength }}
                                                onChange={handleChange(name, required)}
                                                variant="outlined"
                                                color="primary"
                                                disabled={!isRoleAddPage && disabled || isReadOnlyPage}
                                            />
                                        </Grid>);
                                }
                                )}
                            </Grid>
                            {internalUser &&
                                <div className={classes.wrapInternal}>
                                    <FormControlLabel
                                        classes={{ root: classes.checkboxRow }}
                                        control={
                                            <Checkbox
                                                id="internal"
                                                disabled={!isRoleAddPage || isReadOnlyPage}
                                                checked={isInternal}
                                                onChange={handleInternal}
                                                name="internal"
                                                color="primary"
                                            />
                                        }
                                        label={translations['role-internal']}
                                    />
                                    {isRoleAddPage && <span className={classes.helperText}>{`(${translations['role-internal-user-cant-edit']})`}</span>}

                                </div>}
                        </Grid>
                    </Grid>
                    <Divider className={classes.divider} />
                    <div>
                        <h2 className={clsx(classes.groupLabel, classes.groupLabelPermission)}>{translations['role-permissions']}</h2>
                        <RolePermissions
                            handlePermissions={memoizedHandlePermission}
                            handleRoleDetailsData={memoizedHandleRoleDetailsData}
                            readOnly={isReadOnlyPage}
                            requiredError={!firstSubmit && permissionError}
                            roleId={isRoleAddPage ? null : localStorage.getItem('roleId')}
                            hideInternal={!isInternal}
                        />
                        {serverErrorsKeys.map(error => (
                            <p key={error} className={classes.serverError}>{translations[error]}</p>
                        ))}
                    </div>
                    {(isValueChanged() || isRoleAddPage) && !isReadOnlyPage &&
                        <footer className={classes.footer}>
                            <Button
                                className={classes.actionButton}
                                variant="outlined"
                                onClick={handleCancel}
                            >
                                {translations['gen-cancel'] || ''}
                            </Button>
                            <Button
                                className={classes.actionButton}
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                {!isRoleAddPage ? translations['users-edit'] : translations['gen-create'] || ''}
                            </Button>
                        </footer>
                    }
                </form>
            </PageLayoutWrapper>
            <Prompt
                when={!submitState && isValueChanged()}
                message={promptMessage}
            />
        </div>
    );
};

export default withRouter(RoleAdd);
