import React, { useState, useEffect, useContext } from 'react';
import useStyles from './styles';
import clsx from 'clsx';
import { uid } from 'react-uid';
import {
    Divider,
    Grid,
    Box,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    TablePagination,
    Button,
    Chip,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import {
    endOfDay,
    startOfDay,
    formatDateByPattern,
    formatToIsoDateString,
    pickerDateToUtc,
} from 'utils/formatDate';
import formatStr from 'utils/formatStr';
import { cancelRequests } from 'utils/requestCancelation';
import AuditLogFilters from './components/AuditLogFilters/AuditLogFilters';
import NoResultsFound from 'components/NoResultsFound';

import PageLayoutWrapper from 'components/PageLayoutWrapper/PageLayoutWrapper';
import SearchFieldWithSuggest from 'components/SearchField/SearchFieldWithSuggest';
import BackdropSpinner from 'components/Common/BackdropSpinner';
import DateRangeLegend from 'components/DateRangeLegend';

import TablePaginationActions from 'components/TablePaginationActions/TablePaginationActions';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext, globalSettings } from 'context/globalContext';

import { getActionTypeTranslations, getLogActionTitle, filterBySearch, sortByDate } from './business';
import { headAuditLogs } from 'data';
import { IFilterNumberIdListItem, MAX_LOGS_TOTAL_COUNT, rowsPerPageOptions, localStorageFiltersKeys } from 'const';

import Api from 'api/Api';
import { AuditLogResponse } from 'api/models/auditlog';
import { useWidth } from 'utils/customHooks';

import { getFromLocalStorage } from 'utils/devUtils';

export interface IFilterDateList {
    fromDate: Date;
    toDate: Date;
}
export interface IFilterList {
    byAction: Array<IFilterNumberIdListItem>;
}
export interface IFilterListFull extends IFilterDateList, IFilterList { }

const apiRequests = {
    auditLog: null
};

const AuditLogs = () => {
    const classes = useStyles({});
    const { translations, dateTimeFormat, timeZone, permissions }: GlobalContextModel = useContext(GlobalContext);

    const isMobileView = useWidth() === 'xs';

    const dateInTimeZone = new Date(new Date().toLocaleString('en', { timeZone }));

    const filterListDateInitial: IFilterDateList = {
        fromDate: startOfDay(
            dateInTimeZone,
            {
                gameDayEnable: globalSettings?.settings?.GamingDaySettings?.Enabled,
                startOfGamingDay: globalSettings?.settings?.GamingDaySettings?.Start,
                endOfGamingDay: globalSettings?.settings?.GamingDaySettings?.End
            }
        ),
        toDate: endOfDay(
            dateInTimeZone,
            {
                gameDayEnable: globalSettings?.settings?.GamingDaySettings?.Enabled,
                startOfGamingDay: globalSettings?.settings?.GamingDaySettings?.Start,
                endOfGamingDay: globalSettings?.settings?.GamingDaySettings?.End
            }
        )
    };

    const filterListItemsInitial: IFilterList = {
        byAction: []
    };

    const localStorageFilters = getFromLocalStorage(localStorageFiltersKeys.auditLogsFilters);

    const filterListInitial: IFilterListFull = {
        ...filterListDateInitial,
        ...filterListItemsInitial,
    };

    const filterListStorageInitial: IFilterListFull = {
        ...filterListDateInitial,
        ...localStorageFilters ? localStorageFilters : filterListItemsInitial,
    };

    const [isLoaded, setIsLoaded] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [logs, setLogs] = useState([]);
    const [defaultLogs, setDefaultLogs] = useState([]);
    const [page, setPage] = useState(0);
    const [logsPerPage, setLogsPerPage] = useState(rowsPerPageOptions[0]);
    const [filters, setFilters] = useState({ ...filterListStorageInitial });
    const [appliedFilters, setAppliedFilters] = useState({ ...filterListStorageInitial });
    const [resetKey, setResetKey] = useState(uid(new Date()));
    const [showExceedWarning, setExceedWarning] = useState(false);
    const [totalLogsCount, setTotalLogsCount] = useState(0);

    const [isSaveFilters, setIsSaveFilters] = useState(!!localStorageFilters);

    const applyTranslations = (logs) => {
        const actionTypeTranslations = getActionTypeTranslations(translations);
        const logsWithTranslation = logs.map(item => ({ ...item, action: getLogActionTitle(item, actionTypeTranslations) }));

        setDefaultLogs(logsWithTranslation);
        setLogs(filterBySearch(logsWithTranslation, searchText));
    };

    useEffect(() => {
        permissions.auditLogsPermission && loadData();

        return () => cancelRequests(apiRequests);
    }, [ translations ]);

    const loadData = async (newFilters?: IFilterListFull) => {
        try {
            const fromDateFilter = pickerDateToUtc(newFilters?.fromDate || filters.fromDate, timeZone);
            const toDateFilter = pickerDateToUtc(newFilters?.toDate || filters.toDate, timeZone);
            const byActionFilter = (newFilters?.byAction || filters.byAction).map(action => action.id);

            setIsLoaded(false);
            apiRequests.auditLog = Api.AuditLog.GetLogs(
                fromDateFilter,
                toDateFilter,
                byActionFilter
            );

            const result: AuditLogResponse = await apiRequests.auditLog;

            if (result) {
                const actionTypeTranslations = getActionTypeTranslations(translations);
                const logs = result.auditLogs.map(item => ({
                    userName: item.userName,
                    actionDateTime: item.actionDateTime,
                    action: getLogActionTitle(item, actionTypeTranslations),
                    actionTypeId: item.actionTypeId,
                    description: item.description,
                    isUserDeleted: item.isUserDeleted
                }));

                applyTranslations(logs);
                setTotalLogsCount(result.totalLogsCount);
                setExceedWarning(result.totalLogsCount > MAX_LOGS_TOTAL_COUNT);
                setPage(0);
                setIsLoaded(true);
            }
        } catch (error) {
            // handle fetch errors here
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLogsPerPage = event => {
        setLogsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleAppliedFilters = () => {
        if (isSaveFilters) {
            const filtersToStorage = {};

            for (let key in filterListItemsInitial) {
                if (filterListItemsInitial.hasOwnProperty(key)) {
                    filtersToStorage[key] = filters[key];
                }
            }

            localStorage.setItem(localStorageFiltersKeys.auditLogsFilters, JSON.stringify(filtersToStorage));
        }

        setAppliedFilters(filters);
        loadData();
    };

    const handleSaveFilters = () => {
        isSaveFilters && localStorage.removeItem(localStorageFiltersKeys.auditLogsFilters);

        setIsSaveFilters(!isSaveFilters);
    };

    const handleClearFilters = () => {
        setFilters(filterListInitial);
        setAppliedFilters(filterListInitial);
        setIsSaveFilters(false);
        localStorage.removeItem(localStorageFiltersKeys.auditLogsFilters);

        if (appliedFilters != filterListInitial) {
            loadData(filterListInitial);
        }
    };

    const textSearchChange = (newText: string) => {
        if (searchText !== newText) {
            setSearchText(newText);
            setLogs(filterBySearch(defaultLogs, newText));
            setPage(0);
        }
    };

    const resetToDefault = () => {
        setResetKey(uid(new Date()));
        handleClearFilters();
        textSearchChange('');
    };

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.titleWrap}>
                    <h1 className={classes.title}>
                        {translations['gen-audit-logs'] || ''}
                    </h1>
                    {
                        isMobileView && (
                            <AuditLogFilters
                                filters={filters}
                                appliedFilters={appliedFilters}
                                setFilters={setFilters}
                                handleAppliedFilters={handleAppliedFilters}
                                handleClearFilters={handleClearFilters}
                                handleSaveFilters={handleSaveFilters}
                                isSaveFilters={isSaveFilters}
                            />)
                    }
                </div>
            </div>
            <Divider />
            <PageLayoutWrapper>
                <div className={classes.controlsWrap}>
                    {isMobileView && (
                        <div className={classes.searchWrap}>
                            <SearchFieldWithSuggest
                                key={resetKey}
                                data={defaultLogs.map(log => ({ userName: log.userName, action: log.action }))}
                                onTextChange={textSearchChange}
                                externalStyles={{ searchIcon: classes.searchIcon }}
                                searchValue={searchText}
                            />
                        </div>)}
                    <DateRangeLegend translationKey="audit-logs-showing-records" fromDate={appliedFilters.fromDate} toDate={appliedFilters.toDate} />
                    {
                        !isMobileView && (
                            <div className={classes.controlsContainer}>
                                <SearchFieldWithSuggest
                                    key={resetKey}
                                    data={defaultLogs.map(log => ({ userName: log.userName, action: log.action }))}
                                    onTextChange={textSearchChange}
                                    externalStyles = {{ searchIcon: classes.searchIcon, wrapOpen: classes.wrapOpen }}
                                    searchValue={searchText}
                                />
                                <div className={classes.filterContainer}>
                                    <AuditLogFilters
                                        filters={filters}
                                        appliedFilters={appliedFilters}
                                        setFilters={setFilters}
                                        handleAppliedFilters={handleAppliedFilters}
                                        handleClearFilters={handleClearFilters}
                                        handleSaveFilters={handleSaveFilters}
                                        isSaveFilters={isSaveFilters}
                                    />
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className={classes.tableWrap}>
                    <BackdropSpinner open={!isLoaded} />
                    {logs.length > 0
                        ? (
                            <>
                                <div className={classes.tableWrap}>
                                    {showExceedWarning &&
                                        <div className={classes.alert}>
                                            <InfoOutlinedIcon />
                                            <span>
                                                {formatStr(
                                                    translations['audit-logs-exceed-logs-count-warning'],
                                                    { 'totalLogs': totalLogsCount, 'maxLogs': MAX_LOGS_TOTAL_COUNT }
                                                )}
                                            </span>
                                            <Button
                                                size="small"
                                                onClick={()=> setExceedWarning(false)}
                                            >
                                                {translations['gen-dismiss']}
                                            </Button>
                                        </div>
                                    }
                                    <Table
                                        className={classes.table}
                                    >
                                        <TableHead >
                                            <TableRow>
                                                {headAuditLogs.map((headCell) => (
                                                    <TableCell
                                                        key={headCell.id}
                                                        className={classes.headerCell}
                                                        style={{ width: headCell.width || 'auto' }}
                                                    >
                                                        {translations[headCell.label]}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {logs
                                                .sort(sortByDate)
                                                .slice(page * logsPerPage, page * logsPerPage + logsPerPage).map((log, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell className={clsx(classes.cell, classes.timeCell)}>
                                                            <p>
                                                                {
                                                                    log.actionDateTime
                                                                        ? formatDateByPattern(
                                                                            new Date(formatToIsoDateString(log.actionDateTime)),
                                                                            dateTimeFormat,
                                                                            timeZone
                                                                        )
                                                                        : ''
                                                                }
                                                            </p>
                                                        </TableCell>
                                                        <TableCell className={clsx(classes.cell, classes.userCell)}>
                                                            <span>{log.userName}</span>
                                                            {log.isUserDeleted &&
                                                                <Chip
                                                                    data-a="deactivated-label"
                                                                    className={classes.nameLabel}
                                                                    label={translations['audit-logs-deactivated']}
                                                                    size="small"
                                                                    component={'span'}
                                                                />}
                                                        </TableCell>
                                                        <TableCell className={clsx(classes.cell, classes.actionCell)}>
                                                            <p>{log.action}</p>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </div>
                                <TablePagination
                                    rowsPerPageOptions={rowsPerPageOptions}
                                    component="div"
                                    count={logs.length}
                                    rowsPerPage={logsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeLogsPerPage}
                                    labelRowsPerPage={translations['hm-table-rows-per-page']}
                                    ActionsComponent={(props) => <TablePaginationActions {...props} onChangePage={handleChangePage} />}
                                />
                            </>)
                        : isLoaded &&
                            <Box paddingTop={12}>
                                <Grid container justifyContent="center">
                                    <Grid item>
                                        <NoResultsFound
                                            onResetClick={resetToDefault}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                    }
                </div>
            </PageLayoutWrapper>
        </div>
    );
};

export default AuditLogs;
